import React, {useCallback} from "react";
import ReadingIntervalSelect from "../reading-intervals-select";
import PropTypes from "prop-types";
import {
    get as _get,
} from "lodash";
import ImpactVueText from "../../../shared/impactVue/ImpactVueText";

const ReadingIntervalInput = ({type, settings, disabled, notice, onChange}) => {
    const typeLabels = {
        "temperature": "Temperature:",
        "rms": "RMS:",
        "fft": "FFT:",
        "impactvue_fft": <ImpactVueText type={"fft"} />,
        "impactvue_rms": <ImpactVueText type={"rms"} />,
        "wua": "Universal Adapter Interval"
    };
    const handleChange = useCallback((event) => {
        let value = event.target.value.replace(/\D+/g, "");
        event.target.value = value;
        if (onChange !== null) {
            onChange(event);
        }
    }, [onChange]);

    return (
        <React.Fragment>
            {notice && <div className="text-primary mb-2 interval-notice breaking-line">{notice}</div>}
            <div className="form-group" style={{marginBottom: "7px"}}>
                <div className={type === "wua" ? "" : "row align-items-center mb-3"}>
                    <label className={"text-right" + (type === "wua" ? "" : " col-3")}>{_get(typeLabels, type)}</label>
                    <div className={type === "wua" ? "" : " col-9"}>
                        <div className={type === "wua" ? "row" : "row"}>
                            <div className={"text-right col-4 pr-1"}>
                                <input
                                    type="text"
                                    name={type + "_interval"}
                                    onChange={handleChange}
                                    readOnly={disabled}
                                    className={`form-control ${_get(settings, type + "_interval") === "" || _get(settings, type + "_interval") === 0 ? " is-invalid" : ""}`}
                                    defaultValue={_get(settings, type + "_interval")}
                                />
                                {_get(settings, type + "_interval") === "" &&
                                    <span className="invalid-feedback d-block color-danger mt-1 text-right small-error-msg">This field is required.</span>
                                }

                                {_get(settings, type + "_interval") === "0" &&
                                    <span className="invalid-feedback d-block color-danger mt-1 text-right small-error-msg">Value should be more than 0.</span>
                                }
                            </div>
                            <div className={"col-8"}>
                                <ReadingIntervalSelect
                                    disabled={disabled}
                                    name={type + "_interval_type"}
                                    onChange={onChange}
                                    value={_get(settings, type + "_interval_type")}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

ReadingIntervalInput.propTypes = {
    type: PropTypes.string,
    settings: PropTypes.object,
    disabled: PropTypes.bool,
    showNotice: PropTypes.bool,
    onChange: PropTypes.func,
    notice: PropTypes.string,
    formErrors: PropTypes.func,
    validation: PropTypes.object
};

export default ReadingIntervalInput;
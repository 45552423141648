import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import moment from "moment";
import {uniq as _uniq} from "lodash";
import Helper from "../../helpers/helper";
import {DATEPICKER_TYPE} from "../../constants/constants";

import "react-datepicker/dist/react-datepicker.css";

const propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.string,
    replaceWithButton: PropTypes.bool,
    inline: PropTypes.bool,
    ignoreModal: PropTypes.bool,
    className: PropTypes.string,
    onChangeHandler: PropTypes.func,
    onChangeMonth: PropTypes.func,
    availableDates: PropTypes.array,
    displayFormat: PropTypes.string,
    valueFormat: PropTypes.string,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    type: PropTypes.string,
    placement: PropTypes.string,
    portalPosition: PropTypes.string,
};

const defaultProps = {
    type: DATEPICKER_TYPE.DEFAULT,
};

const DatePickerInput = ({
    disabled,
    name,
    value,
    replaceWithButton,
    inline,
    className,
    onChangeHandler,
    onChangeMonth,
    availableDates,
    displayFormat,
    valueFormat,
    minDate,
    maxDate,
    type,
    placement,
    portalPosition = null,
    ignoreModal = false
}) => {
    const [pickerValue, setPickerValue] = useState(undefined);
    const pickerDisplayFormat = Helper.explodeDate(
        displayFormat
            ? displayFormat.indexOf("YY") !== -1 && displayFormat.indexOf("YYYY") === -1
                ? displayFormat.replace("YY", "YYYY")
                : displayFormat
            : "MM/DD/YYYY"
    ).date;

    const pickerValueFormat = valueFormat ? Helper.explodeDate(valueFormat).date : pickerDisplayFormat;

    useEffect(() => {
        if (value && value !== "Invalid date" && (ignoreModal === true || Helper.explodeDate(value).date === moment(value, pickerValueFormat).format(pickerValueFormat))) {
            setPickerValue(Helper.explodeDate(value).date);
        }
    }, [value]);

    const pickerAvailableDates = _uniq((availableDates || []).map((availableDate) => Helper.explodeDate(availableDate).date));

    const pickerMinDate = minDate
        ? new Date(moment(Helper.explodeDate(minDate).date, pickerValueFormat).toString())
        : pickerAvailableDates.length
            ? undefined
            : new Date(new Date().setMonth(new Date().getMonth() - 1));

    const pickerMaxDate =
        maxDate
            ? new Date(moment(Helper.explodeDate(maxDate).date, pickerValueFormat).toString())
            : undefined;

    const filterDate = (date) => (pickerAvailableDates.length ? pickerAvailableDates.indexOf(moment(date).format(pickerValueFormat)) !== -1 : true);

    const onChange = (date) => onChangeHandler(moment(date).format(valueFormat));

    const onMonthChange = (date) => {
        if (type === DATEPICKER_TYPE.FFT) {
            const minCommence = moment().utc().startOf("day").subtract(6, "month");
            const currentCommence = moment(date).utc().startOf("month");

            const commence = currentCommence.isAfter(minCommence) ? currentCommence : minCommence;
            const complete = moment().utc().startOf("day");
            const range = complete.diff(commence);

            if (onChangeMonth && typeof onChangeMonth === "function") {
                onChangeMonth(range);
            }
        }
    };

    const momentFormat = displayFormat === "DD/MM/YY" ? "DD/MM/YYYY" : "MM/DD/YYYY";
    const formattedPickerValue = (ignoreModal === true && pickerValue) ? new Date(moment(pickerValue, momentFormat).format()) : (ignoreModal === false && pickerValue) ? moment(pickerValue, pickerValueFormat).format(momentFormat) : undefined;
    const selectedPickerValue = (ignoreModal === true && pickerValue) ? new Date(moment(pickerValue, momentFormat).format()) : (ignoreModal === false && pickerValue) ? new Date(moment(pickerValue, pickerValueFormat).format()) : new Date();

    return (
        <DatePicker
            value={formattedPickerValue}
            selected={selectedPickerValue}
            name={name}
            minDate={pickerMinDate}
            maxDate={pickerMaxDate}
            peekNextMonth={type !== DATEPICKER_TYPE.FFT}
            disabledKeyboardNavigation
            filterDate={filterDate}
            onChange={onChange}
            onMonthChange={onMonthChange}
            customInput={replaceWithButton ? <button className="btn btn-sm btn-default">{formattedPickerValue}</button> : <input className={className} />}
            portalId={portalPosition || "date-picker-portal"}
            disabled={disabled}
            inline={inline}
            popperPlacement={placement}
            dateFormat={displayFormat === "DD/MM/YY" ? "dd/MM/yyyy" : "MM/dd/yyyy"}
        />
    );
};

DatePickerInput.propTypes = propTypes;
DatePickerInput.defaultProps = defaultProps;

export default DatePickerInput;

import {cloneDeep as _cloneDeep} from "lodash";

export const addNewItemInList = (prev, data) => {
    const localPrev = _cloneDeep(prev);
    localPrev.list.push(data.result);
    return localPrev;
};

export const deleteItemFromListById = (prev, deleteId) => {
    prev.list = prev.list.filter((item) => item.id !== deleteId);
    return prev;
};

export const updateItemInList = (prev, data) => {
    prev.list = prev.list.map((item) => {
        if (item.id !== data.result.id) {
            return item;
        }
        return data.result;
    });
    return prev;
};

import React, { Fragment } from "react";
import PropTypes from "prop-types";

const AlertActions = ({
    reset,
    save,
    remove,
    isEnvelope,
    inProgress,
    isCurrent,
    isProfile,
    isChanged,
    alert,
}) => (
    <Fragment>
        {(alert.id || (isCurrent && isChanged)) && <hr className="my-2" />}

        <div className="row controls-button-alerts-level">
            <div className="col-md-12">
                {isCurrent && isChanged && (
                    <React.Fragment>
                        <button
                            className="btn btn-sm btn-primary pull-right"
                            style={{ padding: "4px 8px" }}
                            disabled={
                                isEnvelope
                                    ? !!inProgress ||
                                      (alert.fftEnvelopeData && alert.fftEnvelopeData.length <= 0)
                                    : !!inProgress
                            }
                            onClick={save}
                        >
                            {isEnvelope
                                ? alert.fftEnvelopeData && alert.fftEnvelopeData.length > 0
                                    ? "Save"
                                    : "Calculating..."
                                : "Save"}
                        </button>
                        <button
                            className="btn btn-sm btn-secondary pull-right"
                            style={{ marginRight: 10, padding: "4px 8px" }}
                            onClick={reset}
                        >
                            Cancel
                        </button>
                    </React.Fragment>
                )}
                {(alert.id || isProfile) && (
                    <button
                        className="btn btn-sm btn-danger pull-right btn-remove"
                        style={{ padding: "4px 8px" }}
                        onClick={remove}
                    >
                        Delete
                    </button>
                )}
            </div>
        </div>
    </Fragment>
);

AlertActions.propTypes = {
    reset: PropTypes.func,
    save: PropTypes.func,
    remove: PropTypes.func,
    isEnvelope: PropTypes.bool,
    isCurrent: PropTypes.bool,
    inProgress: PropTypes.bool,
    isProfile: PropTypes.bool,
    isChanged: PropTypes.bool,
    alert: PropTypes.object,
};

export default AlertActions;

import React from "react";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";
import SimpleChart from "../highcharts/simple-new/simpleChart";
import TwfFftChart from "../highcharts/twf/twfFftChart";
import {
    CHART_TYPE_FFT_ACCELERATION_IMPACT_VUE_ID,
    CHART_TYPE_FFT_VELOCITY_IMPACT_VUE_ID,
    CHART_TYPE_TWF_ACCELERATION_IMPACT_VUE_ID,
    CHART_TYPE_TWF_VELOCITY_IMPACT_VUE_ID,
} from "../../../constants/constants";
import {useChartTypesStore} from "../../../stores/zustand/ChartSelectedChartTypeStore";
import {useChartSelectedEquipmentStore, useSelectedByEquipment} from "../../../stores/zustand/ChartSelectedEquipmentStore";
import {useUserViewChartType} from "../../../stores/UserLocalSettingsStore";
import ScrollEffectWrapper from "./ScrollEffectWrapper";
import {useChartTypesList} from "../hooks/useChartTypesList";

const BodyEquipment = (props) => {
    const {equipment, simpleChartElementHeight, handleSetIdleThresholdModal, fftFrequencyType, fftChartElementHeight} = props;

    const selectedInstallationPoints = useSelectedByEquipment(equipment.id);
    const chartTypes = useChartTypesStore();
    const selectedEquipment = useChartSelectedEquipmentStore();
    const viewChartType = useUserViewChartType();
    const {orderedSelectedCharts, sortedTypes} = useChartTypesList();

    let bodyContent = {};
    if (selectedInstallationPoints?.length && Object.keys(chartTypes).length) {
        bodyContent = (sortedTypes.length > 0 ? sortedTypes : Object.keys(chartTypes)).map((chartType) => {
            if (chartTypes[chartType] && orderedSelectedCharts.includes(chartType)) {
                if (chartTypes[chartType].type === "rms" && selectedInstallationPoints.length) {
                    return (
                        <LazyLoad
                            key={chartType}
                            height={simpleChartElementHeight}
                            scrollContainer={"#chart-list"}
                            debounce={200}
                            placeholder={
                                <div
                                    id={`placeholder-${chartType}`}
                                    className="lazyload-placeholder"
                                    style={{height: simpleChartElementHeight}}
                                ></div>
                            }
                            once={true}
                        >
                            <SimpleChart
                                chartType={chartType}
                                handleSetIdleThresholdModal={handleSetIdleThresholdModal}
                            />
                        </LazyLoad>
                    );
                }

                if (chartTypes[chartType].type === "range" && equipment.existDistanceSensor && selectedInstallationPoints.length) {
                    return (
                        <LazyLoad
                            key={chartType}
                            height={simpleChartElementHeight}
                            scrollContainer={"#chart-list"}
                            placeholder={
                                <div
                                    id={`placeholder-${chartType}`}
                                    className="lazyload-placeholder"
                                    style={{height: simpleChartElementHeight}}
                                ></div>
                            }
                            callback
                            once={true}
                        >
                            <SimpleChart
                                chartType={chartType}
                                handleSetIdleThresholdModal={handleSetIdleThresholdModal}
                            />
                        </LazyLoad>
                    );
                }

                if (+chartType === CHART_TYPE_FFT_ACCELERATION_IMPACT_VUE_ID || +chartType === CHART_TYPE_FFT_VELOCITY_IMPACT_VUE_ID) {
                    return selectedInstallationPoints.map((pointId) => (
                        <LazyLoad
                            key={`${chartType}-${pointId}`}
                            height={fftChartElementHeight}
                            scrollContainer={"#chart-list"}
                            placeholder={
                                <div
                                    id={`placeholder-${chartType}-${pointId}`}
                                    className="lazyload-placeholder"
                                    style={{height: fftChartElementHeight}}
                                ></div>
                            }
                            once={true}
                        >
                            <TwfFftChart
                                chartTypes={chartTypes}
                                chartType={chartType}
                                selectedPoint={pointId}
                                fftFrequencyType={fftFrequencyType}
                                handleSetIdleThresholdModal={handleSetIdleThresholdModal}
                            />
                        </LazyLoad>
                    ));
                }

                if (chartTypes[chartType].type === "fft") {
                    return selectedEquipment[equipment.id].map((pointId) => (
                        <LazyLoad
                            key={`${chartType}-${pointId}`}
                            height={fftChartElementHeight}
                            scrollContainer={"#chart-list"}
                            placeholder={
                                <div
                                    id={`placeholder-${chartType}-${pointId}`}
                                    className="lazyload-placeholder"
                                    style={{height: fftChartElementHeight}}
                                ></div>
                            }
                            once={true}
                        >
                            <TwfFftChart
                                chartTypes={chartTypes}
                                chartType={chartType}
                                selectedPoint={pointId}
                                fftFrequencyType={fftFrequencyType}
                                handleSetIdleThresholdModal={handleSetIdleThresholdModal}
                            />
                        </LazyLoad>
                    ));
                }

                if (+chartType === CHART_TYPE_TWF_ACCELERATION_IMPACT_VUE_ID || +chartType === CHART_TYPE_TWF_VELOCITY_IMPACT_VUE_ID) {
                    return selectedEquipment[equipment.id].map((pointId) => (
                        <LazyLoad
                            key={`${chartType}-${pointId}`}
                            height={fftChartElementHeight}
                            scrollContainer={"#chart-list"}
                            placeholder={
                                <div
                                    id={`placeholder-${chartType}-${pointId}`}
                                    className="lazyload-placeholder"
                                    style={{height: fftChartElementHeight}}
                                ></div>
                            }
                            once={true}
                        >
                            <TwfFftChart
                                chartType={chartType}
                                chartTypes={chartTypes}
                                selectedPoint={pointId}
                                handleSetIdleThresholdModal={handleSetIdleThresholdModal}
                            />
                        </LazyLoad>
                    ));
                }

                if (chartTypes[chartType].type === "twf") {
                    return selectedEquipment[equipment.id].map((pointId) => (
                        <LazyLoad
                            key={`${chartType}-${pointId}`}
                            height={fftChartElementHeight}
                            scrollContainer={"#chart-list"}
                            placeholder={
                                <div
                                    id={`placeholder-${chartType}-${pointId}`}
                                    className="lazyload-placeholder"
                                    style={{height: fftChartElementHeight}}
                                ></div>
                            }
                            once={true}
                        >
                            <TwfFftChart
                                chartType={chartType}
                                chartTypes={chartTypes}
                                selectedPoint={pointId}
                                handleSetIdleThresholdModal={handleSetIdleThresholdModal}
                            />
                        </LazyLoad>
                    ));
                }

                if (chartTypes[chartType].type === "tach" && equipment.tachometer !== null) {
                    return (
                        <LazyLoad
                            key={chartType}
                            height={simpleChartElementHeight}
                            scrollContainer={"#chart-list"}
                            placeholder={
                                <div
                                    id={`placeholder-${chartType}`}
                                    className="lazyload-placeholder"
                                    style={{height: simpleChartElementHeight}}
                                ></div>
                            }
                            once={true}
                        >
                            <SimpleChart
                                chartType={chartType}
                                handleSetIdleThresholdModal={handleSetIdleThresholdModal}
                            />
                        </LazyLoad>
                    );
                }
            }
        });
    }

    const isEmptyContent = Object.values(bodyContent).every((value) => {
        return value === null || value === undefined || value === false || value.length === 0;
    });

    return isEmptyContent ? (
        <div>
            <h5>You have not selected a chart type or installation point</h5>
        </div>
    ) : (
        <div className={viewChartType === "blocks" ? "blocks-chart-wrapper" : ""}>
            {bodyContent}
            <ScrollEffectWrapper />
        </div>
    );
};

BodyEquipment.propTypes = {
    chartTypes: PropTypes.object,
    equipment: PropTypes.object,
    simpleChartElementHeight: PropTypes.number,
    user: PropTypes.object,
    handleSetIdleThresholdModal: PropTypes.func,
    deleteChartMark: PropTypes.func,
    fftFrequencyType: PropTypes.string,
    fftChartElementHeight: PropTypes.number,
};

export default BodyEquipment;

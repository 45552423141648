import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {useFFTChartSeriesBuilder} from "../../../../../../hooks/factory/useFFTChartSeriesBuilder";
import {useIsImpactVue} from "../../../hooks/useChartTypes";
import {useOverlay} from "../helpers/useOverlay";

export const useOverlaySeriesBuilder = () => {
    const {equipment, installationPointId, axisId, date, time} = useOverlay();
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const isImpactVue = useIsImpactVue(chartType);

    const overlaySeries = useFFTChartSeriesBuilder({
        chartType,
        axis: [axisId],
        installationPointId,
        overlayPoints: equipment?.installationPoints || [],
        pointColor: "gray",
        color: "gray",
        currentDate: {
            date,
            time,
        },
        isImpactVue,
    });

    return overlaySeries;
};

import Helper from "../../../helpers/helper";
import {useQueries} from "@tanstack/react-query";
import {useQueriesStatus} from "../../useQueriesStatus";
import {FFT_TIMESTAMPS_API} from "./constants";
import Reading from "../../../api/reading";

export const useReadingBatchQuery = ({
    installationPointId,
    chartType,
    axisIds = ["1"],
    readingIds,
    impactVue = false,
    highpassFilter = 20,
    isInterpolation = true,
}) => {
    const isMultipleAxes = +axisIds[0] === 0;
    // TODO temporary solution for multiple axis
    let chunks = [];

    if (!isMultipleAxes) {
        chunks = Helper.chunkArray(readingIds, 10);
    } else {
        chunks = {};
        for (const readingKey in readingIds) {
            chunks[readingKey] = Helper.chunkArray(readingIds[readingKey], 10);
        }
    }

    if (isMultipleAxes) {
        axisIds = [1, 2, 3];
    }

    let queries = [];
    if (!isMultipleAxes) {
        queries = axisIds.map((axisId) => {
            return chunks.map((chunk) => ({
                queryKey: [FFT_TIMESTAMPS_API.FFT_READING_BATCH, installationPointId, chartType, axisId, chunk, impactVue, highpassFilter, isInterpolation],
                queryFn: () =>
                    Reading.getChartFFTDataBatch(installationPointId, chartType, axisId, chunk, impactVue, highpassFilter, isInterpolation).then(({response}) => response),
                enabled: !!chunks.length,
                select: (res) => {
                    return res[chartType][axisId][installationPointId];
                },
            }));
        });
    } else {
        queries = axisIds.map((axisId) => {
            return chunks[axisId].map((chunk) => ({
                queryKey: [FFT_TIMESTAMPS_API.FFT_READING_BATCH, installationPointId, chartType, axisId, chunk, impactVue, highpassFilter, isInterpolation],
                queryFn: () =>
                    Reading.getChartFFTDataBatch(installationPointId, chartType, axisId, chunk, impactVue, highpassFilter, isInterpolation).then(({response}) => response),
                enabled: !!chunks[axisId],
                select: (res) => {
                    return res[chartType][axisId][installationPointId];
                },
            }));
        });
    }
    queries = queries.reduce((arr, item) => [...arr, ...item], []);

    const res = useQueries({queries});
    return useQueriesStatus(res);
};

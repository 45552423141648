import {useEffect, useMemo, useRef} from "react";
import {cloneDeep as _cloneDeep} from "lodash";
import {useChartStateStoreContext} from "../store/hooks/useStoreContext";
import {useAlertsBuilder} from "./factory/useAlertsBuilder";
import {useIdleThresholdBuilder} from "./factory/useIdleThresholdBuilder";
import {useTachometerSeriesBuilder} from "./factory/useTachometerSeriesBuilder";
import {useChartSelectedRangeStore} from "../../../../../stores/zustand/ChartSelectedRangeStore";
import {useSeriesBuilder} from "./factory/useSeriesBuilder";
import {useAmbientBuilder} from "./factory/useAmbientBuilder";
import {useRpmBuilder} from "./factory/useRpmBuilder";
import {useFftAcquisitionsBuilder} from "./factory/useFftAcquisitionsBuilder";
import {useIsDarkTheme} from "../../../../../hooks/helpers/theme";
import {useChartMarksBuilder} from "./factory/useChartMarksBuilder";
import {useHistoriesBuilder} from "./factory/useHistoriesBuilder";
import {useEquipmentByParams} from "../../../../../hooks/api/equipment/useEquipmentQuery";
import {CHART_CONFIG} from "../../../../../constants/chart";
import {useHeightBuilder} from "./factory/useHeightBuilder";
import {setDarkModeForChart} from "../../../../../helpers/setDarkModeForChart";
import {useCurrentPointRef} from "./useCurrentPointRef";
import {useHelpModal} from "../../../../../modals/help/hooks/useHelpModal";
import {useActiveAlertAnnotation} from "./factory/useActiveAlertAnnotation";
import {shallow} from "zustand/shallow";
import {useChartSelectedEquipmentStore, useSelectedByEquipment} from "../../../../../stores/zustand/ChartSelectedEquipmentStore";
import {useChartScaleInit} from "./useChartScaleInit";
import {useChartRef} from "./useChartRef";
import {useChartEventEffect} from "./useChartEventEffect";
import {useChartHeight} from "./chartHooks";
import {useIsTachometer} from "../../hooks/useChartTypes";
import {useTriggersBuilder} from "./factory/useTriggersBuilder";
import ChartHelper from "../../../../../helpers/chart";
import Highcharts from "highcharts";
import {useZoomReset} from "./useZoomReset";

const zoomGroup = "simpleCharts";

export const useDrawChartEffect = (props) => {
    const isDarkTheme = useIsDarkTheme();
    const currentPointRef = useCurrentPointRef();
    const {equipmentItem} = useEquipmentByParams();
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const settings = useChartStateStoreContext((state) => state.settings, shallow);
    const seriesList = useChartStateStoreContext((state) => state.settings.seriesList, shallow);
    const {chartTooltipFormatter} = useChartStateStoreContext((state) => state.factory);
    const {setLoader} = useChartStateStoreContext((state) => state.actions);
    const selectedPoints = useSelectedByEquipment(equipmentItem.id);
    const rangeSelectorDate = useChartSelectedRangeStore();
    const selectedEquipment = useChartSelectedEquipmentStore();
    const {setZoomed} = useChartStateStoreContext((state) => state.actions);
    const loaderTimeoutSignal = useRef(null);

    const resetZoom = useZoomReset();

    const chartMarks = useChartMarksBuilder(props);

    const {series, isLoading} = useSeriesBuilder();

    const chartRef = useChartRef();

    const ambientSeries = useAmbientBuilder(props, settings);

    const rpmSeries = useRpmBuilder(props);

    const {series: tachSeries} = useTachometerSeriesBuilder();

    const alerts = useAlertsBuilder(props);

    const idleThresholdPlotLines = useIdleThresholdBuilder();

    const fftAcquisitionsTime = useFftAcquisitionsBuilder(series, equipmentItem);

    const histories = useHistoriesBuilder(props, equipmentItem);

    const chartSoftHeight = useHeightBuilder(settings.showAlerts, alerts);

    const activeAlertAnnotation = useActiveAlertAnnotation(chartSoftHeight);

    const isTachometer = useIsTachometer(chartType);

    const tachTriggers = useTriggersBuilder(series.triggers);

    const [showHelpModal] = useHelpModal(false, true);
    const tachometerSeries = isTachometer ? tachSeries : [];

    useChartScaleInit(chartRef, series, alerts, seriesList.selected);
    useChartEventEffect(chartRef, selectedEquipment[equipmentItem.id], series);

    useEffect(() => {
        if (loaderTimeoutSignal.current) {
            clearTimeout(loaderTimeoutSignal.current);
            loaderTimeoutSignal.current = null;
        }
        setLoader(isLoading);
    }, [rangeSelectorDate.from, rangeSelectorDate.to, rangeSelectorDate.range, isLoading]);

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (loaderTimeoutSignal.current) {
            clearTimeout(loaderTimeoutSignal.current);
        }

        loaderTimeoutSignal.current = setTimeout(() => {
            setLoader(false);
        }, 100);
    }, [series, isLoading, ambientSeries, alerts]);

    useEffect(() => {
        resetZoom();
    }, [series]);

    const chartHeight = useChartHeight();

    useEffect(() => {
        if (!Object.keys(Highcharts.syncZoomOptions[zoomGroup] || {}).length) {
            return;
        }

        setZoomed(true);
    }, [series]);

    return useMemo(() => {
        const {softMin, softMax} = chartSoftHeight;
        const chartConfig = _cloneDeep(CHART_CONFIG);
        if (isDarkTheme === "true") {
            setDarkModeForChart(chartConfig);
        }
        if (!series.length && !isTachometer) {
            return chartConfig;
        }
        chartConfig.chart.syncZoomGroup = zoomGroup;
        chartConfig.chart.height = chartHeight;
        chartConfig.chart.chartType = props.chartTypes[chartType]?.type;
        chartConfig.annotations = [...fftAcquisitionsTime, ...activeAlertAnnotation];
        chartConfig.plotOptions.series.point.events.mouseOver = (e) => {
            currentPointRef.current = {...e.target};
        };

        chartConfig.series = [...series, ...ambientSeries, ...rpmSeries, ...tachometerSeries];
        chartConfig.xAxis.plotLines = [...chartMarks, ...histories, ...tachTriggers];
        chartConfig.xAxis.labels = {formatter: ChartHelper.formatXAxis};
        chartConfig.yAxis[0].plotLines = [...alerts, ...idleThresholdPlotLines];
        chartConfig.yAxis[0].title.text = seriesList.label + (seriesList.units !== "" ? `, ${seriesList.units}` : "");
        chartConfig.yAxis[0].title.margin = 5;
        chartConfig.yAxis[0].softMax = softMax;
        chartConfig.yAxis[0].softMin = softMin;

        if (Object.keys(Highcharts.syncZoomOptions[zoomGroup] || {}).length) {
            chartConfig.xAxis.max = Highcharts.syncZoomOptions[zoomGroup].xAxisEnd;
            chartConfig.xAxis.min = Highcharts.syncZoomOptions[zoomGroup].xAxisStart;
        }

        chartConfig.legend.itemStyle.color = isDarkTheme === "true" ? "#ffffff" : "#000000";
        chartConfig.exporting.buttons.help.onclick = showHelpModal;
        chartConfig.tooltip.formatter = chartTooltipFormatter;

        return chartConfig;
    }, [
        series,
        ambientSeries,
        rpmSeries,
        fftAcquisitionsTime,
        histories,
        alerts,
        idleThresholdPlotLines,
        tachSeries,
        chartSoftHeight,
        chartMarks,
        rangeSelectorDate,
        activeAlertAnnotation,
        selectedPoints,
        chartHeight,
    ]);
};

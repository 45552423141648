import React, {useEffect, useReducer, useRef} from "react";
import {Modal, ValidationError} from "../shared";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import ApiEquipment from "../api/equipment";
import Helper from "../helpers/helper";
import FormValidator from "../helpers/form-validator";

const rules = [
    {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: "Equipment Name cannot be empty."
    },
    {
        field: "name",
        method: (name, options, data) => name === data.originalName,
        validWhen: false,
        message: "The equipment name must be unique. "
    }
];

const validator = new FormValidator(rules);

const initialState = {
    data: {
        name: "",
        options: {
            installationPoints: true,
            bearings: true,
            alertConditions: true,
            photos: false,
        },
        originalId: null,
        originalName: ""
    },
    validation: validator.valid(),
    inProgress: false
};

const reducer = (state, action) => ({...state, ...action});

const EquipmentEditPhotoModal = props => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {data, validation, inProgress} = state;
    const {equipment = {}, history} = props;

    const inputNameRef = useRef(null);

    useEffect(() => {
        dispatch({data: {
            ...data,
            name: equipment.name || "",
            originalId: equipment.id,
            originalName: equipment.name || ""
        }});
    }, []);

    useEffect(() => {
        inputNameRef.current && inputNameRef.current.focus();
    }, [inputNameRef.current]);

    const updateData = (key, value) => dispatch({
        data: {...data, [key]: value},
        validation: {...validation, [key]: {}}
    });

    const submit = () => {
        const validation = validator.validate(data);
        if (validation.isValid) {
            dispatch({inProgress: true});
            ApiEquipment.clone(data).then(resp => {
                if (resp.status === "error") {
                    const validation = state.validation;
                    Object.keys(resp.errors).map(key => {
                        validation[key].isValid = false;
                        validation[key].message = resp.errors[key];
                    });
                    dispatch({validation, inProgress: false});
                } else {
                    window.location = `/chart/${resp.equipment.id}`;
                }
            });
        } else {
            dispatch({validation});
        }
    };

    return (
        <Modal
            size="md"
            {...props}
            showModal={true}
            submitTitle="Clone"
            title="Clone Equipment"
            className="custom-modal"
            inProgress={inProgress}
            onSubmit={submit}
            onClose={() => history.push(Helper.deleteHashParams(["modal"]))}
        >
            <div className="mb-2"/>
            <div className="form-group">
                <label>Name: <span className="color-danger">*</span></label>
                <input
                    ref={inputNameRef}
                    type="text"
                    className={"form-control" + (validation.name.isValid || !validation.name.message ? "" : " is-invalid")}
                    onChange={ev => updateData("name", ev.target.value)}
                    value={data.name}
                    disabled={inProgress}
                />
                <ValidationError message={validation.name.message}/>
            </div>
            <div className="form-group">
                <label>Options:</label>
                <div>
                    <label className="form-checkbox modal-checkbox">
                        <input
                            type="checkbox"
                            onChange={ev => updateData("options", {
                                ...data.options,
                                installationPoints: ev.target.checked,
                                alertConditions: !ev.target.checked ? false : data.options.alertConditions,
                                bearings: !ev.target.checked ? false : data.options.bearings
                            })}
                            checked={!!(data.options || {}).installationPoints}
                            disabled={inProgress}
                        /> With installation points<span/>
                    </label>
                </div>
                <div>
                    <label className="form-checkbox modal-checkbox">
                        <input
                            type="checkbox"
                            onChange={ev => updateData("options", {
                                ...data.options,
                                bearings: ev.target.checked
                            })}
                            checked={!!(data.options || {}).bearings}
                            disabled={inProgress || !(data.options || {}).installationPoints}
                        /> With bearings for installation points<span/>
                    </label>
                </div>
                <div>
                    <label className="form-checkbox modal-checkbox">
                        <input
                            type="checkbox"
                            onChange={ev => updateData("options", {
                                ...data.options,
                                alertConditions: ev.target.checked
                            })}
                            checked={!!(data.options || {}).alertConditions}
                            disabled={inProgress || !(data.options || {}).installationPoints}
                        /> With alert conditions for installation points<span/>
                    </label>
                </div>
                <div>
                    <label className="form-checkbox modal-checkbox">
                        <input
                            type="checkbox"
                            onChange={ev => updateData("options", {
                                ...data.options,
                                photos: ev.target.checked
                            })}
                            checked={!!(data.options || {}).photos}
                            disabled={inProgress}
                        /> With photos<span/>
                    </label>
                </div>
            </div>
        </Modal>
    );
};

EquipmentEditPhotoModal.propTypes = {
    equipment: PropTypes.object,
    history: PropTypes.object
};

export default withRouter(EquipmentEditPhotoModal);
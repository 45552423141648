import {useFFTTimestampsQuery} from "../../../../../../hooks/api/fftReadings/TimestampsQuery";
import {useEquipmentByParams} from "../../../../../../hooks/api/equipment/useEquipmentQuery";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {useMemo} from "react";
import {findLastKey as _findLastKey} from "lodash";
import {useCurrentDate} from "./useCurrentDate";
import {useIsImpactVue} from "../../../hooks/useChartTypes";
import {get as _get} from "lodash";
import {useSelectedAxis} from "./useSelectedAxis";
import {IMPACT_VUE_TIMESTAMPS, REGULAR_TIMESTAMPS} from "../../../../../../constants/constants";

export const useCurrentTimestampsInfo = () => {
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint, shallow);
    const currentDate = useCurrentDate();
    const selectedAxis = useSelectedAxis();
    const axis = +selectedAxis[0];

    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const isImpactVue = useIsImpactVue(chartType);

    const timestampSelector = isImpactVue ? IMPACT_VUE_TIMESTAMPS : REGULAR_TIMESTAMPS;

    const {equipmentItem} = useEquipmentByParams();
    const {data: timestamps} = useFFTTimestampsQuery(equipmentItem.id, (res) => _get(res, [timestampSelector, selectedPoint, axis], {}));

    return useMemo(() => {
        let date = currentDate.date;
        let time = currentDate.time;
        if (!timestamps) {
            return {date, time};
        }
        if (date === "last") {
            date = _findLastKey(timestamps);
        }
        if (time === "last") {
            time = _findLastKey(_get(timestamps, [date], false));
        }
        const fulfilledTimestamp = !date && !time ? "": `${date} ${time}`;
        const readingId = _get(timestamps, [date, time, "id"], false);
        const onDemandReason = _get(timestamps, [date, time, "on_demand_reason"], 0);
        const isOnDemand = _get(timestamps, [date, time, "is_on_demand"], false);
        return {date, time, fulfilledTimestamp, readingId, isOnDemand, onDemandReason};
    }, [timestamps, axis, selectedAxis, currentDate]);
};

import {useFFTTimestampsQuery} from "../../../../../../hooks/api/fftReadings/TimestampsQuery";
import {useEquipmentByParams} from "../../../../../../hooks/api/equipment/useEquipmentQuery";
import {useCallback, useEffect, useState} from "react";
import {get as _get} from "lodash";
import moment from "moment";
import Toast from "../../../../../shared/toast";
import {useChartSelectedChartTypeActions, useChartSelectedChartTypeStore} from "../../../../../../stores/zustand/ChartSelectedChartTypeStore";
import {scrollToFft} from "../../../helpers/scrollToFft";
import {useTimestampsSyncStoreActions} from "../../../store/sync/timestampsSyncStore";

const DEFAULT_SCROLL_DATA = {
    installationPointId: null,
    chartType: null,
};

export const useLocateNearestFFT = () => {
    const {equipmentItem} = useEquipmentByParams();
    const {data: fftTimestampsData, isSuccess} = useFFTTimestampsQuery(equipmentItem.id);

    const selectedCharts = useChartSelectedChartTypeStore();
    const {setCheckedChartType} = useChartSelectedChartTypeActions();
    const [scrollData, setScrollData] = useState(DEFAULT_SCROLL_DATA);
    // eslint-disable-next-line no-unused-vars
    const {setTimestampsForFft} = useTimestampsSyncStoreActions();

    const locateNearestFFT = useCallback(
        (timestamp, installationPointId, axisIds, chartType) => {
            if (!isSuccess || !fftTimestampsData) {
                return;
            }
            const isImpactVue = +chartType === 15;
            const {fftTimestamps, fftTimestampsImpactVue} = fftTimestampsData;
            const timestamps = isImpactVue ? fftTimestampsImpactVue : fftTimestamps;
            axisIds = axisIds.map((axisId) => (+axisId === 4 ? 1 : +axisId));

            let nearestFftTimestamp = null,
                nearestFftTime = null,
                nearestDiff = null;
            for (let axisId of axisIds) {
                const currentTimestamps = _get(timestamps, [installationPointId, axisId], {});

                for (let fftTimestamp in currentTimestamps) {
                    for (let ffTtime in currentTimestamps[fftTimestamp]) {
                        let currentDateTime = moment.utc(`${fftTimestamp} ${ffTtime}`).toDate().getTime();

                        let diff = Math.abs(timestamp - moment.utc(currentDateTime).toDate().getTime());

                        if (nearestFftTimestamp === null || diff < nearestDiff) {
                            nearestFftTimestamp = fftTimestamp;
                            nearestFftTime = ffTtime;
                            nearestDiff = diff;
                        }

                        if (moment.utc(fftTimestamp).toDate().getTime() > timestamp) {
                            break;
                        }
                    }
                }
            }

            if (!nearestFftTimestamp) {
                return Toast.error("Nearest FFT Not Found");
            }

            // if current chart type velocity - show fft velocity else fft acceleration
            const fftChartType = isImpactVue ? 19 : (+chartType === 3 ? 9 : 8);
            if (selectedCharts.checked.indexOf(fftChartType + "") === -1) {
                setCheckedChartType(fftChartType + "");
            }
            setScrollData({
                installationPointId,
                chartType: fftChartType,
            });
            if (typeof nearestFftTimestamp == "string") {
                setTimestampsForFft(installationPointId, {date: nearestFftTimestamp, time: nearestFftTime}, isImpactVue);
                return;
            }

            setTimestampsForFft(installationPointId, nearestFftTimestamp, isImpactVue);
        },
        [fftTimestampsData, equipmentItem, selectedCharts]
    );

    useEffect(() => {
        if (!scrollData.installationPointId || !scrollData.chartType) {
            return;
        }
        if (!selectedCharts.checked.includes(scrollData.chartType + "")) {
            return;
        }
        scrollToFft(scrollData.chartType, scrollData.installationPointId);
        setScrollData(DEFAULT_SCROLL_DATA);
    }, [scrollData, selectedCharts.checked]);

    return locateNearestFFT;
};

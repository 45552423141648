import {find as _find, filter as _filter, get as _get} from "lodash";
import Highcharts from "highcharts";
import {fftAlertTypes} from "../../../../../../constants/constants";
import {useChartRef} from "../useChartRef";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {useEquipmentByParams} from "../../../../../../hooks/api/equipment/useEquipmentQuery";
import {useInstallationPointIds} from "../../../../../../hooks/helpers/useInstallationPointIds";
import {useAlertConditionListQuery} from "../../../../../../hooks/api/alertCondition/useAlertConditionListQuery";
import {useMemo, useState} from "react";
import {useSelectedAxis} from "../helpers/useSelectedAxis";

export const useEnvelopeBuilder = () => {
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint);
    const seriesList = useChartStateStoreContext((state) => state.settings.seriesList, shallow);
    const {equipmentItem} = useEquipmentByParams();
    const installationPointIds = useInstallationPointIds(equipmentItem);
    const chartRef = useChartRef();
    const {alertConditions: alerts, isSuccess} = useAlertConditionListQuery(chartType, installationPointIds);
    const showAlerts = useChartStateStoreContext((state) => state.settings.showAlerts);
    const selectedAxis = useSelectedAxis();
    const [forceUpdate, setForceUpdate] = useState(false);

    const generateEnvelope = () => {
        // TODO: remake chart that chartRef doesn't disappear
        if (showAlerts && isSuccess && !chartRef.current) {
            setTimeout(() => setForceUpdate((prev) => !prev), 100);
            return {envelopeAlerts: [], envelopeSeries: []};
        }
        if (!isSuccess || !showAlerts) {
            return {envelopeAlerts: [], envelopeSeries: []};
        }
        const alertList = _filter(
            alerts,
            (alert) =>
                alert.fft_alert_type === fftAlertTypes.ENVELOPE &&
                selectedPoint === alert.installation_point_id &&
                (!selectedAxis || !alert.axis_id || selectedAxis.indexOf(alert.axis_id + "") >= 0)
        );

        const shapes = [],
            labels = [],
            seriesLocal = [];

        const xAxis = _get(chartRef, "current.chart.xAxis.0");
        const xExtremes = {
            max: chartRef.current.chart.xAxis[0].max,
        };

        let maxX = xAxis ? xAxis.getExtremes().max : xExtremes.max;
        for (let alert of alertList) {
            const tempX = +alert.envelopeData.data[alert.envelopeData.data.length - 1][0];
            if (maxX < tempX) maxX = tempX;
        }

        for (let alert of alertList) {
            const color = new Highcharts.Color(alert.alertLevel.color).setOpacity(0.8).get();

            seriesLocal.push({
                id: "alert_" + alert.id,
                color,
                data: alert.envelopeData.data,
                name: alert.title,
                units: seriesList.units,
                precision: seriesList.precision,
                enableMouseTracking: false,
            });

            const dataMax = alert.envelopeData.data.reduce((result, current) => (current[1] > result[1] ? current : result), [0, 0]);

            shapes.push({
                type: "path",
                dashStyle: "longdashdot",
                fill: false,
                stroke: color,
                points: [
                    {x: dataMax[0], y: dataMax[1], xAxis: 0, yAxis: 0},
                    {x: maxX, y: dataMax[1], xAxis: 0, yAxis: 0},
                ],
            });

            const style = `background: ${alert.alertLevel.color}; height: 10px; width: 10px; margin-top: 10px;`;
            const pointName = (_find(equipmentItem.installationPoints, ["id", alert.installation_point_id]) || {}).name || "";
            const text =
                `<span class="badge badge-square alert-badge-${chartType}" data-alert-id="${alert.id}" ` +
                `data-installation-point-id="${alert.installation_point_id}" data-axis-id="${alert.axis_id || (alert.readingType || {}).axis_id}" ` +
                `data-fft-alert-type="${alert.fft_alert_type}" style="${style}" title="${pointName.replace(/"/g, "")}"/>`;
            labels.push({
                allowOverlap: 0,
                backgroundColor: "none",
                borderWidth: 0,
                distance: -10,
                padding: 0,
                useHTML: true,
                text,
                point: {x: maxX, y: dataMax[1], xAxis: 0, yAxis: 0},
            });
        }

        return {
            envelopeAlerts: {
                draggable: "",
                shapes,
                labels,
            },
            envelopeSeries: seriesLocal,
        };
    };

    return useMemo(generateEnvelope, [alerts, showAlerts, seriesList, chartRef.current, selectedAxis, forceUpdate]);
};

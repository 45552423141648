import React, {Component} from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Loader from "../../../../../shared/loader/loader";
import moment from "moment";
import {AXES_COLORS, AXES_COLORS_DARK, DATEFORMAT} from "../../../../../constants/constants";
import Helper from "../../../../../helpers/helper";
import {get as _get} from "lodash";
import {withFftReadingBatch} from "../hoc/withFftReadingBatch";

const isDarkTheme = localStorage.getItem("lightMode");
const darkThemeLines = {"#d9534f": "#AD3835", "#f0ad4e": "#D38F2E"};

const height = 250;

const initialOptions = {
    chart: {
        animation: false,
        zoomType: false,
        wrapZoomType: false,
        backgroundColor:
            isDarkTheme === "true"
                ? {
                      linearGradient: {x1: 0, y1: 0, x2: 1, y2: 1},
                      stops: [
                          [0, "#3a3934"],
                          [1, "#36342a"],
                      ],
                  }
                : "#FFFFFF",
        height,
    },
    credits: {
        enabled: false,
    },
    plotOptions: {
        line: {
            lineWidth: 1,
            states: {
                hover: {
                    lineWidth: 1,
                },
            },
        },
        series: {
            animation: false,
            connectNulls: false,
        },
    },
    title: {
        text: "",
    },
    legend: {
        enabled: false,
    },
    xAxis: {
        type: "datetime",
    },
    yAxis: {
        opposite: false,
        title: {
            align: "middle",
            text: "",
        },
        ...(isDarkTheme === "true" ? {gridLineColor: "rgba(200,200,200,0.4)"} : {}),
        ...(isDarkTheme === "true" ? {gridLineWidth: 0.5} : {}),
    },
    rangeSelector: {
        enabled: false,
    },
    tooltip: {
        backgroundColor: isDarkTheme === "true" ? "rgba(54, 54, 50, 0.9)" : null,
        borderWidth: 0,
        shadow: false,
        animation: false,
        shape: "rect",
        useHTML: true,
        formatter: function () {
            const datetimeFormat = Object.keys(DATEFORMAT)[0];

            const rows = [];
            for (let point of this.points) {
                let row =
                    "<span><span style=\"color: " +
                    point.color +
                    "\">" +
                    point.series.name +
                    "</span>: " +
                    Helper.dateToFormat(point.x, datetimeFormat) +
                    "</span><br/>";
                row +=
                    "<span><b>" +
                    Helper.numberFormat(point.y, point.series.userOptions.precision) +
                    "</b> " +
                    point.series.userOptions.units +
                    "</span><br/>";
                rows.push(row);
            }

            return "<div class=\"chart-tooltip\">" + rows.join("<hr/>") + "</div>";
        },
        ...(isDarkTheme === "true" ? {style: {color: "#ddd"}} : {}),
        shared: true,
    },
    series: [],
    navigation: {
        buttonOptions: {
            enabled: false,
        },
    },
};

const propTypes = {
    alert: PropTypes.object,
    chartTypes: PropTypes.array,
    axisLabels: PropTypes.object,
    batchReadings: PropTypes.object,
    isSuccess: PropTypes.bool,
};

class BandTrendChart extends Component {
    constructor(props) {
        super(props);

        this.chartRef = React.createRef();

        this.state = {
            options: initialOptions,
            loader: true,
        };

        this.precision = _get(props.chartTypes, [props.alert.chartType, "series", 1, "precision"]);
        this.units = _get(props.chartTypes, [props.alert.chartType, "series", 1, "units"], "");

        this.drawPlotLine = this.drawPlotLine.bind(this);
        this.drawSeries = this.drawSeries.bind(this);
    }

    componentDidMount() {
        const {batchReadings} = this.props;
        if (batchReadings.isSuccess) {
            this.drawPlotLine();
            this.drawSeries();
        }
    }

    componentDidUpdate(prevProps) {
        const {alert, batchReadings} = this.props;

        if (
            alert.installationPointId !== prevProps.alert.installationPointId ||
            alert.alert_level_id !== prevProps.alert.alert_level_id ||
            alert.fftAlertType !== prevProps.alert.fftAlertType ||
            alert.axisId !== prevProps.alert.axisId ||
            alert.value !== prevProps.alert.value ||
            alert.index !== prevProps.alert.index ||
            batchReadings !== prevProps.batchReadings
        ) {
            this.drawPlotLine();
        }

        if (
            alert.installationPointId !== prevProps.alert.installationPointId ||
            alert.fftBandFrequencyType !== prevProps.alert.fftBandFrequencyType ||
            alert.fftAlertType !== prevProps.alert.fftAlertType ||
            alert.fftBandFrom !== prevProps.alert.fftBandFrom ||
            alert.fftBandTo !== prevProps.alert.fftBandTo ||
            alert.axisId !== prevProps.alert.axisId ||
            batchReadings !== prevProps.batchReadings
        ) {
            this.drawSeries();
        }
    }

    drawPlotLine() {
        const options = {...this.state.options};
        const {alert} = this.props;

        let plotLines = [];
        const color = alert.alertLevel.color;
        options.xAxis = {type: "datetime"};
        options.yAxis.softMin = parseFloat(alert.value) * 0.95;
        options.yAxis.softMax = parseFloat(alert.value) * 1.05;
        if (alert.value !== undefined) {
            plotLines.push({
                id: alert.alert_level_id,
                className: "cursor-move",
                value: parseFloat(alert.value),
                color: isDarkTheme === "true" ? darkThemeLines[color] || color : color,
                width: 2,
                zIndex: 9,
            });
        }
        options.yAxis.plotLines = plotLines.reverse();
        this.setState({options, loader: false});
    }

    drawSeries() {
        this.setState({loader: true});

        this.getSeries().then((series) => {
            const options = {...this.state.options};

            options.series = series;

            this.setState({options, loader: false});
        });
    }

    getSeries() {
        const {axisLabels, chartTypes, alert, batchReadings: readings} = this.props;

        const getAxisSeries = async (axisId) => {
            if (!readings) {
                return [];
            }
            const data = [];

            for (const reading of readings) {
                data.push({
                    x: +moment.utc(reading.timestamp).format("x"),
                    y: this.getMaxValue(reading, alert.fftBandFrom, alert.fftBandTo),
                    datetime: reading.timestamp,
                });
            }

            return {
                name: _get(axisLabels, [axisId, alert.installationPointId]) || chartTypes[alert.chartType].series[axisId].axisName + " - Axis",
                color: isDarkTheme === "true" ? AXES_COLORS_DARK[axisId] : AXES_COLORS[axisId],
                dataGrouping: {approximation: "high", forced: true},
                units: this.units,
                precision: this.precision,
                data,
            };
        };

        if (+alert.axisId !== 0) {
            return getAxisSeries(alert.axisId).then((series) => [series]);
        } else {
            const promiseArr = [];
            const axisIds = Object.keys(chartTypes[alert.chartType].series);

            for (let axisId of axisIds) {
                promiseArr.push(getAxisSeries(axisId));
            }
            return Promise.all(promiseArr);
        }
    }

    getMaxValue(reading, from, to) {
        let result = 0;

        for (let i = 0; i < reading.data.length; i++) {
            if (reading.data[i][0] > to) {
                break;
            }

            if (reading.data[i][0] >= from && reading.data[i][1] > result) {
                result = reading.data[i][1];
            }
        }

        return result;
    }

    render() {
        const {options} = this.state;
        const {isSuccess} = this.props;
        return (
            <div
                style={{height}}
                className="mb-2"
            >
                {!isSuccess ? (
                    <Loader />
                ) : (
                    <HighchartsReact
                        ref={this.chartRef}
                        highcharts={Highcharts}
                        constructorType={"chart"}
                        options={options}
                    />
                )}
            </div>
        );
    }
}

BandTrendChart.propTypes = propTypes;

export default withFftReadingBatch(BandTrendChart);

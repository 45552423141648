import React, {Component} from "react";

import {BrowserRouter as Router} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {ModalProvider} from "react-modal-hook";
import RoutesContent from "./routes-content";

import MainLayout from "./layouts/MainLayout/MainLayout";
import {createInstance, MatomoProvider} from "@datapunt/matomo-tracker-react";
import MatomoLayout from "./components/matomoLayout";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 0,
            cacheTime: 60 * 1000,
            staleTime: Infinity,
            refetchOnWindowFocus: false,
        },
    },
});

const matomoInstance = createInstance({
    urlBase: process.env.REACT_APP_MATOMO_URL,
    siteId: process.env.REACT_APP_MATOMO_SITE_ID,
    linkTracking: false,
    disabled: !process.env.REACT_APP_MATOMO_ENABLED
});

class RouterWrapper extends Component {
    render() {
        return (
            <QueryClientProvider client={queryClient}>
                <ModalProvider>
                    <Router>
                        <MatomoProvider value={matomoInstance}>
                            <MainLayout>
                                <MatomoLayout>
                                    <RoutesContent />
                                </MatomoLayout>
                            </MainLayout>
                        </MatomoProvider>
                        <ReactQueryDevtools initialIsOpen={process.env.mode == "development"} />
                    </Router>
                </ModalProvider>
            </QueryClientProvider>
        );
    }
}

export default RouterWrapper;

import React, { Component } from "react";
import PropTypes from "prop-types";
import ApiAlertLevel from "../../api/alertLevel";
import ApiAlarms from "../../api/alarm";
import ApiAxisLabel from "../../api/axisLabel";
import ApiReadingTypes from "../../api/readingTypes";
import { Loader, Pager } from "../../shared";
import HistoryTable from "./history-table";
import SearchInput from "../shared/search-input";
import AlarmFilters from "./alarm-filters";
import LimitSelect from "../shared/limit-select";
import { withGlobalStore } from "../../stores/GlobalStore";
import { withLocationSelectStore } from "../../stores/LocationSelectStore";
import Helper from "../../helpers/helper";
import { HeaderSimple } from "../../shared/header";

class AlarmsHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbs: [{name: "Triggered Alert History"}],
            loader: true,
            list: [],
            settingsStates: {},
            axisLabels: {},
            pagination: {
                page: 1,
                pages: 1,
                perpage: Helper.getPerPage("alarms-history"),
                total: 0
            },
            sort: {
                field: "",
                sort: "asc"
            },
            filter: {
                chartType: "",
                type: "",
                axis: "",
                level: "",
                query: "",
                location: props.getLocationId(),
            },
            levels: [],
            readings: [],
            globalSearchString: ""
        };

        this.finished = false;

        this.handleFetch = this.handleFetch.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handlePagerChange = this.handlePagerChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.bindSearch = this.bindSearch.bind(this);
        this.handleChangeGlobalSearch = this.handleChangeGlobalSearch.bind(this);
    }

    componentDidMount() {
        this.handleFetch();
    }

    handleChangeGlobalSearch = (globalSearchString) => {
        this.setState({globalSearchString: globalSearchString});
    };

    handleSearchFetch = () => {
        this.controller = new window.AbortController();
        this.signal = this.controller.signal;

        ApiAlarms.getHistoryList({
            query: {
                sort: this.state.sort,
                filter: this.state.filter,
                pagination: this.state.pagination
            },
            signal: this.signal
        }).then(response => {
            if (response) {
                this.setState({
                    loader: false,
                    list: response ? response.list : [],
                    settingsStates: response ? response.settingsStates : {},
                    pagination: Object.assign({}, this.state.pagination, response ? response.meta : {}),
                }, () => {
                    if (!this.finished) {
                        this.finished = true;
                    }
                });
            }
        });
    };

    handleFetch() {
        Promise.all([
            ApiAlertLevel.getList(),
            ApiReadingTypes.list(),
            ApiAxisLabel.get()
        ]).then(([levels, readings, axisLabels]) => {
            this.setState({
                levels: levels.list,
                axisLabels: axisLabels.data || {},
                readings: [].concat(...Object.values(readings.list)),
            }, this.handleSearchFetch);
        });
    }

    handleSortChange(field = "") {
        const {sort} = this.state;

        this.setState({
            sort: Object.assign({}, {
                field,
                sort: field && field === sort.field ? (sort.sort === "asc" ? "desc" : "asc") : "asc"
            })
        }, this.handleSearchFetch);
    }

    handlePagerChange(page = 1) {
        this.setState({loader: true, pagination: Object.assign({}, this.state.pagination, {page})}, this.handleSearchFetch);
    }

    handleFilterChange(obj = {}) {
        this.finished = false;
        this.setState({
            pagination: Object.assign({}, this.state.pagination, {page: 1}),
            filter: {
                ...this.state.filter,
                ...obj,
            },
            loader: true,
        }, this.handleSearchFetch);
    }

    bindSearch(value) {
        if (!this.finished) {
            this.finished = true;
            this.controller.abort();
        }
        this.handleFilterChange({query: value});
    }
    handleLimitChange = e => {
        const {name, value} = e.target;
        Helper.setPerPage("alarms-history", value);
        this.setState({
            loader: true,
            pagination: {
                ...this.state.pagination,
                page: 1,
                [name]: value
            }
        }, this.handleSearchFetch);
    };

    render() {
        const {
            breadcrumbs,
            loader,
            list,
            axisLabels,
            pagination,
            sort,
            filter,
            settingsStates,
            globalSearchString
        } = this.state;

        const {user} = this.props;

        return (
            <div>
                <HeaderSimple
                    breadcrumbs={ breadcrumbs }
                    globalSearchString={ globalSearchString }
                    handleChangeGlobalSearch={ this.handleChangeGlobalSearch }
                />
                <div className="subheader">
                    <div className="subheader-title">Triggered Alert History</div>
                </div>
                <div className="block">
                    <div className="block-header">
                        <div className="block-label">
                            <AlarmFilters updated={this.handleFilterChange}/>
                        </div>
                        <div id="top-pagination-block" className="block-pagination">
                            {pagination.total > 10 &&
                            <div className="limit-select-block">
                                <LimitSelect
                                    name={"perpage"}
                                    onChange={this.handleLimitChange}
                                    defaultValue={pagination.perpage}
                                    limits={[10, 20, 50, 100]}
                                />
                            </div>
                            }
                            {pagination.pages > 1 &&
                            <Pager
                                page={pagination.page}
                                pages={pagination.pages}
                                onPagerChange={this.handlePagerChange}
                            />
                            }
                        </div>
                        <div className="block-toolbar">
                            <div className="block-toolbar-wrapper">
                                <div style={{minWidth: "180px"}}>
                                    <SearchInput
                                        onChange={this.bindSearch}
                                        query={filter.query}
                                        placeholder="Search Alerts"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block-body">
                        {loader
                            ?
                            <Loader/>
                            :
                            <div>
                                <HistoryTable
                                    list={list}
                                    settingsStates={settingsStates}
                                    profile={user}
                                    axisLabels={axisLabels}
                                    sort={sort}
                                    filter={filter}
                                    onSortChange={this.handleSortChange}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

AlarmsHistory.propTypes = {
    user: PropTypes.object,
    history: PropTypes.object,

    // LocationSelectStore
    getLocationId: PropTypes.func
};

export default withLocationSelectStore(withGlobalStore(AlarmsHistory));
import {useQueries} from "@tanstack/react-query";
import {FFT_TIMESTAMPS_API} from "./constants";
import ApiReading from "../../../api/reading";
import {axisSelector, pointSelector, useFFTTimestampsQuery} from "./TimestampsQuery";
import {get as _get, findLastKey as _findLastKey} from "lodash";
import {useMemo} from "react";
import {useQueriesStatus} from "../../useQueriesStatus";
import {useImpactVueChartTypeMapper} from "../../../pages/chart/highcharts/hooks/useChartTypes";
export const useGetFFTReadings = (chartType, paramsArray, isEnabled = true) => {
    //TODO REWORK API response for ImpactVue charts in case remove this mapper
    const impactVueChartType = useImpactVueChartTypeMapper(chartType);

    const queries = useMemo(() => {
        let queries = [];
        paramsArray.forEach((params) => {
            queries.push({
                queryKey: [
                    FFT_TIMESTAMPS_API.FFT_READING,
                    params.instPointID,
                    params.axisID,
                    params.readingID,
                    params.isImpactVue,
                    params.highpassFactor,
                    params.isInterpolation,
                ],
                queryFn: () =>
                    ApiReading.getChartFFTData(
                        params.instPointID,
                        params.axisID,
                        params.readingID,
                        params.isImpactVue,
                        params.highpassFactor,
                        params.isInterpolation
                    ).then(({response}) => response),
                select: (data) => {
                    return {...data[params.isImpactVue ? impactVueChartType : chartType][params.axisID][params.instPointID][params.readingID], ...params};
                },
                cacheTime: "Infinity",
                enabled: isEnabled,
            });
        });
        return queries;
    }, [paramsArray]);
    const results = useQueries({queries});
    return useQueriesStatus(results);
};

export const useGetPointReadingParamsByDateTime = (
    equipmentID,
    instPointID,
    axis,
    date = {
        date: "last",
        time: "last",
    },
    isImpactVue = false,
    highpassFactor = 20,
    isInterpolation
) => {
    const {isSuccess, data: timestamps} = useFFTTimestampsQuery(equipmentID, (data) => pointSelector(data, isImpactVue, instPointID));
    const params = [];
    if (!Array.isArray(axis)) {
        axis = [axis];
    }
    if (isSuccess && timestamps) {
        axis.forEach((axisID) => {
            let currentDate = date.date;
            let currentTime = date.time;
            let readingID;

            if (currentDate === "last") {
                currentDate = _findLastKey(_get(timestamps, [axisID], []));
            }
            if (currentTime === "last") {
                currentTime = _findLastKey(_get(timestamps, [axisID, currentDate], []));
            }
            readingID = _get(timestamps, [axisID, currentDate, currentTime, "id"], false);
            if (readingID) {
                params.push({
                    instPointID,
                    axisID,
                    readingID,
                    isImpactVue,
                    highpassFactor,
                    isInterpolation,
                });
            }
        });
    }

    return {isSuccess, params};
};

export const useGetPointReadingParamsByDateTimeBatch = (
    equipmentID,
    instPointID,
    axisID,
    dates,
    isImpactVue = false,
    highpassFactor = 20,
    isInterpolation = false
) => {
    const {isSuccess, data: timestamps} = useFFTTimestampsQuery(equipmentID, (data) => axisSelector(data, isImpactVue, instPointID, axisID));

    let params = [];
    if (isSuccess) {
        dates.forEach((date) => {
            const readingID = _get(timestamps, [date.date, date.time, "id"], false);
            if (readingID) {
                params.push({
                    instPointID,
                    axisID,
                    readingID,
                    isImpactVue,
                    highpassFactor,
                    isInterpolation,
                });
            }
        });
    }

    return {isSuccess, params};
};

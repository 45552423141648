import {useGetFFTReadings, useGetPointReadingParamsByDateTime} from "../../../../../../hooks/api/fftReadings/ReadingsQuery";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {usePointData} from "../../../hooks/usePointsData";
import {useIsImpactVue} from "../../../hooks/useChartTypes";
import {useCurrentDate} from "./useCurrentDate";
import {useSelectedAxis} from "./useSelectedAxis";

export const useChartReadings = () => {
    const chartType = useChartStateStoreContext((state) => state.settings.chartType, shallow);
    const isImpactVue = useIsImpactVue(chartType);
    const currentDate = useCurrentDate();
    const selectedAxis = useSelectedAxis();
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint);
    const pointData = usePointData(selectedPoint);

    const {params: readingsParams} = useGetPointReadingParamsByDateTime(
        pointData.equipment_id,
        pointData.id,
        selectedAxis,
        currentDate,
        isImpactVue,
        pointData?.settings?.high_pass
    );

    const {data, isSuccess} = useGetFFTReadings(chartType, readingsParams);

    return {data, isSuccess};
};

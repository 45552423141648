import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useReadingBatchQuery } from "../../../../../hooks/api/fftReadings/ReadingBatchQuery";
import { values as _values } from "lodash";
import { useEquipmentByParams } from "../../../../../hooks/api/equipment/useEquipmentQuery";
import { axisSelector, useFFTTimestampsQuery } from "../../../../../hooks/api/fftReadings/TimestampsQuery";
import {
    useHighPassFilterSyncStoreByPoint
} from "../../../../../pages/chart/highcharts/store/sync/HighPassFilterSyncStore";
import {
    useInterpolationSyncStoreByPoint
} from "../../../../../pages/chart/highcharts/store/sync/InterpolationSuncStore";
import { usePointData } from "../../../../../pages/chart/highcharts/hooks/usePointsData";

// eslint-disable-next-line react/display-name
export const withFftReadingBatch = (Component) => {
    const Hoc = ({alert, ...rest}) => {
        const {equipmentItem} = useEquipmentByParams();
        const {installationPointId, chartType, axisId} = alert;
        const pointData = usePointData(installationPointId);
        const highpassFilter = useHighPassFilterSyncStoreByPoint(installationPointId, pointData);
        const isInterpolation = useInterpolationSyncStoreByPoint(installationPointId);
        const {isSuccess, data: fftTimestamps} = useFFTTimestampsQuery(equipmentItem.id, (data) => axisSelector(data, false, installationPointId, axisId));
        const count = 25;

        // TODO this is temporary solution
        const readingIds = useMemo(() => {
            if (!isSuccess) {
                return [];
            }

            const orderedFftTimestamps = Object.keys(fftTimestamps).reverse().reduce(
                (obj, key) => {
                    obj[key] = fftTimestamps[key];
                    return obj;
                },
                {}
            );

            if (+axisId !== 0) {
                return _values(orderedFftTimestamps).reduce((ids, item) => {
                    const timestampIds = _values(item);

                    timestampIds.forEach((i) => {
                        if (ids.length < count) {
                            ids.push(i.id);
                        }
                    });

                    return ids.sort();
                }, []);
            }
            const readingIdsByAxis = {};

            for (const timestamp in orderedFftTimestamps) {
                readingIdsByAxis[timestamp] = _values(fftTimestamps[timestamp]).reduce((ids, item) => {
                    const timestampIds = _values(item);

                    timestampIds.forEach((i) => {
                        if (ids.length < count) {
                            ids.push(i.id);
                        }
                    });

                    return ids.sort();
                }, []);
            }

            return readingIdsByAxis;
        }, [fftTimestamps, axisId, installationPointId, count]);

        const {isSuccess: isBatchSuccess, data} = useReadingBatchQuery({
            installationPointId,
            chartType,
            axisIds: [axisId],
            readingIds,
            highpassFilter,
            isInterpolation
        });

        const batchReadings = useMemo(() => {
            if (!isBatchSuccess) {
                return [];
            }
            return data.reduce((readings, i) => {
                return [...readings, ...Object.values(i)];
            }, []);
        }, [isSuccess, data]);
        return (
            <Component
                {...rest}
                batchReadings={batchReadings}
                isSuccess={isBatchSuccess}
                alert={alert}
            />
        );
    };

    Hoc.propTypes = {
        alert: PropTypes.object,
    };

    return Hoc;
};

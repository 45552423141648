import React, {Component} from "react";
import PropTypes from "prop-types";
import {Modal, ValidationError} from "../../../shared";
import FormValidator from "../../../helpers/form-validator";
import Helper from "../../../helpers/helper";
import {find as _find} from "lodash";
import FrequencyConverter from "../../../helpers/frequency-converter";
import {FREQUENCY_TYPES} from "../../../constants/constants";
import SelectWrapper from "../../../helpers/select-wrapper";
import Toast from "../../../pages/shared/toast";
import {cloneDeep as _cloneDeep, isEqual as _isEqual} from "lodash";

const rules = [
    {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required.",
    },
    {
        field: "name",
        method: (val) => val.length <= 25,
        validWhen: true,
        message: "This field cannot have more than 25 characters.",
    },
    {
        field: "frequency",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required.",
    },
    {
        field: "frequency",
        method: "isFloat",
        validWhen: true,
        message: "Frequency should be valid number.",
    },
    {
        field: "plotLinesCount",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required.",
    },
    {
        field: "plotLinesCount",
        method: "isInt",
        validWhen: true,
        message: "Plot Lines should be valid number.",
    },
    {
        field: "plotLinesCount",
        method: (val) => val >= 1 && val <= 50,
        validWhen: true,
        message: "Plot Lines should be from 1 to 50.",
    },
];

const propTypes = {
    equipment: PropTypes.object,
    frequency: PropTypes.number,
    pointData: PropTypes.object,
    faultFrequencyId: PropTypes.number,
    faultFrequencies: PropTypes.array,
    history: PropTypes.object,
    addFaultFrequency: PropTypes.func,
    updateFaultFrequency: PropTypes.func,
    onClose: PropTypes.func,
    onCreate: PropTypes.func,
    frequencyType: PropTypes.string,
    getPointById: PropTypes.func,
    currentSpeed: PropTypes.object,
};

class Form extends Component {
    constructor(props) {
        super(props);
        this.validator = new FormValidator(rules);

        this.isEdit = !!this.props.faultFrequencyId;

        if (this.isEdit) {
            this.faultFrequency = _find(this.props.faultFrequencies, ["id", this.props.faultFrequencyId]);
        }

        this.state = {
            validation: this.validator.valid(),
            inProgress: false,
            data: {
                equipmentId: this.props.equipment.id,
                installationPointId:
                    this.isEdit && this.faultFrequency.installation_point_id
                        ? this.faultFrequency.installation_point_id
                        : this.props.pointData.id,
                name: this.isEdit ? this.faultFrequency.name : "",
                frequency: this.isEdit ? this.faultFrequency.frequency
                    : (this.props.frequency && this.props.currentSpeed.value !== 0)
                    ? FrequencyConverter.fromHz(this.props.frequency, this.props.currentSpeed.value)
                          .toType(this.props.frequencyType)
                          .numberFormat()
                    : "",
                frequencyType: this.isEdit ? this.faultFrequency.frequency_type : (this.props.currentSpeed.value === 0 && this.props.frequencyType === FREQUENCY_TYPES.ORDERS) ? FREQUENCY_TYPES.HZ : this.props.frequencyType,
                plotLinesCount: this.isEdit ? this.faultFrequency.plot_lines_count : undefined,
            },
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    componentDidMount() {
        this.setState({dataCopy: _cloneDeep(this.state.data)});
    }

    get isDataEqualCopy() {
        return _isEqual(this.state.data, this.state.dataCopy);
    }

    onChange(event) {
        const data = {...this.state.data};
        const value = Helper.getInputValue(event.target);

        if (event.target.name === "frequencyType") {
            data.frequency = FrequencyConverter.fromType(data.frequencyType, data.frequency, this.props.currentSpeed.value)
                .toType(value)
                .numberFormat();
        }

        data[event.target.name] = value;

        this.setState({data, validation: this.validator.valid()});
    }

    onSubmit() {
        this.setState({inProgress: true}, () => {
            const validation = this.validator.validate(this.state.data);

            this.setState({validation}, () => {
                if (validation.isValid) {
                    if (this.isEdit) {
                        this.edit(this.getData()).catch(() => this.setState({inProgress: false}));
                    } else {
                        this.create(this.getData()).catch(() => this.setState({inProgress: false}));
                    }
                } else {
                    this.setState({inProgress: false});
                }
            });
        });
    }

    async create(data) {
        this.props.onCreate(data).then(() => {
            this.props.onClose();
            Toast.success("The fault frequency has been created.");
        });
    }

    edit(data) {
        return this.props.updateFaultFrequency(data).then(() => {
            this.props.onClose();
            Toast.success("The fault frequency has been updated.");
        });
    }

    getData() {
        return {
            name: this.state.data.name,
            frequency: this.state.data.frequency,
            frequencyType: this.state.data.frequencyType,
            equipmentId: this.state.data.equipmentId,
            installationPointId: +this.state.data.installationPointId,
            plotLinesCount: +this.state.data.plotLinesCount,
        };
    }

    render() {
        const {inProgress, validation} = this.state;
        return (
            <Modal
                {...this.props}
                size="lg"
                title={"Set fault frequency"}
                onSubmit={this.onSubmit}
                inProgress={this.state.inProgress}
                disableSubmit={this.isDataEqualCopy}
            >
                <React.Fragment>
                    <div className="row">
                        <div className="form-group col-4 mb-2">
                            <label>Asset Code:</label>
                            <p className="mb-2">{this.props.equipment.asset_code}</p>
                        </div>
                        <div className="form-group col-6 mb-2">
                            <label>Equipment:</label>
                            <p className="mb-2">{this.props.equipment.name}</p>
                        </div>
                        <div className="form-group col-2 mb-2">
                            <label>{this.props.currentSpeed.from} RPM:</label>
                            <p className="mb-2">{this.props.currentSpeed.value > 0 ? this.props.currentSpeed.value : "Not set"}</p>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-3">
                            <div className="form-group">
                                <label className="form-label">Sensor</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={this.props.pointData.name}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group">
                                <label className="form-label">
                                    Title <span className="color-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    className={"form-control" + (validation.name.isValid || !validation.name.message ? "" : " is-invalid")}
                                    placeholder="Enter Frequency Name"
                                    name="name"
                                    disabled={inProgress}
                                    onChange={this.onChange}
                                    value={this.state.data.name}
                                />
                                <ValidationError message={validation.name.message} />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="form-group">
                                <label className="form-label">
                                    Frequency <span className="color-danger">*</span>
                                </label>
                                <input
                                    type="number"
                                    className={
                                        "form-control" +
                                        (validation.frequency.isValid || !validation.frequency.message ? "" : " is-invalid")
                                    }
                                    name="frequency"
                                    disabled={inProgress}
                                    onChange={this.onChange}
                                    value={this.state.data.frequency}
                                />
                                <ValidationError message={validation.frequency.message} />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="form-group">
                                <label className="form-label">
                                    Units <span className="color-danger">*</span>
                                </label>
                                <SelectWrapper
                                    style={{
                                        width: "100%",
                                        display: "block",
                                    }}
                                    name="frequencyType"
                                    disabled={inProgress}
                                    onChange={this.onChange}
                                    value={this.state.data.frequencyType}
                                >
                                    {Object.values(FREQUENCY_TYPES).map((type) => (
                                        <option
                                            value={type}
                                            key={type}
                                            disabled={
                                                !(this.props.currentSpeed.value > 0) &&
                                                ((this.state.data.frequencyType === FREQUENCY_TYPES.ORDERS &&
                                                    type !== FREQUENCY_TYPES.ORDERS) ||
                                                    (this.state.data.frequencyType !== FREQUENCY_TYPES.ORDERS &&
                                                        type === FREQUENCY_TYPES.ORDERS))
                                            }
                                        >
                                            {type}
                                        </option>
                                    ))}
                                </SelectWrapper>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="form-group">
                                <label className="form-label">
                                    Plot Lines <span className="color-danger">*</span>
                                </label>
                                <input
                                    type="number"
                                    className={
                                        "form-control" +
                                        (validation.plotLinesCount.isValid || !validation.plotLinesCount.message ? "" : " is-invalid")
                                    }
                                    name="plotLinesCount"
                                    disabled={inProgress}
                                    onChange={this.onChange}
                                    value={this.state.data.plotLinesCount}
                                />
                                <ValidationError message={validation.plotLinesCount.message} />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            </Modal>
        );
    }
}

Form.propTypes = propTypes;

export default Form;

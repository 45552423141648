import React, {Fragment, Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import Helper from "../../../helpers/helper";
import {find as _find, get as _get} from "lodash";
import {ResetSortButton} from "../../shared/resetSortButton";
import {withGlobalStore} from "../../../stores/GlobalStore";
import {FirmwareVersion} from "../../../shared/FirmwareVersion/FirmwareVersion";

const children = {
    reporting: {title: "Reporting", sort: false, style: {width: 100}},
    installed: {title: "Installed", sort: false, style: {width: 100}},
};

const headersList = {
    signal_strength: {title: "Signal", sort: true, style: {width: 80}},
    location_info: {title: "Location", sort: true},
    name: {title: "Name", sort: true},
    serial: {title: "Serial Number", sort: true},
    nodes: {title: "Nodes", style: {textAlign: "center"}, children},
    sensors: {title: "Tethered Sensors", style: {textAlign: "center"}, children},
    motes: {title: "Sensor Motes", style: {textAlign: "center"}, children},
    last_seen: {title: "Last Seen", sort: true},
    version: {title: "Version", sort: true},
    actions: {title: "Actions", additionalClasses: "table-buttons-th", component: ResetSortButton},
};

class Table extends Component {
    render() {
        const {list, stats, totals, sort, onSortChange, query, user} = this.props;
        const listLen = Object.keys(headersList).length;

        return (
            <div className="table-scroll-wrapp">
                <table className="table table-bordered table-hover tl-fixed">
                    <thead>
                        <tr>
                            {Object.keys(headersList).map((key) => {
                                let component = "";
                                let RowComponent = _get(headersList, [key, "component"], false);
                                if (RowComponent) {
                                    component = (
                                        <RowComponent
                                            sort={sort}
                                            resetSort={() => onSortChange("")}
                                        />
                                    );
                                }
                                return (
                                    (["motes", "nodes", "sensors"].indexOf(key) === -1 || !!totals[`${key}_installed`]) &&
                                    (listLen && headersList[key].sort ? (
                                        <th
                                            className={headersList[key].additionalClasses || ""}
                                            key={key}
                                            style={{verticalAlign: "middle", ...(headersList[key].style || {})}}
                                            rowSpan={headersList[key].children ? 1 : 2}
                                            colSpan={headersList[key].children ? Object.keys(headersList[key].children).length : 1}
                                            onClick={() => onSortChange(key)}
                                        >
                                            {headersList[key].title}
                                            {sort.field === key ? (
                                                <i className={"fa fa-sort" + (sort.sort === "asc" ? "-up" : "-down")} />
                                            ) : (
                                                <i className="fa fa-sort" />
                                            )}
                                        </th>
                                    ) : (
                                        <th
                                            className={headersList[key].additionalClasses || ""}
                                            key={key}
                                            style={{verticalAlign: "middle", ...(headersList[key].style || {})}}
                                            rowSpan={headersList[key].children ? 1 : 2}
                                            colSpan={headersList[key].children ? Object.keys(headersList[key].children).length : 1}
                                        >
                                            {headersList[key].title} {component}
                                        </th>
                                    ))
                                );
                            })}
                        </tr>
                        <tr>
                            {Object.keys(headersList).map(
                                (key) =>
                                    (["motes", "nodes", "sensors"].indexOf(key) === -1 || !!totals[`${key}_installed`]) &&
                                    headersList[key].children &&
                                    Object.keys(headersList[key].children).map((childrenKey) =>
                                        headersList[key].children[childrenKey].sort ? (
                                            <th
                                                key={childrenKey}
                                                style={{verticalAlign: "middle", ...(headersList[key].children[childrenKey].style || {})}}
                                                onClick={() => onSortChange(`${key}.${childrenKey}`)}
                                            >
                                                {headersList[key].children[childrenKey].title}
                                                {sort.field === `${key}.${childrenKey}` ? (
                                                    <i className={"la la-arrow" + (sort.sort === "asc" ? "-up" : "-down")} />
                                                ) : (
                                                    <i className="fa fa-sort" />
                                                )}
                                            </th>
                                        ) : (
                                            <th
                                                key={childrenKey}
                                                style={{verticalAlign: "middle", ...(headersList[key].children[childrenKey].style || {})}}
                                            >
                                                {headersList[key].children[childrenKey].title}
                                            </th>
                                        )
                                    )
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {list.length > 0 ? (
                            list.map((gateway) => (
                                <GatewayRow
                                    query={query}
                                    key={gateway.serial}
                                    gateway={gateway}
                                    stat={_find(stats, (stat) => +stat.gateway_id === gateway.gateway_id) || {}}
                                    totals={totals}
                                    user={user}
                                />
                            ))
                        ) : (
                            <tr>
                                <td
                                    colSpan={11}
                                    className="text-center text-info"
                                >
                                    {query !== "" ? "No items match your search." : "No gateways were found."}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
}

const GatewayRow = ({stat, totals, gateway, query, user}) => {
    const version = gateway?.version;

    return (
        <>
            <tr>
                <td>
                    <span
                        title={
                            Helper.getSignalLabel(gateway.signal_strength) +
                            (gateway.lastSeen ? `\n${Helper.dateToUserFormat(gateway.lastSeen, user)}` : "")
                        }
                        className={`signal-lvl color-${gateway.signal_strength === "default" ? "" : gateway.signal_strength}`}
                        style={{marginLeft: 0}}
                    >
                        {gateway.signal_strength === "default" ? "N/A" : <img src={`/assets/pic/icon-signal-level-${gateway.signal_strength}.svg`} />}
                    </span>
                </td>
                {gateway.located.length ? (
                    <td dangerouslySetInnerHTML={{__html: Helper.highlight(gateway.located, query)}} />
                ) : (
                    <td>
                        <i className="badge badge-secondary">Not Set</i>
                    </td>
                )}
                {gateway.name && gateway.name.length ? (
                    <td dangerouslySetInnerHTML={{__html: Helper.highlight(gateway.name, query)}} />
                ) : (
                    <td>
                        <i className="badge badge-secondary">Not Set</i>
                    </td>
                )}
                <td dangerouslySetInnerHTML={{__html: Helper.highlight("" + gateway.serial, query)}} />
                {!!totals.nodes_installed && (
                    <Fragment>
                        <td>{stat.nodes_reporting || 0}</td>
                        <td>{stat.nodes_installed || 0}</td>
                    </Fragment>
                )}
                {!!totals.sensors_installed && (
                    <Fragment>
                        <td>{stat.sensors_reporting || 0}</td>
                        <td>{stat.sensors_installed || 0}</td>
                    </Fragment>
                )}
                {!!totals.motes_installed && (
                    <Fragment>
                        <td>{stat.motes_reporting || 0}</td>
                        <td>{stat.motes_installed || 0}</td>
                    </Fragment>
                )}
                <td>{gateway.last_seen}</td>
                <td>
                    <FirmwareVersion
                        version={version}
                        isFirmwareExists={!!gateway?.firmwareExist}
                        description={gateway?.firmwareDescription}
                        shortDescription={gateway?.firmwareShortDescription}
                        name={gateway.name}
                        type="Gateway"
                    />
                </td>
                <td>
                    <div className="btn-group btn-group-sm">
                        <Link
                            to={`/network/gateways/${gateway.serial}`}
                            className="link link-primary"
                        >
                            <i className="fa fa-eye" />
                            <span>View</span>
                        </Link>
                    </div>
                </td>
            </tr>
        </>
    );
};

GatewayRow.propTypes = {
    stat: PropTypes.object,
    totals: PropTypes.object,
    gateway: PropTypes.object,
    user: PropTypes.object,
    query: PropTypes.string,
};

Table.propTypes = {
    list: PropTypes.array,
    stats: PropTypes.array,
    totals: PropTypes.object,
    sort: PropTypes.object,
    onSortChange: PropTypes.func,
    user: PropTypes.object,
    query: PropTypes.string,
};

export default withGlobalStore(Table);

import { useMemo } from "react";
import { useIsDarkTheme } from "../helpers/theme";

const darkThemeLines = {"#d9534f": "#AD3835", "#f0ad4e": "#D38F2E"};

export const useDraggableAlertBuilder = ({alert, otherAlerts = [], onMouseDown}) => {
    const isDarkTheme = useIsDarkTheme();

    return useMemo(() => {
        if (!alert?.alertLevel) {
            return [];
        }

        let minItemValue = alert.value;
        let maxItemValue = alert.value;
        const plotLines = [];
        const color = alert.alertLevel.color;

        plotLines.push({
            id: alert.alert_level_id,
            className: "cursor-move",
            value: parseFloat(alert.value || 0),
            color: isDarkTheme === "true" ? darkThemeLines[color] || color : color,
            width: 2,
            zIndex: 10,
            events: {
                mousedown: onMouseDown,
            },
        });

        otherAlerts.forEach((alert) => {
            if (!alert?.value) {
                return;
            }
            const color = alert?.alertLevel?.color;
            if (maxItemValue < alert.value) maxItemValue = alert.value;
            if (minItemValue > alert.value) minItemValue = alert.value;
            plotLines.push({
                value: parseFloat(alert.value || 0),
                color: isDarkTheme === "true" ? darkThemeLines[color] || color : color,
                dashStyle: "ShortDot",
                width: 2,
                zIndex: 9,
            });
        });

        return plotLines;
    }, [alert, otherAlerts]);
};

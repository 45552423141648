import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {Loader, Modal} from "../../../shared";
import {DATEPICKER_TYPE} from "../../../constants/constants";
import Helper from "../../../helpers/helper";
import DatePickerInput from "../../../shared/datePickerInput/datePickerInput";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {get as _get} from "lodash";
import SensorSelect, {PureSensorSelect} from "../../../pages/shared/sensor-select";
import EquipmentSelect from "../../../pages/shared/equipment-select";
import AxisSelect from "../../../pages/shared/axis-select";
import moment from "moment";
import Toast from "../../../pages/shared/toast";
import InstallationPointApi from "../../../api/installationPoint";
import CollapseLocationSelect from "../../../shared/collapseLocationSelect/collapseLocationSelect";
import {useFFTTimestampsQuery} from "../../../hooks/api/fftReadings/TimestampsQuery";
import {findLastKey as _findLastKey} from "lodash";
import {useSelectedByEquipment} from "../../../stores/zustand/ChartSelectedEquipmentStore";

const propTypes = {
    equipment: PropTypes.object,
    user: PropTypes.object,
    onClose: PropTypes.func,
    enableOverlay: PropTypes.func,
    installationPointId: PropTypes.number,
    axisIds: PropTypes.array,
    currentPointId: PropTypes.number,
    isImpactVue: PropTypes.bool,
    chartTimestamp: PropTypes.object,
    overlay: PropTypes.object,
};

const TYPE_CURRENT_SENSOR = "current";
const TYPE_CURRENT_EQUIPMENT = "equipment";
const TYPE_ANOTHER_EQUIPMENT = "another";

const OverlayModal = ({
    isImpactVue,
    chartTimestamp,
    overlay,
    equipment,
    installationPointId,
    axisIds,
    currentPointId,
    user,
    enableOverlay,
    onClose
}) => {
    const startOfMonth = moment().utc().startOf("month");
    const today = moment().utc().startOf("day");
    const range = today.diff(startOfMonth);
    const selectedInstallationsPoints = useSelectedByEquipment(equipment.id);

    const [state, setState] = useState({
        installationPointId: installationPointId || overlay.installationPointId || selectedInstallationsPoints[0],
        axisId: overlay.axisId || _get(axisIds, "0", 1),
        timeDropdown: false,
        currentDate: overlay.date ?? false,
        currentTime: overlay.time ?? false,
        locationId: overlay?.equipment?.location_id ?? false,
        dates: {},
        type: overlay.type || TYPE_CURRENT_SENSOR,
        equipment: overlay.equipment || equipment,
        range,
        similarInstallationPoints: [],
    });
    const {data: fftTimestamps, isSuccess, isFetching} = useFFTTimestampsQuery(state.equipment.id);

    const chartTimestamps = _get(fftTimestamps, `${isImpactVue ? "fftTimestampsImpactVue" : "fftTimestamps"}`);
    const currentTimestamps = _get(chartTimestamps, `${state.installationPointId}.${state.axisId}`, {});

    const availableDates = useMemo(() => {
        if (!isSuccess) {
            return [];
        }
        const currentTimestamps = _get(chartTimestamps, `${state.installationPointId}.${state.axisId}`, {});
        return Object.keys(currentTimestamps || {});
    }, [fftTimestamps, state.axisId, state.installationPointId]);

    const getAvailableTimes = (date) =>
        Object.keys(_get(chartTimestamps, `${state.installationPointId}.${state.axisId}.${date || state.currentDate}`, []));

    const availableTimesPerDay = getAvailableTimes();

    useEffect(() => {
        const lastTimestamp = _findLastKey(_get(chartTimestamps, `${state.installationPointId}.${state.axisId}`, {}));

        setState((prev) => ({
            ...prev,
            currentDate: overlay.date || lastTimestamp,
            currentTime: overlay.time || getAvailableTimes(lastTimestamp)[0],
        }));
    }, [isSuccess]);

    useEffect(() => {
        fetchSimilarInstallationPoints();
    }, []);

    const fetchSimilarInstallationPoints = () => {
        if (currentPointId) {
            InstallationPointApi.getSimilar(currentPointId).then((response) => {
                setState((prev) => ({
                    ...prev,
                    similarInstallationPoints: _get(response, "installationPoints", []),
                }));
            });
        }
    };

    const changeCurrentDate = (currentDate) => {
        setState((prev) => ({
            ...prev,
            currentDate,
            currentTime: Object.keys(currentTimestamps[currentDate])[0],
        }));
    };

    const changeCurrentTime = (currentTime) => {
        setState((prev) => ({
            ...prev,
            currentTime,
        }));
    };

    const onSubmit = () => {
        if (isAnotherEquipment && !state.locationId) {
            return Toast.info("Select location");
        } else if (isAnotherEquipment && !state.equipment) {
            return Toast.info("Select equipment");
        } else if (isAnotherEquipment && !state.installationPointId) {
            return Toast.info("Select sensor");
        } else if (!state.currentTime) {
            return Toast.info("Select a reading date and time.");
        }
        if (chartTimestamp.time === state.currentTime && chartTimestamp.date === state.currentDate) {
            return Toast.info("Trying to overlay same datetime.");
        }
        enableOverlay({
            timestamp: `${state.currentDate} ${state.currentTime}`,
            installationPointId: state.installationPointId,
            equipment: state.equipment,
            axisId: state.axisId,
            date: state.currentDate,
            time: state.currentTime,
            type: state.type,
        });
        onClose();
    };

    const handleChange = (e) => {
        const name = _get(e, "target.name");
        let value = _get(e, "target.value");

        const newState = {[name]: value};
        if (name === "installationPointId") {
            newState[name] = +newState[name];
            newState.currentDate = false;
            newState.currentTime = false;
        } else if (name === "type") {
            if (value === TYPE_ANOTHER_EQUIPMENT) {
                newState.installationPointId = false;
                newState.equipment = false;
                newState.currentDate = false;
                newState.currentTime = false;
            } else {
                newState.installationPointId = installationPointId || selectedInstallationsPoints[0];
                newState.equipment = equipment;
                if (state.installationPointId !== newState.installationPointId) {
                    newState.currentDate = false;
                    newState.currentTime = false;
                }
            }
        } else if (name === "locationId") {
            newState[name] = +newState[name];
            newState.equipment = false;
            newState.installationPointId = false;
            newState.currentDate = false;
            newState.currentTime = false;
        } else if (name === "axisId") {
            newState[name] = +newState[name];
        }
        setState((prev) => ({...prev, ...newState}));
    };

    const handleChangeRecommended = (point) => {
        setState((prev) => ({
            ...prev,
            locationId: +point.equipment.location_id,
            equipment: point.equipment,
            installationPointId: point.id,
            currentDate: false,
            currentTime: false,
        }));
        onChangeMonth(state.range);
    };

    const handleEquipmentChange = (equipment) => {
        setState((prev) => ({...prev, equipment, installationPointId: null, currentDate: false, currentTime: false}));
        onChangeMonth(state.range);
    };
    const isCurrentSensor = useMemo(() => state.type === TYPE_CURRENT_SENSOR, [state.type]);
    const isCurrentEquipment = useMemo(() => state.type === TYPE_CURRENT_EQUIPMENT, [state.type]);
    const isAnotherEquipment = useMemo(() => state.type === TYPE_ANOTHER_EQUIPMENT, [state.type]);

    const onChangeMonth = () => {};

    const {timeDropdown, currentTime, similarInstallationPoints} = state;

    return (
        <Modal
            showModal={true}
            size="md"
            title={"Select date to overlay"}
            className={"custom-modal overlay-modal"}
            onSubmit={onSubmit}
            onClose={onClose}
            submitTitle={"Overlay"}
        >
            <div className="row form-group axis-block-container mt-2">
                <div
                    className={`col-4 axis-block ${!!isCurrentSensor && "active"}`}
                    onClick={() =>
                        !isCurrentSensor &&
                        handleChange({
                            target: {
                                name: "type",
                                value: TYPE_CURRENT_SENSOR,
                                tagName: "",
                            },
                        })
                    }
                >
                    <span>Current Sensor</span>
                </div>
                <div
                    className={`col-4 axis-block ${!!isCurrentEquipment && "active"}`}
                    onClick={() =>
                        !isCurrentEquipment &&
                        handleChange({
                            target: {
                                name: "type",
                                value: TYPE_CURRENT_EQUIPMENT,
                                tagName: "",
                            },
                        })
                    }
                >
                    <span>Current Equipment</span>
                </div>
                <div
                    className={`col-4 axis-block ${!!isAnotherEquipment && "active"}`}
                    onClick={() =>
                        !isAnotherEquipment &&
                        handleChange({
                            target: {
                                name: "type",
                                value: TYPE_ANOTHER_EQUIPMENT,
                                tagName: "",
                            },
                        })
                    }
                >
                    <span>Different Equipment</span>
                </div>
            </div>
            {isCurrentSensor && (
                <div className={"row form-group"}>
                    <div className={"col-12"}>
                        <AxisSelect
                            style={{
                                width: "100%",
                                display: "block",
                            }}
                            name="axisId"
                            value={state.axisId}
                            onChange={handleChange}
                            axes={[
                                {id: 1, name: "X - Axis"},
                                {id: 2, name: "Y - Axis"},
                                {id: 3, name: "Z - Axis"},
                            ]}
                            disabled={isImpactVue}
                        />
                    </div>
                </div>
            )}
            {isCurrentEquipment && (
                <div className={"row form-group"}>
                    <div className={"col-6"}>
                        <SensorSelect
                            name="installationPointId"
                            value={state.installationPointId}
                            onChange={handleChange}
                            equipment={equipment}
                            filterOutTachometers={true}
                        />
                    </div>
                    <div className={"col-6"}>
                        <AxisSelect
                            style={{
                                width: "100%",
                                display: "block",
                            }}
                            name="axisId"
                            value={state.axisId}
                            onChange={handleChange}
                            axes={[
                                {id: 1, name: "X - Axis"},
                                {id: 2, name: "Y - Axis"},
                                {id: 3, name: "Z - Axis"},
                            ]}
                            disabled={isImpactVue}
                        />
                    </div>
                </div>
            )}
            {isAnotherEquipment && (
                <>
                    <div className={"row form-group"}>
                        <div className={"col-4"}>
                            <CollapseLocationSelect
                                className={"form-control modal-input"}
                                emptyOptionLabel={"Select location"}
                                selectName={"locationId"}
                                value={state.locationId || ""}
                                onChange={handleChange}
                                style={{marginLeft: "-10px"}}
                            />
                        </div>
                        <div className={"col-4"}>
                            <EquipmentSelect
                                locationId={state.locationId || 0}
                                emptyOptionLabel={"Select equipment"}
                                selectName={"equipment"}
                                value={(state.equipment && state.equipment.id) || ""}
                                onChange={handleEquipmentChange}
                                disabled={!state.locationId}
                            />
                        </div>
                        <div className={"col-4"}>
                            <PureSensorSelect
                                className="form-control modal-input"
                                name="installationPointId"
                                value={state.installationPointId || ""}
                                onChange={handleChange}
                                equipment={state.equipment || {}}
                                disabled={!state.equipment}
                                filterOutTachometers={true}
                            />
                        </div>
                        {!!similarInstallationPoints.length && (
                            <div className={"p-2"}>
                                <div>Recommended sensors:</div>
                                {similarInstallationPoints.map((point) => (
                                    <div key={point.id}>
                                        <label className="form-checkbox modal-checkbox">
                                            <input
                                                checked={+state.installationPointId === +point.id}
                                                type="checkbox"
                                                onChange={() => handleChangeRecommended(point)}
                                            />
                                            {point.equipment.name} - {point.name}
                                            <span />
                                        </label>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className={"row form-group"}>
                        <div className={"col-12"}>
                            <AxisSelect
                                style={{
                                    width: "100%",
                                    display: "block",
                                }}
                                name="axisId"
                                value={state.axisId}
                                onChange={handleChange}
                                axes={[
                                    {id: 1, name: "X - Axis"},
                                    {id: 2, name: "Y - Axis"},
                                    {id: 3, name: "Z - Axis"},
                                ]}
                                disabled={isImpactVue}
                            />
                        </div>
                    </div>
                </>
            )}

            <div className="row form-group">
                <div className={"col-md-8"}>
                    {!!availableDates.length && (
                        <DatePickerInput
                            className="form-control"
                            inline={true}
                            type={DATEPICKER_TYPE.FFT}
                            value={state.currentDate || ""}
                            availableDates={availableDates || []}
                            onChangeMonth={onChangeMonth}
                            onChangeHandler={changeCurrentDate}
                            displayFormat={Helper.getUserDateFormat(user, true).date}
                            valueFormat={"YYYY-MM-DD"}
                        />
                    )}
                    {!isSuccess && isFetching && <Loader />}
                </div>
                <div className="col-md-4">
                    {state.currentDate && (
                        <Dropdown
                            className="mt-2 text-center"
                            size="sm"
                            isOpen={timeDropdown}
                            toggle={() => setState((prev) => ({...prev, timeDropdown: !state.timeDropdown}))}
                        >
                            <DropdownToggle
                                caret
                                className="btn-default"
                                disabled={(availableTimesPerDay || []).length < 2}
                            >
                                {currentTime || "-"}
                            </DropdownToggle>
                            <DropdownMenu className={(availableTimesPerDay || []).length > 8 ? "custom-scroll" : ""}>
                                {(availableTimesPerDay || []).map((time) => (
                                    <DropdownItem
                                        key={time}
                                        onClick={() => changeCurrentTime(time)}
                                        disabled={time === currentTime}
                                    >
                                        {time}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    )}
                </div>
            </div>
        </Modal>
    );
};

OverlayModal.propTypes = propTypes;

export default OverlayModal;

import {useQuery} from "@tanstack/react-query";
import {ROUTES_API} from "./constants";
import RoutesApi from "../../../api/routes";

export const useRouteByIdQuery = (routeId) => {
    return useQuery({
        queryKey: [ROUTES_API.GET_ROUTE_BY_ID, routeId],
        queryFn: () => RoutesApi.getView(routeId),
        select: (res) => res.route,
        enabled: !!routeId,
    });
};

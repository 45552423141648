import PropTypes from "prop-types";
import React from "react";
import {get as _get, find as _find, map as _map} from "lodash";
import {WOS_TRIGGER_TYPE} from "../stores";
import {UncontrolledTooltip} from "reactstrap";
import {FREQUENCY_TYPES} from "../../../constants/constants";
import InfoTooltip from "../../../shared/infoTooltip/infoTooltip";

// Valid ranges for the threshold amplitude are 78.1 mG to 1G where each tick represent 1/256 Gs. (ie the valid range is 20-256 ticks)
const generateAmplitudeArray = () => {
    let amplitudesArray = [];
    for (let i = 20; i <= 256; i++) {
        amplitudesArray.push({key: i, value: i / 256});
    }
    return amplitudesArray;
};

const amplitudesArray = generateAmplitudeArray();

const frequenciesArray = [
    {key: 0, value: 0.78},
    {key: 1, value: 1.56},
    {key: 2, value: 3.12},
    {key: 3, value: 6.25},
    {key: 4, value: 12.5},
    {key: 5, value: 25},
    {key: 6, value: 50},
];

export const WakeOnShakeView = ({point, triggers}) => {
    if (!_get(triggers, ["data", point.id, WOS_TRIGGER_TYPE])) {
        return (
            <div className={"settings-preview-item triggers-preview"}>
                <div className={"title"}>WoS Trigger</div>
                <div className={"mt-2"}>
                    <b>
                        <i>Not Set</i>
                    </b>
                </div>
            </div>
        );
    }

    let wosDelayStr = +_get(triggers, ["data", point.id, WOS_TRIGGER_TYPE, "delay"], 0) + " ms";

    const frequencyValueKey = +_get(triggers, ["data", point.id, WOS_TRIGGER_TYPE, "data_rate"], 0);
    let frequencyValueStr = _get(_find(frequenciesArray, ["key", frequencyValueKey]), "value") + " " + FREQUENCY_TYPES.HZ;

    const amplitudeValueKey = +_get(triggers, ["data", point.id, WOS_TRIGGER_TYPE, "threshold"], 0);

    let amplitudeValueStr = _get(_find(amplitudesArray, ["key", amplitudeValueKey]), "value");
    amplitudeValueStr = amplitudeValueStr < 1 ? (amplitudeValueStr * 1000).toFixed(2) + " mG" : amplitudeValueStr + " G";

    return (
        <div className={"settings-preview-item triggers-preview"}>
            <div className={"title"}>WoS Trigger</div>
            <div>
                <b>Trigger Delay: </b>
                {wosDelayStr}
            </div>
            <div>
                <b>Threshold Amplitude (pk-pk): </b>
                {amplitudeValueStr}
            </div>
            <div>
                <b>Scan Frequency: </b>
                {frequencyValueStr}
            </div>
        </div>
    );
};
WakeOnShakeView.propTypes = {
    point: PropTypes.object,
    triggers: PropTypes.object,
};

export const WakeOnShakeForm = ({triggers, toggleFlag, onFormChange, isImpactVueEnabled}) => {
    const handleWosClick = () => {
        if (isImpactVueEnabled) {
            return;
        }

        toggleFlag(WOS_TRIGGER_TYPE, !triggers.toggles[WOS_TRIGGER_TYPE]);
    };

    return (
        <div className={"point-settings-form-container"}>
            <div className="trigger-label">
                <span
                    className={`alert-link cursor-pointer ${isImpactVueEnabled && "disabled mr-2"}`}
                    onClick={handleWosClick}
                >
                    <label className="switch">
                        <input
                            readOnly={true}
                            type="checkbox"
                            disabled={isImpactVueEnabled}
                            checked={triggers.toggles[WOS_TRIGGER_TYPE] && !isImpactVueEnabled ? "checked" : false}
                        />
                        <span className="slider round" />
                    </label>
                    <b className={"ml-2"}>Wake On Shake Settings</b>
                </span>
                {isImpactVueEnabled && (
                    <InfoTooltip>
                        This sensor doesn’t currently support having both Wake-On-Shake and ImpactVue enabled simultaneously. We are actively working on a
                        firmware update to support this in the future.
                    </InfoTooltip>
                )}
                <hr />
            </div>

            <div className="row align-items-center mb-3">
                <label className="text-right col-5 mt-1">
                    <span
                        className="mr-2"
                        id="setting-td-info"
                    >
                        <i className="fa fa-info-circle"></i>
                    </span>
                    <UncontrolledTooltip
                        placement="top"
                        target="setting-td-info"
                    >
                        <p className="tooltip-text">
                            This setting determines the time between the detection of the trigger and when the data acquisition begins.
                        </p>
                    </UncontrolledTooltip>
                    <b>Trigger Delay</b>
                </label>
                <div className="pr-1 col-7 input-group ">
                    <input
                        disabled={!triggers.toggles[WOS_TRIGGER_TYPE]}
                        className={"form-control"}
                        type={"number"}
                        min={0}
                        max={1000}
                        onChange={(e) => onFormChange(e)}
                        name={WOS_TRIGGER_TYPE + ".delay"}
                        value={+_get(triggers.form, [WOS_TRIGGER_TYPE, "delay"], 0)}
                    />
                    <div className="input-group-append">
                        <span className="input-group-text">ms</span>
                    </div>
                </div>
            </div>

            <div className="row align-items-center mb-3">
                <label className="text-right col-5 mt-1">
                    <span
                        className="mr-2"
                        id="setting-ta-info"
                    >
                        <i className="fa fa-info-circle"></i>
                    </span>
                    <UncontrolledTooltip
                        placement="top"
                        target="setting-ta-info"
                    >
                        <p className="tooltip-text">
                            This setting sets the threshold at which the relative change in acceleration between two consecutive readings constitutes a
                            trigger event.
                        </p>
                    </UncontrolledTooltip>
                    <b>Threshold Amplitude (pk-pk)</b>
                </label>
                <div className="pr-1 col-7">
                    <select
                        disabled={!triggers.toggles[WOS_TRIGGER_TYPE]}
                        className="form-control select-without-arrow"
                        onChange={(e) => onFormChange(e)}
                        value={+_get(triggers.form, [WOS_TRIGGER_TYPE, "threshold"], "0")}
                        name={WOS_TRIGGER_TYPE + ".threshold"}
                    >
                        {_map(amplitudesArray, (amplitude) => (
                            <option
                                key={amplitude.key}
                                value={amplitude.key}
                            >
                                {amplitude.value < 1 ? (amplitude.value * 1000).toFixed(2) + " mG" : amplitude.value + " G"}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="row align-items-center mb-3">
                <label className="text-right col-5 mt-1">
                    <span
                        className="mr-2"
                        id="setting-sf-info"
                    >
                        <i className="fa fa-info-circle"></i>
                    </span>
                    <UncontrolledTooltip
                        placement="top"
                        target="setting-sf-info"
                    >
                        <p className="tooltip-text">
                            This setting determines the frequency at which the vibration data is evaluated against the threshold amplitude. If the scan
                            frequency is set too low, the device may not detect the vibration event if it is short. If it is set too high, the device will
                            consume more power than necessary.
                        </p>
                    </UncontrolledTooltip>
                    <b>Scan Frequency</b>
                </label>
                <div className="pr-1 col-7">
                    <select
                        disabled={!triggers.toggles[WOS_TRIGGER_TYPE]}
                        className="form-control select-without-arrow"
                        onChange={(e) => onFormChange(e)}
                        value={+_get(triggers.form, [WOS_TRIGGER_TYPE, "data_rate"], "0")}
                        name={WOS_TRIGGER_TYPE + ".data_rate"}
                    >
                        {_map(frequenciesArray, (rate) => (
                            <option
                                key={rate.key}
                                value={rate.key}
                            >
                                {rate.value + " " + FREQUENCY_TYPES.HZ}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
};

WakeOnShakeForm.propTypes = {
    triggers: PropTypes.object,
    toggleFlag: PropTypes.func,
    onFormChange: PropTypes.func,
    isImpactVueEnabled: PropTypes.bool,
};

import React from "react";
import PropTypes from "prop-types";
import { RPM_FROM } from "../../../constants/constants";
import auth from "../../../services/auth";

const propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    equipment: PropTypes.object.isRequired,
    pointData: PropTypes.object,
    currentSpeed: PropTypes.number.isRequired,
    currentSpeedFrom: PropTypes.string.isRequired,
};

const SpeedNotSetLabel = ({
    className,
    onClick,
    equipment,
    pointData,
    currentSpeed,
    currentSpeedFrom,
}) => {
    let label = "";
    if (currentSpeed === 0 && currentSpeedFrom === RPM_FROM.TACHOMETER) {
        label = "Nearest Tachometer Value Is 0";
    } else if (
        pointData &&
        (pointData.speed_ratio === 0 || pointData.speed_ratio === null) &&
        equipment.tachometer !== null
    ) {
        label = "Installation Point Ratio Is Not Set";
    } else if (currentSpeed === 0 || currentSpeed === null) {
        label = "Running Speed Is Not Set";
    }

    const click = () => {
        if (auth.userCan("editSelfBearings")) {
            onClick();
        }
    };

    return label.length ? (
        <div className={`badge badge-warning cursor-pointer ${className}`} onClick={click}>
            {label}
        </div>
    ) : false;
};

SpeedNotSetLabel.propTypes = propTypes;

export default SpeedNotSetLabel;

import $ from "jquery";
import React, {Component} from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Loader from "../../../../../shared/loader/loader";
import ApiReading from "../../../../../api/reading";
import Helper from "../../../../../helpers/helper";
import ChartHelper from "../../../../../helpers/chart";
import {find as _find, isEqual as _isEqual, orderBy as _orderBy, cloneDeep as _cloneDeep, get as _get} from "lodash";
import FrequencyConverter from "../../../../../helpers/frequency-converter";
import {withFftReading} from "../hoc/withFftReading";
import {FREQUENCY_TYPES} from "../../../../../constants/constants";

const isDarkTheme = localStorage.getItem("lightMode");
const darkThemeLines = {"#d9534f": "#AD3835", "#f0ad4e": "#D38F2E"};

const initialOptions = {
    chart: {
        animation: false,
        zoomType: false,
        wrapZoomType: false,
        backgroundColor:
            isDarkTheme === "true"
                ? {
                      linearGradient: {x1: 0, y1: 0, x2: 1, y2: 1},
                      stops: [
                          [0, "#3a3934"],
                          [1, "#36342a"],
                      ],
                  }
                : "#FFFFFF",
        height: 450,
    },
    credits: {
        enabled: false,
    },
    plotOptions: {
        line: {
            lineWidth: 1,
            states: {
                hover: {
                    lineWidth: 1,
                },
            },
        },
        series: {
            animation: false,
            connectNulls: false,
        },
    },
    title: {
        text: "",
    },
    legend: {
        enabled: false,
    },
    xAxis: {
        type: "datetime",
    },
    yAxis: {
        opposite: false,
        title: {
            align: "middle",
            text: "",
        },
        ...(isDarkTheme === "true" ? {gridLineColor: "rgba(200,200,200,0.4)", gridLineWidth: 0.5} : {}),
    },
    rangeSelector: {
        enabled: false,
    },
    tooltip: {
        enabled: false,
        ...(isDarkTheme === "true" ? {backgroundColor: "rgba(54, 54, 50, 0.9)", style: {color: "#ddd"}} : {}),
    },
    series: [],
    navigation: {
        buttonOptions: {
            enabled: false,
        },
    },
};

class AlertChart extends Component {
    constructor(props) {
        super(props);

        this.chartRef = React.createRef();

        this.abortController = null;

        this.state = {
            averageFft: {},
            options: initialOptions,
            showCurrentSerial: true,
            showAveSerial: false,
            loader: true,
        };

        this.setReadings = this.setReadings.bind(this);
        this.draggablePlotLine = this.draggablePlotLine.bind(this);
    }

    componentDidMount() {
        this.setReadings();
        this.drawBandPlotLines();
    }

    componentDidUpdate(prevProps) {
        const {alert, isBand, chartDrawFlag} = this.props;

        if (
            (isBand
                ? alert.id !== prevProps.alert.id ||
                  alert.axisId !== prevProps.alert.axisId ||
                  alert.fftAlertType !== prevProps.alert.fftAlertType ||
                  alert.installationPointId !== prevProps.alert.installationPointId
                : !_isEqual(alert, prevProps.alert)) ||
            chartDrawFlag !== prevProps.chartDrawFlag
        ) {
            this.setReadings();
        }
        if (
            isBand &&
            (alert.alert_level_id !== prevProps.alert.alert_level_id ||
                alert.fftBandFrequencyType !== prevProps.alert.fftBandFrequencyType ||
                alert.fftBandFrom !== prevProps.alert.fftBandFrom ||
                alert.fftBandTo !== prevProps.alert.fftBandTo ||
                alert.value !== prevProps.alert.value ||
                alert.index !== prevProps.alert.index)
        ) {
            this.drawBandPlotLines();
        }
    }

    setReadings() {
        this.currentReadings.then((envelopReading = []) => this.drawSeries(envelopReading)).catch((err) => console.log(err));
    }

    // eslint-disable-next-line getter-return
    get currentReadings() {
        const {alert, isEnvelope, updateStdDeviations, update} = this.props;
        const {averageFft} = this.state;

        if (isEnvelope && alert.followingType) {
            if (+alert.followingType === 1) {
                return Promise.resolve(this.props.chartSeries[0]?.data);
            }
            if (this.avgReadingsShouldUpdate()) {
                update({fftEnvelopeData: []}, true);
                return this.loadAverageReadings();
            } else {
                updateStdDeviations(!!averageFft.std.length);
                if (+alert.fftEnvelopeCalcType === 2) {
                    return Promise.resolve(averageFft["max"]);
                }
                return Promise.resolve(averageFft["data"]);
            }
        } else {
            updateStdDeviations(false);
            return Promise.resolve([]);
        }
    }

    avgReadingsShouldUpdate() {
        const {alert} = this.props;
        const {averageFft} = this.state;
        return (
            +averageFft.axisId !== +alert.axisId ||
            +averageFft.followingType !== +alert.followingType ||
            (+alert.followingType === 2 && !!alert.avgLastRCount && +averageFft.avgLastRCount !== +alert.avgLastRCount) ||
            (+alert.followingType === 3 && !!alert.avgLastDCount && +averageFft.avgLastDCount !== +alert.avgLastDCount) ||
            (+alert.followingType === 4 &&
                ((!!alert.avgDateFrom && averageFft.avgDateFrom !== alert.avgDateFrom) || (!!alert.avgDateTo && averageFft.avgDateTo !== alert.avgDateTo)))
        );
    }

    loadAverageReadings() {
        const {alert, updateStdDeviations} = this.props;

        if (this.abortController) this.abortController.abort();
        this.abortController = new window.AbortController();

        this.setState({averageFft: {}, loader: true});

        const options = {
            axisId: alert.axisId,
            chartType: alert.chartType,
            fftEnvelopeCalcType: alert.fftEnvelopeCalcType,
            followingType: alert.followingType,
            ...(+alert.followingType === 2 ? {avgLastRCount: alert.avgLastRCount} : {}),
            ...(+alert.followingType === 3 ? {avgLastDCount: alert.avgLastDCount} : {}),
            ...(+alert.followingType === 4 ? {avgDateFrom: alert.avgDateFrom, avgDateTo: alert.avgDateTo} : {}),
        };

        return ApiReading.getChartFFtAverageData(alert.installationPointId, alert.followingType, options, this.abortController.signal).then(
            ({response}) => {
                if (response) {
                    this.setState(
                        {
                            averageFft: {
                                ...options,
                                data: response.average || [],
                                max: response.max || [],
                                std: response.std || [],
                            },
                            loader: true,
                        },
                        () => {
                            updateStdDeviations(!!this.state.averageFft.std.length);
                        }
                    );
                    return (+alert.fftEnvelopeCalcType === 1 ? response.average : response.max) || [];
                } else {
                    throw Error("abort");
                }
            }
        );
    }

    drawSeries(envelopReading = []) {
        return this.props.isBand ? this.drawBandSeries() : this.drawEnvelopeSeries(envelopReading);
    }

    drawEnvelopeSeries(envelopReading) {
        const options = _cloneDeep(this.state.options);
        const {alert, update} = this.props;
        const {showCurrentSerial, showAveSerial, averageFft} = this.state;

        const color = alert.alertLevel.color;

        const alertSeries = this.calculateEnvelope(_cloneDeep(envelopReading));
        update({fftEnvelopeData: alertSeries}, true);

        options.series = [];
        options.xAxis.type = "line";
        options.xAxis.min = null;
        options.xAxis.max = null;
        options.yAxis.plotLines = [];

        if (showAveSerial) {
            options.series.push({
                id: "aveReading",
                name: "aveReading",
                color: "#0000FF",
                data: +alert.fftEnvelopeCalcType === 2 ? _cloneDeep(averageFft["max"]) : _cloneDeep(averageFft["data"]),
                dataGrouping: {
                    approximation: "high",
                    forced: true,
                },
                zIndex: 0,
            });
        }

        if (showCurrentSerial) {
            options.series.push({..._get(this.props.chartSeries, "0", {}), ...{zIndex: 2}});
        }

        options.series.push({
            id: "alertSeries",
            name: "",
            color: new Highcharts.Color(color).setOpacity(1).get(),
            data: alertSeries,
            dataGrouping: {
                approximation: "high",
                forced: true,
            },
            zIndex: 3,
        });

        if ((alert.existingEnvelopeData || []).length) {
            options.series.push({
                id: "existing",
                name: "existing",
                color: new Highcharts.Color(color).setOpacity(0.5).get(),
                data: alert.existingEnvelopeData,
                dashStyle: "longdash",
                dataGrouping: {
                    approximation: "high",
                    forced: true,
                },
            });
        }

        this.setState({options, loader: false});
    }

    drawBandSeries() {
        const options = {...this.state.options};

        options.series = [...this.props.chartSeries];

        this.setState({options, loader: false});
        this.drawBandPlotLines(options);
    }

    drawBandPlotLines(options) {
        options = options || {...this.state.options};
        const {alert, equipment, getPointById, isBand} = this.props;

        if (isBand) {
            const color = alert?.alertLevel?.color;
            let plotLines = [];
            const rpmSpeed = ChartHelper.getPointSpeed(getPointById(alert.installationPointId), equipment) || {};

            const currentSpeed = options.series[0]?.speed || rpmSpeed.value || 0;

            const currentFrequency =
                currentSpeed === 0 && alert.fftBandFrequencyType === FREQUENCY_TYPES.ORDERS ? FREQUENCY_TYPES.HZ : alert.fftBandFrequencyType;

            options.xAxis.currentSpeed = currentSpeed;
            options.xAxis.currentFrequency = currentFrequency;
            options.xAxis.labels = {formatter: ChartHelper.formatXAxisFFT};

            options.xAxis.type = "line";
            options.yAxis.softMin = parseFloat(alert.value);
            options.yAxis.softMax = parseFloat(alert.value);

            options.xAxis.min = parseFloat(
                FrequencyConverter.fromType(options.xAxis.currentFrequency, +alert.fftBandFrom, options.xAxis.currentSpeed).toHz().numberFormat()
            );
            options.xAxis.max = parseFloat(
                FrequencyConverter.fromType(options.xAxis.currentFrequency, +alert.fftBandTo, options.xAxis.currentSpeed).toHz().numberFormat()
            );

            if (alert.value !== undefined) {
                plotLines.push({
                    id: alert.alert_level_id,
                    className: "cursor-move",
                    value: parseFloat(alert.value),
                    color: isDarkTheme === "true" ? darkThemeLines[color] || color : color,
                    width: 2,
                    zIndex: 9,
                    events: {
                        mousedown: this.draggablePlotLine,
                    },
                });
            }

            options.yAxis.plotLines = plotLines;

            this.setState({options, loader: false});
        }
    }

    draggablePlotLine(ev) {
        const {alert, updateValue} = this.props;
        const axis = this.chartRef.current.chart.yAxis[0];
        const plotLine = _find(axis.plotLinesAndBands, {id: alert.alert_level_id});
        let yOffset, clickY, value;

        const start = (ev) => {
            $(document).bind({
                "mousemove.line": step,
                "mouseup.line": stop,
                "click.line": stop,
            });
            clickY = ev.pageY - plotLine.svgElem.translateY;
        };

        const step = (ev) => {
            if (value === axis.max || value === axis.min) return stop();
            yOffset = ev.pageY - clickY;
            value = Math.max(axis.min, Math.min(axis.max, axis.toValue(yOffset) - axis.toValue(0) + plotLine.options.value));
            yOffset = axis.toPixels(value + axis.toValue(0) - plotLine.options.value);
            plotLine.svgElem.translate(0, yOffset);
            updateValue(Helper.numberFormat(value, 4));
        };

        const stop = () => {
            $(document).unbind(".line");
            updateValue(Helper.numberFormat(value, 4), true);
        };

        if (typeof plotLine.svgElem.translateY === "undefined") {
            plotLine.svgElem.translate(0, 0);
        }

        start(ev);
    }

    calculateEnvelope(readings = []) {
        const {alert} = this.props;
        const averageFft = _cloneDeep(this.state.averageFft);

        let result = [];
        let maxHz = 0;
        readings.forEach((val, key) => {
            if (val) {
                if (+alert.fftEnvelopeType === 1) result.push([val[0], val[1] * (1 + alert.fftEnvelopePercent / 100)]);
                else if (+alert.fftEnvelopeType === 2) result.push([val[0], val[1] + alert.fftEnvelopeStd * averageFft.std[key][1]]);
                else result.push([val[0], val[1]]);

                if (maxHz < val[0]) maxHz = val[0];
            }
        });

        if (+alert.fftEnvelopeSpectralWindow === 1 && +alert.fftEnvelopeSpectralWindowWidth > 0) {
            result = this.filterEnvelopePeaks(this.findAllPeaks(result), alert.fftEnvelopeSpectralWindowWidth, maxHz);
        }

        if (+alert.fftEnvelopeMinimum === 1) {
            result = result.map((val) => [val[0], Math.max(val[1], alert.fftEnvelopeMinimumValue)]);
        }

        return _orderBy(result, ["0"], ["asc"]);
    }

    findAllPeaks(data) {
        const peaks = [];

        data.forEach((val, key) => {
            if (key === 0 || key === data.length - 1) return true;
            const cur_y_val = val[1];
            const prev_y_val = data[key - 1][1];
            const next_y_val = data[key + 1][1];
            if (cur_y_val >= prev_y_val && cur_y_val >= next_y_val) peaks.push(val);
        });

        return peaks;
    }

    filterEnvelopePeaks(peaks, hz, maxHz) {
        hz = +hz;
        const sortedArr = peaks.sort((a, b) => b[1] - a[1]); // sort descending by value
        const xx = sortedArr.map((val) => val[0]);

        xx.forEach((x) => {
            if (!x) return true;
            const max_range = x + hz / 2;
            const min_range = x - hz / 2;
            sortedArr.forEach((vv, kk) => {
                if (vv) {
                    var cur_x_val = vv[0];
                    // if value is between current range (x_val - hz to x_val + hz) - remove it.
                    // also removing the value from xx array to skip it when it comes to iteration
                    if (cur_x_val !== x && cur_x_val >= min_range && cur_x_val <= max_range) {
                        xx[kk] = undefined;
                        sortedArr[kk] = undefined;
                    }
                }
            });
        });

        const coords = sortedArr.filter((val) => !!val).sort((a, b) => a[0] - b[0]);
        const res = [];

        coords.forEach((vv) => {
            if (vv) {
                let x1 = vv[0] - hz / 2;
                let x2 = vv[0] + hz / 2;
                let y = vv[1];
                if (x1 < 0) {
                    x1 = 0;
                }
                if (x2 > maxHz) {
                    x2 = maxHz;
                }
                res.push([x1, y]);
                res.push([x2, y]);
            }
        });

        if (res.length && res[0][0] > 0) res.unshift([0, res[0][1]]);

        //Make trapezoid shape for envelope series to avoid x cord duplication
        res.forEach((val, key) => {
            if (res[key + 1]) {
                if (res[key + 1][0] <= res[key][0]) {
                    if (res[key + 1][1] > res[key][1]) {
                        res[key][0] = res[key + 1][0] - 0.000001;
                    } else if (res[key + 1][1] < res[key][1]) {
                        res[key + 1][0] = res[key][0] + 0.000001;
                    }
                }
            }
        });

        return res;
    }

    setShowCurrentSerial = (value) => {
        this.setState({showCurrentSerial: value}, () => {
            this.setReadings();
        });
    };

    setShowAveSerial = (value) => {
        this.setState({showAveSerial: value}, () => {
            this.setReadings();
        });
    };

    getCurrentSerialTitle = () => {
        const serial = _get(this.props.chartSeries, "0", {}) || {};

        return " [ " + serial.readingDate + " ]";
    };

    render() {
        const {options, loader, showCurrentSerial, showAveSerial} = this.state;
        const {isBand, alert} = this.props;
        if (loader) return <Loader />;

        return (
            <>
                {!isBand && (
                    <div className={"pl-4 mb-4"}>
                        <span
                            className={"alert-link mr-0"}
                            onClick={() => this.setShowCurrentSerial(!showCurrentSerial)}
                        >
                            <label className="switch">
                                <input
                                    readOnly={true}
                                    type="checkbox"
                                    checked={showCurrentSerial ? "checked" : false}
                                />
                                <span className="slider round" />
                            </label>
                            Show Current Reading
                        </span>
                        <span className={"mr-4"}>{this.getCurrentSerialTitle()}</span>
                        {+alert.followingType !== 1 && (
                            <span
                                className={"alert-link "}
                                onClick={() => this.setShowAveSerial(!showAveSerial)}
                            >
                                <label className="switch">
                                    <input
                                        readOnly={true}
                                        type="checkbox"
                                        checked={showAveSerial ? "checked" : false}
                                    />
                                    <span className="slider round" />
                                </label>
                                Show {+alert.fftEnvelopeCalcType === 2 ? "Peaks" : "Average"} Reading
                            </span>
                        )}
                    </div>
                )}
                <HighchartsReact
                    ref={this.chartRef}
                    highcharts={Highcharts}
                    constructorType={"chart"}
                    options={options}
                />
            </>
        );
    }
}

AlertChart.propTypes = {
    alert: PropTypes.object,
    equipment: PropTypes.object,
    chartTypes: PropTypes.array,
    currentDate: PropTypes.string,
    isBand: PropTypes.bool,
    isEnvelope: PropTypes.bool,
    chartDrawFlag: PropTypes.bool,
    update: PropTypes.func,
    updateValue: PropTypes.func,
    updateStdDeviations: PropTypes.func,
    getTachData: PropTypes.func,
    getReadingsData: PropTypes.func,
    getPointById: PropTypes.func,
    chartSeries: PropTypes.array,
    rpmSpeed: PropTypes.object,
};

export default withFftReading(AlertChart);

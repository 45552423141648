import {useCurrentAlertCondition} from "../../../../stores/zustand/AlertConditionStore";
import {useGetFFTReadings, useGetPointReadingParamsByDateTime} from "../../../../hooks/api/fftReadings/ReadingsQuery";
import {usePointData} from "../../../../pages/chart/highcharts/hooks/usePointsData";
import {useSimpleChartReadings} from "../../../../hooks/api/simpleChartReadings/useSimpleChartReadings";
import {useChartComputed, useChartType} from "./chart";
import ChartHelper from "../../../../helpers/chart";
import {useInstallationPoint} from "../useInstallationPoint";
import {useEquipmentByParams} from "../../../../hooks/api/equipment/useEquipmentQuery";
import {useChartTypesStore} from "../../../../stores/zustand/ChartSelectedChartTypeStore";
import {useInterpolationSyncStoreByPoint} from "../../../../pages/chart/highcharts/store/sync/InterpolationSuncStore";
import {useHighPassFilterSyncStoreByPoint} from "../../../../pages/chart/highcharts/store/sync/HighPassFilterSyncStore";

export const useCurrentSpeed = () => {
    const chartTypes = useChartTypesStore();
    const chartType = useChartComputed(chartTypes);

    const alert = useCurrentAlertCondition();

    const {equipmentItem: equipment} = useEquipmentByParams();
    const pointData = usePointData(alert?.installationPointId);
    const installationPoint = useInstallationPoint(equipment, chartType);
    const isInterpolation = useInterpolationSyncStoreByPoint(installationPoint.id);
    const highpassFilter = useHighPassFilterSyncStoreByPoint(installationPoint.id, pointData);

    const {isChartFFT} = useChartType(chartTypes);

    const {params: readingsParams} = useGetPointReadingParamsByDateTime(
        pointData?.equipment_id,
        pointData?.id,
        alert?.axisId,
        {
            date: "last",
            time: "last",
        },
        false,
        highpassFilter,
        isInterpolation
    );

    const {data: readingsFFT} = useGetFFTReadings(alert?.chartType, readingsParams);

    const {data: readingsSimple} = useSimpleChartReadings({
        installPointsIds: [pointData?.id],
        axisIds: [alert?.axisId],
        chartType: alert.chartType,
    });

    return ChartHelper.getCurrentSpeed(isChartFFT ? readingsFFT[0] : readingsSimple[0], installationPoint, equipment);
};

import React, {useState} from "react";
import PropTypes from "prop-types";
import Modal from "../../modals/simple-modal";

export const FirmwareVersion = ({version, isFirmwareExists, description, shortDescription, name, type}) => {
    const [isShowModal, setIsShowModal] = useState(false);

    if (version && isFirmwareExists) {
        return (
            <>
                <span
                    onClick={() => setIsShowModal(!isShowModal)}
                    className="link-primary"
                >
                    {version}
                </span>
                <Modal
                    showModal={isShowModal}
                    onClose={() => setIsShowModal(!isShowModal)}
                    withoutSubmit
                    cancelTitle="Close"
                    title={name ? `Firmware update for ${name}` : "Firmware update"}
                    className="full-view-modal"
                >
                    <div>
                        <p className="mb-3">
                            {version} {type} Release
                        </p>
                        {shortDescription && <p className="mb-3">{shortDescription}</p>}
                        <p>{description}</p>
                    </div>
                </Modal>
            </>
        );
    }

    if (version && !isFirmwareExists) {
        return <span>{version}</span>;
    }

    return "N/A";
};

FirmwareVersion.propTypes = {
    version: PropTypes.string,
    description: PropTypes.string,
    shortDescription: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    isFirmwareExists: PropTypes.bool,
};

import React from "react";
import PropTypes from "prop-types";
import RmsOdTemplate from "./rms-od-template";
import EmailTemplate from "./email-template";
import AlertActions from "./alert-actions";
import TachometerRpmTemplate from "./tachometer-rpm-template";
import SelectWrapper from "../../../helpers/select-wrapper";

const TachometerRpmAlertTemplate = ({
    inProgress,
    isCurrent,
    isChanged,
    isProfile,
    alert,
    users,
    alias,
    level,
    units,
    conditions,
    versionType,
    update,
    remove,
    reset,
    save,
    setCurrent,
    specs,
    validErrorMsg
}) => {

    return (
        <div
            className={`form-group mb-3 alert-level-block-big alert-level-block${isCurrent ? " current" : ""}`}
            onClick={() => isCurrent ? {} : setCurrent(alert)}
        >
            <div className="row flex-xl-nowrap justify-content-end align-items-start">
                <div className={level.name === "Caution" ? "col-auto col-12 col-xl-5 mb-3 mb-xl-0" : "col col-12 col-xl-auto flex-grow-1 mb-3 mb-xl-0" }>
                    <label className={level.name === "Caution" ? "level-label caution-level" : "level-label warning-level" }>{level.name || ""}</label>
                </div>
                <div className={level.name === "Caution" ? "col-12 col-md-6 col-xl-2 mb-3 mb-md-0" : "col-12 col-md-3 col-xl-2 mb-3 mb-md-0" }>
                    <SelectWrapper
                        style={{
                            width:"100%",
                            display:"block",
                        }}
                        value={alert.condition || ""}
                        onChange={ev => update({condition: ev.target.value})}
                        disabled={!isCurrent}
                    >
                        {Object.keys(conditions || {}).map(key => <option key={key} value={key}>{key}</option>)}
                    </SelectWrapper>
                </div>
                <div className={level.name === "Caution" ? "col-12 col-md-6 col-xl-5" : "col-12 col-md-5 col-xl-5" }>
                    <div className={"input-group input-group-sm"}>
                        <input
                            className={`form-control request-form-control${isCurrent ? " current-alert-value" : ""} ${(alert.value === "" && validErrorMsg && isCurrent) ? "is-invalid" : ""}`}
                            type="number"
                            value={alert.value.toString() || ""}
                            step={0.1}
                            min={0}
                            onChange={ev => update({value: ev.target.value})}
                            disabled={!isCurrent}
                        />
                        {!!units &&
                            <div className="input-group-append">
                                <span className="input-group-text request-form-control">{units}</span>
                            </div>
                        }
                    </div>

                    {alert.value === "" && validErrorMsg && isCurrent &&
                        <span className="error-msg d-block color-danger mt-1 text-right">This field is required.</span>
                    }
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12 col-md-5 mb-1 mb-md-0">
                    <span className="trigger-type label-horizontal-input d-block">Trigger Type</span>
                </div>
                <div className="col-12 col-md-7">
                    <SelectWrapper
                        style={{
                            width:"100%",
                            display:"block",
                        }}
                        value={alert.notificationType || ""}
                        onChange={ev => update({notificationType: +ev.target.value})}
                        disabled={!isCurrent}
                    >
                        <option value={1}>Alert only when reading exceeds the last active alert</option>
                        <option value={2}>Alert for every reading</option>
                    </SelectWrapper>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-5">
                    <span>Consecutive Readings Required <br/> Before Trigger</span>
                </div>
                <div className="col-12 col-md-7">
                    <input
                        className="form-control request-form-control"
                        value={alert.requireReadings || ""}
                        onChange={ev => update({requireReadings: +ev.target.value, require_readings: +ev.target.value})}
                        disabled={!isCurrent}
                        type={"number"}
                        min={1}
                    />
                    {alert.requireReadings === "" && validErrorMsg && isCurrent &&
                    <span className="error-msg d-block color-danger mt-1 text-right">This field is required.</span>
                    }
                    {alert.requireReadings !== "" && (alert.requireReadings < 1 || +alert.requireReadings !== parseInt(""+alert.requireReadings)) && validErrorMsg && isCurrent &&
                    <span className="error-msg d-block color-danger mt-1 text-right">This field only accepts integers and cannot be less than 1.</span>
                    }
                </div>
            </div>
            <TachometerRpmTemplate
                alert={alert}
                isCurrent={isCurrent}
                update={update}
                validErrorMsg={validErrorMsg}
            />
            <RmsOdTemplate
                alert={alert}
                alias={alias}
                versionType={versionType}
                isCurrent={isCurrent}
                update={update}
                specs={specs}
            />
            {!isProfile &&
                <React.Fragment>
                    <EmailTemplate
                        isCurrent={isCurrent}
                        users={users}
                        alert={alert}
                        level={level}
                        update={update}
                    />
                </React.Fragment>
            }
            <div>
                <AlertActions
                    inProgress={inProgress}
                    isCurrent={isCurrent}
                    isChanged={isChanged}
                    isProfile={isProfile}
                    reset={reset}
                    save={save}
                    remove={remove}
                    alert={alert}
                />
            </div>
        </div>
    );
};

TachometerRpmAlertTemplate.propTypes = {
    inProgress: PropTypes.bool,
    isCurrent: PropTypes.bool,
    isChanged: PropTypes.bool,
    isProfile: PropTypes.bool,
    alias: PropTypes.string,
    units: PropTypes.string,
    users: PropTypes.array,
    alert: PropTypes.object,
    level: PropTypes.object,
    conditions: PropTypes.object,
    versionType: PropTypes.number,
    setCurrent: PropTypes.func,
    update: PropTypes.func,
    remove: PropTypes.func,
    reset: PropTypes.func,
    save: PropTypes.func,
    specs: PropTypes.object,
    validErrorMsg: PropTypes.bool,
    showValidationTachometerRpmFromError: PropTypes.bool,
    showValidationTachometerRpmToError: PropTypes.bool
};

export default TachometerRpmAlertTemplate;
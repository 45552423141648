import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {useFFTChartSeriesBuilder} from "../../../../../../hooks/factory/useFFTChartSeriesBuilder";
import {useIsImpactVue} from "../../../hooks/useChartTypes";
import {useSelectedAxis} from "../helpers/useSelectedAxis";
// eslint-disable-next-line no-unused-vars
import {useCurrentDate} from "../helpers/useCurrentDate";

export const useSeriesBuilder = () => {
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint);
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const isImpactVue = useIsImpactVue(chartType);
    const chartMode = useChartStateStoreContext((state) => state.settings.chartMode);
    const chartViewType = useChartStateStoreContext((state) => state.settings.chartViewType);

    const currentDate = useCurrentDate();
    const {chartModeList} = useChartStateStoreContext((state) => state.factory, shallow);
    const selectedAxis = useSelectedAxis();
    return useFFTChartSeriesBuilder({
        chartType,
        axis: selectedAxis,
        chartMode,
        currentDate,
        chartViewType,
        installationPointId: selectedPoint,
        chartModeList,
        isImpactVue,
    });
};

import $ from "jquery";
import React, { Component } from "react";
import {Loader, Modal} from "../../../shared";
import PropTypes from "prop-types";
import {
    get as _get,
} from "lodash";

import "../../../assets/scss/components/guide/guide.scss";
import {
    EQUIPMENT_TYPE_GATEWAY,
    EQUIPMENT_TYPE_NODE,
    EQUIPMENT_TYPE_REPEATER,
    EQUIPMENT_TYPE_SENSOR_MOTE,
    EQUIPMENT_TYPE_TACHOMETER,
    EQUIPMENT_TYPE_TETHERED_SENSOR
} from "../../../constants/constants";
import UserGuideApi from "../../../api/userGuide";

class HelpInstallationGuide extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loader: true,
            productGuides: [],
        };

        this.dropdownMenu = this.dropdownMenu.bind(this);
    }

    dropdownMenu(ulId) {
        $("#" + ulId).stop().slideToggle(200);
    }

    componentDidMount() {
        UserGuideApi
            .getProductGuides()
            .then(response => {
                this.setState({
                    loader: false,
                    productGuides: _get(response, "list", [])
                });
            });
    }

    render() {
        const {loader, productGuides} = this.state;

        return (
            <Modal
                {...this.props}
                size="m"
                title={"Product Guides"}
                withoutFooter={true}
                className="user_guide-modal"
            >
                {loader ? <Loader/>
                    :
                    <ul className="user_guide-list">
                        <li>
                            <p>
                                The product guides below provide information on how to install and maintain the Waites hardware.
                            </p>

                            <table className={"table text-center"}>
                                {productGuides.length
                                    ? productGuides.map((guide) => <GuideRaw key={_get(guide, "id")} item={guide} />)
                                    : <tr>
                                        <td><b>The list of guides is empty.</b></td>
                                    </tr>
                                }
                            </table>
                        </li>
                    </ul>
                }
            </Modal>
        );
    }
}

HelpInstallationGuide.propTypes = {
    showModal: PropTypes.bool,
    onClose: PropTypes.func
};

const GuideRaw = ({item}) => {

    const equipmentTypes = {
        [EQUIPMENT_TYPE_GATEWAY]: "Gateway User Guide",
        [EQUIPMENT_TYPE_NODE]: "Node User Guide",
        [EQUIPMENT_TYPE_REPEATER]: "Repeater User Guide",
        [EQUIPMENT_TYPE_SENSOR_MOTE]: "Sensor Mote User Guide",
        [EQUIPMENT_TYPE_TACHOMETER]: "Tachometer User Guide",
        [EQUIPMENT_TYPE_TETHERED_SENSOR]: "Tethered Sensor User Guide"
    };

    return <tr key={_get(item, "id")}>
        <td><b>{_get(equipmentTypes, _get(item, "equipment_type"), "Unknown")}</b></td>
        <td>
            <div
                className="mt-2 mb-2"
            >
                <a
                    className={"btn btn-primary btn-sm"}
                    href={_get(item, "url")}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Click here to open
                </a>
            </div>
        </td>
    </tr>;
};

GuideRaw.propTypes = {
    item: PropTypes.object,
};

export default HelpInstallationGuide;
import {useQuery} from "@tanstack/react-query";
import moment from "moment";
import Helper from "../../../helpers/helper";
import {FFT_TIMESTAMPS_API} from "./constants";
import ApiReading from "../../../api/reading";
import {get as _get} from "lodash";
import {MTIME_SIX_MONTHS, MTIME_TWO_YEARS} from "../../../stores/zustand/ChartSelectedRangeStore";
import auth from "../../../services/auth";

const IMPACT_VUE_KEY = "fftTimestampsImpactVue";
const REGULAR = "fftTimestamps";

const parseApiResult = (timestamps) => {
    if (Array.isArray(timestamps)) {
        timestamps = {};
    }

    return {
        [REGULAR]: !timestamps.timestamps || Array.isArray(timestamps.timestamps) ? {} : timestamps.timestamps,
        [IMPACT_VUE_KEY]: !timestamps.timestampsHFDvue || Array.isArray(timestamps.timestampsHFDvue) ? {} : timestamps.timestampsHFDvue,
    };
};

const getTimestampsKey = (isImpactVue = false) => (isImpactVue ? IMPACT_VUE_KEY : REGULAR);

export const pointSelector = (data, isImpactVue, pointID) => {
    const timestampsKey = getTimestampsKey(isImpactVue);
    return _get(data, [timestampsKey, pointID], {});
};

export const axisSelector = (data, isImpactVue, pointID, axisID) => {
    if (+axisID === 0) {
        const timestampsKey = getTimestampsKey(isImpactVue);
        return _get(data, [timestampsKey, pointID], {});
    }
    const timestampsKey = getTimestampsKey(isImpactVue);
    return _get(data, [timestampsKey, pointID, axisID], {});
};

export const dateListByPointSelector = (data, isImpactVue, pointID, axes) => {
    const timestampsKey = getTimestampsKey(isImpactVue);
    return Object.keys(_get(data, [timestampsKey, pointID, axes.at(0)], []));
};

export const timeListByPointDateSelector = (data, isImpactVue, pointID, date, axes) => {
    const timestampsKey = getTimestampsKey(isImpactVue);
    const dateIndex = date === "last" ? Object.keys(_get(data, [timestampsKey, pointID, axes.at(0)], [])).at(-1) : date;
    return dateIndex ? _get(data, [timestampsKey, +pointID, +axes.at(-1), dateIndex], []) : [];
};

export const timeObjectByPointDateSelector = (data, isImpactVue, pointID, dateObject, axes) => {
    const availableTimesList = timeListByPointDateSelector(data, isImpactVue, pointID, dateObject.date, axes);
    return _get(availableTimesList, [dateObject.time], {});
};

export const lastTenByAxis = (data, isImpactVue, pointID, axisID) => {
    const timestampsKey = getTimestampsKey(isImpactVue);
    const lastTen = [];

    //TODO rework - bad complexity with breaker point;
    const datesArr = Object.keys(_get(data, [timestampsKey, pointID, axisID], [])).reverse();

    for (let date of datesArr) {
        const timesArr = Object.keys(_get(data, [timestampsKey, pointID, axisID, date], [])).reverse();
        for (let time of timesArr) {
            lastTen.push({date: date, time: time});
            if (lastTen.length === 10) {
                return lastTen;
            }
        }
    }

    return lastTen;
};

export const useFFTTimestampsQuery = (equipmentID, selectorFun = undefined) => {
    //TODO provide algorithm to merge sliced parts by from/to params in same store

    const selectedRange =  +_get(auth, ["customer", "customer_id"]) === 313 ? MTIME_SIX_MONTHS : MTIME_TWO_YEARS;

    const {isSuccess, data, isLoading, ...rest} = useQuery(
        [FFT_TIMESTAMPS_API.FFT_TIMESTAMPS_EQUIPMENT, +equipmentID],
        () =>
            ApiReading.getFFTTimestampsList(+equipmentID, Helper.findCommence(selectedRange), moment().utc().unix()).then((timestamps) =>
                parseApiResult(timestamps)
            ),
        {
            select: selectorFun,
            enabled: !!equipmentID,
        }
    );
    return {isSuccess, data, isLoading, ...rest};
};

import OverlayModal from "../components/overlayModal";
import {useModal} from "react-modal-hook";
import React from "react";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";
import {useChartStateStoreContext} from "../../../pages/chart/highcharts/twf/store/hooks/useStoreContext";
import {useSelectedAxis} from "../../../pages/chart/highcharts/twf/hooks/helpers/useSelectedAxis";
import Toast from "../../../pages/shared/toast";
import {useIsImpactVue} from "../../../pages/chart/highcharts/hooks/useChartTypes";
import {useOverlayControls} from "../../../pages/chart/highcharts/twf/hooks/helpers/useOverlayControls";
import {useCurrentTimestampsInfo} from "../../../pages/chart/highcharts/twf/hooks/helpers/useCurrentTimestampsInfo";
import {useOverlay} from "../../../pages/chart/highcharts/twf/hooks/helpers/useOverlay";
export const useOverlayChartModal = () => {
    const {equipmentItem} = useEquipmentByParams();
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const selectedAxis = useSelectedAxis();
    const isImpactVue = useIsImpactVue(chartType);
    const {enableOverlay} = useOverlayControls();
    const chartTimestamp = useCurrentTimestampsInfo();
    const overlay = useOverlay();

    const [showModal, hideModal] = useModal(() => (
        <OverlayModal
            onClose={hideModal}
            equipment={equipmentItem}
            enableOverlay={enableOverlay}
            overlay={overlay}
            isImpactVue={isImpactVue}
            chartTimestamp={chartTimestamp}
        />
    ), [overlay]);

    const onShow = () => {
        if (selectedAxis?.length == 1) {
            return showModal();
        }
        Toast.info("Can't overlay multiple axes");
    };

    return [onShow, hideModal];
};

import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Modal, ValidationError} from "../../../shared";
import {
    get as _get,
    orderBy as _orderBy,
    mapValues as _mapValues,
    without as _without
} from "lodash";
import Helper from "../../../helpers/helper";
import NoteFile from "./file";
import CollapseLocationSelect from "../../../shared/collapseLocationSelect/collapseLocationSelect";
import SelectWrapper from "../../../helpers/select-wrapper";
import Note from "../../../api/note";
import Equipment from "../../../api/equipment";


const NoteModal = (props) => {

    const {usersList, isModal, formErrors, selectEquipment} = props;

    const [filteredEquipments, setFilteredEquipments] = useState(selectEquipment);
    const [showUsersList, setShowUsersList] = useState(false);
    const [filterEmailSearch, setFilterEmailSearch] = useState("");

    const [emailUserCount, setEmailUserCount] = useState(0);
    const [emailUserList, setEmailUserList] = useState([]);
    const [locationList, setLocationList] = useState([]);

    const [data, setData] = useState({
        equipment_id: "",
        locationId: "",
        text: "",
        emailUsers: [],
        file: "",
        fileName: ""
    });

    useEffect(() => {
        const equipment_id = Helper.getHashParams().equipment_id;

        if (equipment_id) {
            setNewEquipment(equipment_id);
        }
        installEmailUserList();
        setLocations();
    }, []);

    const handleSubmit = () => {
        props.onSubmit(data);
    };

    const setLocations = () => {
        return Note.noteCollectorLocations().then(response => {
            setLocationList(_get(response, "list.0.children", []));
        });
    };

    const changeLocation = (e) => {
        const value = _get(e, "target.value", "");

        if (value) {
            Equipment.equipmentIdsByLocation({locationId: value}).then(response => {
                const equipmentIds = _get(response, "list", []);
                setFilteredEquipments(selectEquipment.filter(item => {
                    return equipmentIds.includes(+item.value);
                }));
            });
        } else {
            setFilteredEquipments(selectEquipment);
        }

        setData(Object.assign(
            {},
            data,
            {
                locationId: +value,
                equipment_id: "",
            }
        ));
    };

    const changeEquipment = (e) => {
        setNewEquipment(_get(e, "target.value", ""));
    };

    const setNewEquipment = (value) => {
        const {selectEquipment, removeErrors} = props;
        const index = selectEquipment.findIndex(x => +x.value === +value);

        if (index > -1) {
            setData(Object.assign(
                {},
                data,
                {
                    locationId: selectEquipment[index].location_id,
                    equipment_id: selectEquipment[index].value,
                }
            ));

            removeErrors("equipment_id");
        }
    };


    const installEmailUserList = () => {
        let emailCount = 0;
        let emailList = [];

        (_orderBy(usersList, ["name_first", "name_last"]) || []).forEach(user => {
            if (user.email) {
                emailCount++;
                if ((`${user.name_first} ${user.name_last} (${user.email})`).toLowerCase().indexOf(filterEmailSearch) !== -1) {
                    emailList.push(
                        <label
                            key={user.id}
                            className={`form-checkbox modal-checkbox ${data.emailUsers.indexOf(user.id) === -1 ? "gray-text" : ""}`}
                        >
                            <input
                                type="checkbox"
                                checked={data.emailUsers.indexOf(user.id) !== -1}
                                onChange={() => onChangeEmailUsers(user.id)}
                            /> {`${user.name_first} ${user.name_last} (${user.email})`}<span/>
                        </label>
                    );
                }
            }
        });

        setEmailUserList(emailList);
        setEmailUserCount(emailCount);
    } ;

    const onChangeText = (event) => {
        const {removeErrors} =  props;

        setData(Object.assign(
            {},
            data,
            {
                text: event.target.value,
            }
        ));

        removeErrors("text");
    };

    const onChangeFilterEmailSearch = (filterEmailSearch) => {
        setFilterEmailSearch(filterEmailSearch);
        installEmailUserList();
    };

    const onChangeEmailUsers = (value) => {
        const selectedUsers = (data.emailUsers || []).slice(0);

        if (value === "*") {
            value = usersList.length === selectedUsers.length
                ? []
                : value = Object.values(_mapValues(usersList, "id"));
        } else {
            const index = selectedUsers.indexOf(value);

            if (index === -1) {
                selectedUsers.push(value);
                value = selectedUsers;
            } else {
                value = _without(selectedUsers, value);
            }
        }

        data.emailUsers = value;
        setData(data);

        installEmailUserList();
    };

    const onAttachFile = (fileObject) => {
        const {removeErrors} =  props;

        setData(Object.assign(
            {},
            data,
            fileObject
        ));

        removeErrors("file");
    };

    const onRemoveFile = () => {
        setData(Object.assign(
            {},
            data,
            {
                file: "",
                fileName: ""
            }
        ));
    };

    return (
        <Modal
            {...props}
            size="lg"
            className={"custom-modal"}
            title={"Add Note"}
            onSubmit={handleSubmit}
        >
            <div className="row">
                <div className="form-group col-5 mb-2">
                    <div className="form-group mb-2">
                        <label>Asset Tree Branch:</label>
                        <CollapseLocationSelect
                            className={"form-control note-modal-select"}
                            selectName={"location"}
                            value={+data.locationId}
                            onChange={(e) => {
                                changeLocation(e);
                            }}
                            emptyOptionLabel={"All"}
                            needMarkFacility={true}
                            disabled={isModal}
                            locationList={locationList}
                        />
                    </div>
                    {!!filteredEquipments.length &&
                        <div className="form-group mb-2">
                            <label>Equipment:<span className="color-danger">*</span></label>
                            <SelectWrapper
                                value={data.equipment_id}
                                className={
                                    (formErrors.equipment_id ? " is-invalid" : "")
                                }
                                style={{
                                    width:"100%",
                                    display:"block",
                                }}
                                disabled={isModal}
                                onChange={changeEquipment}
                                name={"equipment"}
                                placeholder={"Select Equipment"}
                            >
                                {filteredEquipments.map((item) => (
                                    <option key={item.value} value={item.value}>{item.name}</option>
                                ))}
                            </SelectWrapper>
                            <ValidationError message={formErrors.equipment_id}/>
                        </div>
                    }
                    <div className="form-group" style={{marginBottom: 0}}>
                        <div className="col-7 inline-form p-0">
                            {!!emailUserCount &&
                                <div>
                                    <label className="form-checkbox modal-checkbox">
                                        <input
                                            type="checkbox"
                                            checked={showUsersList === "1"}
                                            onChange={(e) => setShowUsersList(Helper.getInputValue(e.target))}
                                        /> Send Email Notifications<span/>
                                    </label>
                                </div>
                            }
                        </div>
                    </div>
                    {showUsersList === "1" &&
                        <div className="form-group custom-search-block my-2">
                            <i className="fa fa-search fa-2x"/>
                            <input
                                type="text"
                                value={filterEmailSearch}
                                onChange={e => onChangeFilterEmailSearch(e.target.value.toLowerCase())}
                                className={"form-control custom-search-input"}
                                placeholder={"Find email address"}
                            />
                        </div>
                    }

                    <div className="form-group row notes-emails-block">
                        {showUsersList === "1" &&
                            <div className="col-12">
                                <div className="form-checkbox-list">
                                    {!filterEmailSearch &&
                                        <label className="form-checkbox modal-checkbox">
                                            <input
                                                type="checkbox"
                                                checked={data.emailUsers.length === usersList.length}
                                                onChange={() => onChangeEmailUsers("*")}
                                            />All<span/>
                                        </label>
                                    }
                                    {emailUserList.length
                                        ? emailUserList
                                        : <span className="text-info">Not found</span>
                                    }
                                </div>
                            </div>
                        }
                    </div>

                </div>
                <div className="col-7">
                    <div className="form-group mb-2">
                        <label>Note:<span className="color-danger">*</span></label>
                        <textarea
                            rows={22}
                            className={"form-control"  + (formErrors.text ? " is-invalid" : "")}
                            value={data.text}
                            onChange={onChangeText}
                        />
                        <ValidationError message={formErrors.text}/>
                    </div>
                    <div>
                        <NoteFile
                            fileName={data.fileName}
                            onAttachFile={onAttachFile}
                            onRemoveFile={onRemoveFile}
                            errorMsg={formErrors.file}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

NoteModal.propTypes = {
    showModal: PropTypes.bool,
    selectEquipment: PropTypes.array,
    selectLocation: PropTypes.array,
    usersList: PropTypes.array,
    isModal: PropTypes.bool,
    onSubmit: PropTypes.func,
    formErrors: PropTypes.object,
    removeErrors: PropTypes.func
};

export default NoteModal;
import React from "react";
import {TABLE_FIELDS} from "../../constants/tableFields";
import {FirmwareItem} from "../FirmwareItem/FirmwareItem";
import {Loader} from "../../../../shared";
import PropTypes from "prop-types";

export const Table = ({onSortChange, isRefetching, isSuccess, firmwareList, sort}) => {
    return (
        <div className="table-scroll-wrapp">
            <table className="table table-hover tl-fixed global-notes-table">
                <thead>
                    <tr>
                        {TABLE_FIELDS.map((item, key) => (
                            <th
                                key={key}
                                onClick={() => onSortChange(item.field)}
                            >
                                {item.label}
                                {sort.field === item.field ? (
                                    <i className={"fa fa-sort" + (sort.sort === "asc" ? "-up" : "-down")} />
                                ) : (
                                    <i className="fa fa-sort" />
                                )}
                            </th>
                        ))}
                        <th />
                    </tr>
                </thead>
                {!isRefetching && isSuccess && (
                    <tbody>
                        {firmwareList.map((item) => (
                            <FirmwareItem
                                key={item.id}
                                firmware={item}
                            />
                        ))}
                    </tbody>
                )}
            </table>
            {isRefetching && <Loader />}
        </div>
    );
};

Table.propTypes = {
    onSortChange: PropTypes.func,
    isRefetching: PropTypes.bool,
    isSuccess: PropTypes.boo,
    firmwareList: PropTypes.array,
    sort: PropTypes.object,
};

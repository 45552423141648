import {create} from "zustand";
import {immer} from "zustand/middleware/immer";
import {persist} from "zustand/middleware";
import {shallow} from "zustand/shallow";
import {cloneDeep as _cloneDeep} from "lodash";
import ApiEquipment from "../../api/equipment";

const defaultChartOrderSettings = JSON.parse(localStorage.getItem("chartOrderSettings")) || null;
const store = (set, get) => ({
    chartOrderSettings: {
        list: defaultChartOrderSettings === null ? [] : defaultChartOrderSettings.list,
        checked: defaultChartOrderSettings === null ? ["1"] : defaultChartOrderSettings.checked,
    },
    chartTypes: {},
    adapterChartTypes: {},
    universalAdapters: [],
    actions: {
        setSelectedChartType: (list) => {
            set((state) => {
                state.chartTypes = list;
            });
        },
        setCheckedChartType: (checked) => {
            let checkedItems = _cloneDeep(get().chartOrderSettings.checked);

            if (checkedItems.includes(checked) === true) {
                checkedItems = checkedItems.filter((item) => item !== checked);
            } else {
                checkedItems.push(checked);
            }

            set((state) => {
                state.chartOrderSettings = {
                    ...state.chartOrderSettings,
                    checked: checkedItems,
                };
            });
        },
        setSelectedChartTypeSorted: (list) => {
            set((state) => {
                state.chartOrderSettings = {
                    ...state.chartOrderSettings,
                    list: list,
                };
            });
        },
        setAdapterChartTypes: (adapterChartTypes) => {
            set((state) => {
                state.adapterChartTypes = adapterChartTypes;
            });
        },
        updateCustomChartTypes: (equipmentId) => {
            let typesList = _cloneDeep(get().chartOrderSettings.list);
            ApiEquipment.universalAdaptersList(equipmentId).then((response) => {
                ApiEquipment.customChartTypes(equipmentId).then((customChartTypes) => {
                    typesList = {...typesList, ...customChartTypes};

                    set((state) => {
                        state.chartOrderSettings = {
                            ...state.chartOrderSettings,
                            list: Object.keys(typesList),
                        };

                        state.universalAdapters = response.list;
                    });
                });
            });
        },
    },
});

export const chartSelectedChartTypeStore = create(
    persist(immer(store), {
        name: "chartSelectedChartTypeStore",
        getStorage: () => localStorage,
        partialize: (state) => ({chartOrderSettings: state.chartOrderSettings}),
    })
);

export const useChartSelectedChartTypeStore = () => chartSelectedChartTypeStore((state) => state.chartOrderSettings, shallow);
export const useUniversalAdaptersStore = () => chartSelectedChartTypeStore((state) => state.universalAdapters, shallow);
export const useChartTypesStore = () =>
    chartSelectedChartTypeStore(
        (state) => ({
            ...state.chartTypes,
            ...state.adapterChartTypes,
        }),
        shallow
    );
export const useChartSelectedChartTypeActions = () => chartSelectedChartTypeStore((state) => state.actions, shallow);

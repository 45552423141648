import React, {useState} from "react";
import {Modal, ValidationError} from "../../../shared";
import FormValidator from "../../../helpers/form-validator";
import PropTypes from "prop-types";
import {get as _get, indexOf as _indexOf, includes as _includes, each as _each, cloneDeep as _cloneDeep} from "lodash";
import {UncontrolledTooltip} from "reactstrap";
import InstallationPointApi from "../../../api/installationPoint";
import Toast from "../../../pages/shared/toast";
import FrequencyConverter from "../../../helpers/frequency-converter";
import SweetAlert from "react-bootstrap-sweetalert";
import { FREQUENCY_TYPES } from "../../../constants/constants";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";
import {useEquipmentInvalidate} from "../../../hooks/api/equipment/useEquipmentInvalidate";
import {useReadingsInvalidate} from "../../../hooks/api/fftReadings/useReadingsInvalidate";

const rules = [
    {
        field: "speed",
        method: (val) => {
            return parseFloat(val) > 0;
        },
        validWhen: true,
        message: `Speed ${FREQUENCY_TYPES.CPM} should be a numeric value and more than 0.`,
    },
    {
        field: "speed_ratio",
        method: (val) => {
            return parseFloat(val) > 0;
        },
        validWhen: true,
        message: "Speed Ratio should be a numeric value and more than 0.",
    },
];

const TYPE_READING_ONLY = "reading";
const TYPE_SINGLE_POINT = "single";
const TYPE_ALL_POINTS = "all";

export const SetSpeedModal = props => {

    const {
        readingId,
        currentAxisTimestamps,
        pointData,
        speed,
        onClose,
        seriesList,
        currentSpeedFrom,
        currentSpeed,
        currentRatio,
        isCurrent,
        isImpactVue,
    } = props;

    const activeType = readingId ? TYPE_READING_ONLY : TYPE_SINGLE_POINT;
    const {equipmentItem} = useEquipmentByParams();
    const invalidateEquipment = useEquipmentInvalidate(equipmentItem.id);
    const invalidateReadings = useReadingsInvalidate();

    const getReadingIds = () => {
        let readingIds = [];
        _each(currentAxisTimestamps, (data) => {readingIds.push(data.readingId);});

        return readingIds;
    };

    const getSpeedRatio = (type) => {
        if (type === TYPE_READING_ONLY) {
            return currentRatio || pointData.speed_ratio || 1;
        }

        return pointData.speed_ratio || 1;
    };

    const getSpeedField = (type) => {

        if (isCurrent) {
            return speed ? FrequencyConverter.fromHz(speed).toCpm().numberFormat() : "";
        } else {
            if (type === TYPE_READING_ONLY) {
                return currentSpeed / (currentRatio || pointData.speed_ratio || 1) || pointData.speed || "";
            }

            if (type === TYPE_SINGLE_POINT) {
                return pointData.speed || equipmentItem.current_speed || "";
            }

            return equipmentItem.current_speed || "";
        }
    };

    const getResetSpeedAvailable = (type) => {
        let isAvailable;
        let title;

        switch (type) {
            case TYPE_READING_ONLY:
                isAvailable = currentSpeedFrom === "Reading" && +currentRatio > 0;
                title = !isAvailable && "You cannot reset the default running speed for this " + (equipmentItem.tachometer ? "tachometer" : "reading");
                break;
            case TYPE_SINGLE_POINT:
                isAvailable = !!pointData.speed;
                title = !isAvailable && "You cannot reset the default running speed for this installation point";
                break;
            case TYPE_ALL_POINTS:
                isAvailable = equipmentItem.installationPoints.filter((installationPoint) => !!installationPoint.speed === true).length > 0;
                title = !isAvailable && "You cannot reset the default running speed for this equipment";
                break;
        }

        return {
            disabled: !isAvailable,
            title: title || "Reset Speed",
        };
    };

    const readingIds = getReadingIds(currentAxisTimestamps);
    const validator = new FormValidator(rules);


    const [state, setState] = useState({
        inProgress: false,
        data: {
            speed: getSpeedField(activeType),
            speed_ratio: getSpeedRatio(activeType),
            resetSpeedAvailable: getResetSpeedAvailable(activeType),
            reading_id: readingId || false,
            type: activeType,
            readingIds: readingIds,
            allReadingIds: [...readingIds],
            isHFDvue: isImpactVue
        },
        validation: validator.valid(),
        clearSpeedConfirm: false,
    });

    const {data, inProgress, validation, clearSpeedConfirm} = state;
    const showAxis = readingId && data.type === TYPE_READING_ONLY && (currentAxisTimestamps.length > 1);
    const colWidth = data.type === TYPE_ALL_POINTS ? 6 : 4;

    const handleSubmit = () => {
        setState((prev) => ({
            ...prev,
            inProgress: true,
        }));

        if (validation.isValid) {
            InstallationPointApi.changeSpeed(pointData.id, data).then((response) => {
                if (response.status === "ok") {
                    Toast.success("The running speed has been successfully updated.", {
                        autoClose: 10000,
                        closeOnClick: true,
                        pauseOnHover: false,
                    });
                }

                const equipment = _cloneDeep(equipmentItem);
                equipment.installationPoints.map((installationPoint) => {
                    if (data.type === TYPE_SINGLE_POINT) {
                        if (+installationPoint.id === +pointData.id) {
                            installationPoint.speed = +data.speed;
                            installationPoint.speed_ratio = +data.speed_ratio;
                        }
                    } else if (data.type === TYPE_ALL_POINTS) {
                        installationPoint.speed = +data.speed;
                        installationPoint.speed_ratio = +data.speed_ratio;
                    }
                });
                invalidateEquipment();
                invalidateReadings(pointData.id);

                setState((prev) => ({
                    ...prev,
                    inProgress: false
                }));
                onClose(data.type, true);
            });
        } else {
            setState((prev) => ({
                ...prev,
                inProgress: false,
                validation: validation
            }));
        }
    };

    const handleChange = (e) => {
        const {data} = {...state};
        const targetName = _get(e, "target.name", "");
        const targetValue = _get(e, "target.value");
        data[targetName] = targetValue;

        if (targetName === "type") {
            data.speed = getSpeedField(targetValue);
            data.speed_ratio = getSpeedRatio(targetValue);
            data.resetSpeedAvailable = getResetSpeedAvailable(targetValue);
        }

        const validation = validator.validate(data, targetName);
        setState((prev) => ({
            ...prev,
            data: data,
            validation: validation
        }));
    };

    const handleReadingsCheckbox = (e) => {
        const {data} = {...state};
        const readingIds = _get(data, "readingIds", []);
        const readingId = _get(e, "target.value");

        const index = _indexOf(readingIds, readingId);
        if (index !== -1) {
            readingIds.splice(index, 1);
        } else {
            readingIds.push(readingId);
        }

        setState((prev) => ({
            ...prev,
            data: {...data, ...{readingIds}},
        }));
    };

    const handleClearSpeedConfirm = () => {
        setState((prev) => ({
            ...prev,
            clearSpeedConfirm: !state.clearSpeedConfirm
        }));
    };

    const handleClearSpeedRequest = () => {
        const {data} = state;
        const equipmentLocal = _cloneDeep(equipmentItem);

        InstallationPointApi.changeSpeed(pointData.id, {
            type: data.type,
            speed: null,
            speed_ratio: null,
            reading_id: data.reading_id,
            readingIds: data.readingIds,
            allReadingIds: data.allReadingIds,
            isHFDvue: data.isHFDvue
        }).then((response) => {
            if (response.status === "ok") {
                Toast.success("The running speed has been cleared.");
            }

            setState((prev) => ({
                ...prev,
                clearSpeedConfirm: false
            }));

            equipmentLocal.installationPoints.map((installationPoint) => {
                if (data.type === TYPE_SINGLE_POINT) {
                    if (+installationPoint.id === +pointData.id) {
                        installationPoint.speed = null;
                        installationPoint.speed_ratio = null;
                    }
                }

                if (data.type === TYPE_ALL_POINTS) {
                    installationPoint.speed = null;
                    installationPoint.speed_ratio = null;
                }
            });

            invalidateEquipment();
            invalidateReadings(pointData.id);
            onClose("manual");
        });
    };

    const getAxisName = (axisId) => {
        return _get(seriesList, "list." + axisId + ".axisName", "X") + " axis";
    };

        return (
            <Modal
                {...props}
                title="Running speed settings"
                size={"lg"}
                onClose={onClose}
                inProgress={inProgress}
                className={"custom-modal"}
                onSubmit={handleSubmit}
            >
                <div className="row">
                    <div className="form-group col-6 mb-2">
                        <label>Asset Code:</label>
                        <p className="mb-2">{equipmentItem.asset_code}</p>
                    </div>
                    <div className="form-group col-6 mb-2">
                        <label>Equipment:</label>
                        <p className="mb-2">{equipmentItem.name}</p>
                    </div>
                </div>
                <hr />
                <div className="row">
                    {data.type !== TYPE_ALL_POINTS && (
                        <div className={`form-group col-md-${colWidth}`}>
                            <label>Sensor</label>
                            <input
                                className={"form-control"}
                                type="text"
                                disabled={true}
                                value={pointData.name}
                            />
                        </div>
                    )}
                    <div className={`form-group col-md-${colWidth}`}>
                        <label>{FREQUENCY_TYPES.CPM} Value:</label>
                        <div className="input-group">
                            <input
                                className={"form-control" + (validation.speed.isValid || !validation.speed.message ? "" : " is-invalid")}
                                type="text"
                                value={data.speed}
                                name="speed"
                                onChange={handleChange}
                            />
                            <ValidationError message={validation.speed.message} />
                            <div className="input-group-append">
                                <button
                                    disabled={data.resetSpeedAvailable.disabled}
                                    className={`btn btn-icon btn-primary ${data.resetSpeedAvailable.disabled && "disabled"}`}
                                    title={data.resetSpeedAvailable.title}
                                    onClick={() => handleClearSpeedConfirm(false)}
                                >
                                    <i className="fa fa-trash" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={`form-group col-md-${colWidth} mb-4`}>
                        <label>Ratio Value:</label>
                        <input
                            className={"form-control" + (validation.speed_ratio.isValid || !validation.speed_ratio.message ? "" : " is-invalid")}
                            type="text"
                            value={data.speed_ratio}
                            name="speed_ratio"
                            onChange={handleChange}
                        />
                        <ValidationError message={validation.speed_ratio.message} />
                    </div>
                </div>
                <div className="row form-group">
                    <label className="col-1">Set For:</label>
                    <div className="axis-block-container col-11">
                        {readingId && (
                            <div
                                className={`axis-block ${data.type === TYPE_READING_ONLY && "active"}`}
                                onClick={() =>
                                    handleChange({
                                        target: {
                                            name: "type",
                                            value: TYPE_READING_ONLY,
                                            tagName: "",
                                        },
                                    })
                                }
                            >
                                <span>Current Reading Only</span>
                            </div>
                        )}
                        <div
                            className={`axis-block ${data.type === TYPE_SINGLE_POINT && "active"}`}
                            onClick={() =>
                                handleChange({
                                    target: {
                                        name: "type",
                                        value: TYPE_SINGLE_POINT,
                                        tagName: "",
                                    },
                                })
                            }
                        >
                            <span>Installation Point Only</span>
                        </div>
                        <div
                            className={`axis-block ${data.type === TYPE_ALL_POINTS && "active"}`}
                            onClick={() =>
                                handleChange({
                                    target: {
                                        name: "type",
                                        value: TYPE_ALL_POINTS,
                                        tagName: "",
                                    },
                                })
                            }
                        >
                            <span>All Installation Points On Equipment</span>
                        </div>
                    </div>
                </div>
                {showAxis && (
                    <div className="row form-group">
                        <label className="col-3">Will be applied to readings:</label>
                        <div className="axis-block-container col-9">
                            {!!currentAxisTimestamps?.length && (
                                <div>
                                    {currentAxisTimestamps.map((axisTimestamp, index) => (
                                        <div key={index}>
                                            <div>
                                                <label className={"form-checkbox mr-2" + (+readingId === +axisTimestamp.readingId ? " disabled" : "")}>
                                                    <input
                                                        checked={_includes(data.readingIds, axisTimestamp.readingId)}
                                                        type="checkbox"
                                                        value={axisTimestamp.readingId}
                                                        onChange={handleReadingsCheckbox}
                                                        disabled={+readingId === +axisTimestamp.readingId}
                                                    />
                                                    {getAxisName(axisTimestamp.axisId) + " - " + axisTimestamp.readingDate}
                                                    <span id={+readingId === +axisTimestamp.readingId ? `rpmv-info-${+axisTimestamp.readingId}` : ""} />
                                                    {+readingId === +axisTimestamp.readingId && (
                                                        <UncontrolledTooltip
                                                            placement="top"
                                                            target={`rpmv-info-${+axisTimestamp.readingId}`}
                                                        >
                                                            <p className="tooltip-text mb-0">
                                                                This axis is currently selected on the chart and cannot be changed.
                                                            </p>
                                                        </UncontrolledTooltip>
                                                    )}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {clearSpeedConfirm && (
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes"
                        cancelBtnBsStyle="default"
                        btnSize="xs"
                        title="DELETE SPEED"
                        onConfirm={handleClearSpeedRequest}
                        onCancel={handleClearSpeedConfirm}
                    >
                        {data.type === TYPE_ALL_POINTS &&
                            "Are you sure you want to clear the speed settings for all installation points that are attached to this equipment?"}
                        {data.type === TYPE_SINGLE_POINT && "Are you sure you want to clear the speed for this installation point?"}
                        {data.type === TYPE_READING_ONLY && "Are you sure you want to clear the speed for this reading?"}
                    </SweetAlert>
                )}
            </Modal>
        );

};

SetSpeedModal.propTypes = {
    showModal: PropTypes.bool,
    data: PropTypes.object,
    speed: PropTypes.number,
    currentSpeed: PropTypes.number,
    currentSpeedFrom: PropTypes.string,
    currentRatio: PropTypes.number,
    readingId: PropTypes.number,
    pointData: PropTypes.object,
    onClose: PropTypes.func,
    isCurrent: PropTypes.bool,
    drawChart: PropTypes.func,
    currentAxisTimestamps: PropTypes.array,
    seriesList: PropTypes.object,
    invalidateReadings: PropTypes.func,
    isImpactVue: PropTypes.bool
};

export default SetSpeedModal;

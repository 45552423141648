import React, {useState} from "react";
import PropTypes from "prop-types";
import {get as _get, includes as _includes} from "lodash";
import Helpers from "../../../helpers/helper";
import {WakeOnShakeView} from "../../../modals/trigger-settings/components/wake-on-shake";
import {BooleanView} from "../../../modals/trigger-settings/components/generic";
import SweetAlert from "react-bootstrap-sweetalert";
import InstallationPointSettingsApi from "../../../api/InstallationPointSettings";
import {FREQUENCY_TYPES} from "../../../constants/constants";
import ImpactVueText from "../../../shared/impactVue/ImpactVueText";
import {compare} from "compare-versions";
import InfoTooltip from "../../../shared/infoTooltip/infoTooltip";
import {WOS_TRIGGER_TYPE} from "../../../modals/trigger-settings/stores";

const ViewSettings = ({installationPoint, triggers, onEdit, readonly, withOverlay, viewColumnCount, updateSettings, hasWos}) => {
    const sensorId = _get(installationPoint, "sensor_id");
    const settings = _get(installationPoint, "settings", []);
    const fftAxes = _get(installationPoint, "fftAxes", false);
    const hasSensor = _get(installationPoint, "hasSensor");
    const hasNode = !!_get(installationPoint, "sensor.node_id");

    const supportHfdvue = _get(installationPoint, "sensorParameters.supportHfdvue", false);

    const booleanSupport = _includes(_get(triggers, "restriction.generic_sensor_types", []), +_get(installationPoint, "sensor.version_type", false));
    let wosSupport = _includes(_get(triggers, "restriction.wos_sensor_types", []), +_get(installationPoint, "sensor.version_type", false));
    if (wosSupport) {
        const version = _get(installationPoint, "sensor.version", "0.0.0");
        const wosSupportVersion = _get(triggers, "restriction.wos_sensor_version", "0.0.0");
        wosSupport = compare(version, wosSupportVersion, ">=");
    }

    const isAdapter = _get(installationPoint, "point_type") === "adapter";

    const impactVueEnabled = !!_get(installationPoint, "sensor.is_hfdvue", 0) && _get(installationPoint, "sensor.supportHfdvue", 0);

    return (
        <>
            <div className={"interval-block"}>
                {withOverlay && <div className={"overlay"} />}
                <div className={"settings-preview-list d-flex justify-content-between interval-content"}>
                    <ShortPointDescription
                        point={installationPoint}
                        sensorId={sensorId}
                        readonly={readonly}
                        updateSettings={updateSettings}
                        isWosEnabled={!!triggers?.toggles?.[WOS_TRIGGER_TYPE] || (!triggers && hasWos)}
                    />
                    {!!(hasSensor && hasNode) && (
                        <React.Fragment>
                            {booleanSupport && (
                                <BooleanView
                                    point={installationPoint}
                                    triggers={triggers}
                                />
                            )}
                            <ShortDescriptionIntervals
                                settings={settings}
                                isAdapter={isAdapter}
                                booleanSupport={booleanSupport}
                                impactVueEnabled={impactVueEnabled}
                            />
                            <ShortFftSettings
                                isAdapter={isAdapter}
                                booleanSupport={booleanSupport}
                                settings={settings}
                                fftAxes={fftAxes}
                                withHfdvue={impactVueEnabled}
                                supportHfdvue={supportHfdvue}
                            />
                            {wosSupport && (
                                <WakeOnShakeView
                                    point={installationPoint}
                                    triggers={triggers}
                                />
                            )}
                            {viewColumnCount === 4 && !wosSupport && !booleanSupport && <div className="settings-preview-item triggers-preview" />}
                        </React.Fragment>
                    )}
                    {!!(!hasSensor && sensorId) && <NoSensorReported />}
                    {!!(!hasSensor && !sensorId) && <NoSensorForFft />}
                    {!!(hasSensor && sensorId && !hasNode) && <NoNodeOnSensor />}
                    {!!hasSensor && !readonly && hasNode && (
                        <a
                            title="Set Intervals"
                            className="pull-right intervals-settings"
                            onClick={onEdit}
                        >
                            <i className="fa fa-pen" />
                        </a>
                    )}
                </div>
            </div>
        </>
    );
};

ViewSettings.propTypes = {
    auth: PropTypes.object,
    triggers: PropTypes.object,
    withHfdvue: PropTypes.bool,
    viewColumnCount: PropTypes.number,
    installationPoint: PropTypes.object.isRequired,
    onEdit: PropTypes.func,
    readonly: PropTypes.bool,
    withOverlay: PropTypes.bool,
    updateSettings: PropTypes.func,
    hasWos: PropTypes.bool,
};

const ShortPointDescription = ({point, sensorId, updateSettings, readonly, isWosEnabled}) => {
    const sensorHex = _get(point, "sensor.hex_id", sensorId);
    const versionName = _get(point, "sensor.version_name", "-");
    const impactvue = _get(point, "sensor.is_hfdvue", 0);
    const supportHfdvue = _get(point, "sensorParameters.supportHfdvue", false);

    const [impactVue, setImpactVue] = useState({
        showModal: false,
        value: +impactvue,
    });

    const handleImpactVueSwitch = () => {
        if (isWosEnabled) {
            return;
        }

        setImpactVue({...impactVue, showModal: true});
    };

    return (
        <div className={"settings-preview-item point-preview"}>
            <div className={"title"}>{_get(point, "name")}</div>
            {sensorId ? (
                <>
                    <div>
                        <span className={"sensor-id-title mr-1"}>Hex Code:</span>
                        {sensorHex}
                    </div>
                    <div>
                        <span className={"sensor-id-title mr-1"}>Type:</span>
                        {versionName}
                    </div>
                    {supportHfdvue && updateSettings && typeof updateSettings == "function" && (
                        <div>
                            <div className="d-flex">
                                <div
                                    className={`alert-link mr-2 ${isWosEnabled && "disabled"}`}
                                    onClick={handleImpactVueSwitch}
                                >
                                    <ImpactVueText />
                                    <label
                                        className={"switch"}
                                        style={{marginLeft: "5px"}}
                                    >
                                        <input
                                            readOnly={true}
                                            type="checkbox"
                                            checked={!!+impactVue.value}
                                            disabled={isWosEnabled}
                                        />
                                        <span className="slider round" />
                                    </label>
                                    {/*    HERE*/}
                                </div>
                                {isWosEnabled && (
                                    <InfoTooltip>
                                        This sensor doesn’t currently support having both Wake-On-Shake and ImpactVue enabled simultaneously. We are
                                        actively working on a firmware update to support this in the future.
                                    </InfoTooltip>
                                )}
                            </div>

                            <SweetAlert
                                show={!readonly && impactVue.showModal}
                                warning
                                showCancel
                                confirmBtnText="Yes"
                                cancelBtnBsStyle="default"
                                btnSize="xs"
                                title=""
                                onConfirm={() => {
                                    InstallationPointSettingsApi.updateHFDVue({
                                        impactVue: +!impactVue.value,
                                        installation_point_id: point.id,
                                    }).then((resp) => {
                                        if (resp && resp.status === "ok") {
                                            setImpactVue({showModal: false, value: +!impactVue.value, type: impactVue.type});
                                        }
                                        if (updateSettings && typeof updateSettings == "function") {
                                            updateSettings();
                                        }
                                    });
                                }}
                                onCancel={() => {
                                    setImpactVue({...impactVue, showModal: false});
                                    if (updateSettings && typeof updateSettings == "function") {
                                        updateSettings();
                                    }
                                }}
                            >
                                Are you sure you want to {+impactVue.value ? "disable" : "enable"} <ImpactVueText /> for {sensorHex}?
                            </SweetAlert>
                        </div>
                    )}
                </>
            ) : (
                <div>N/A</div>
            )}
        </div>
    );
};

ShortPointDescription.propTypes = {
    readonly: PropTypes.bool,
    point: PropTypes.object,
    updateSettings: PropTypes.func,
    sensorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isWosEnabled: PropTypes.bool,
};

const ShortDescriptionIntervals = ({settings, isAdapter, booleanSupport, impactVueEnabled}) => {
    if (booleanSupport) {
        return <></>;
    }

    if (isAdapter) {
        return (
            <div className="settings-preview-item intervals-preview">
                <div>
                    <b>WUA: </b>
                    {_get(settings, "wua_interval")} {Helpers.readingIntervalName(_get(settings, "wua_interval_type"), _get(settings, "wua_interval"))}
                </div>
            </div>
        );
    }
    return (
        <div className="settings-preview-item intervals-preview">
            <div>
                <b>Temperature: </b>
                {_get(settings, "temperature_interval")}{" "}
                {Helpers.readingIntervalName(_get(settings, "temperature_interval_type"), _get(settings, "temperature_interval"))}
            </div>
            <div>
                <b>RMS: </b>
                {_get(settings, "rms_interval")} {Helpers.readingIntervalName(_get(settings, "rms_interval_type"), _get(settings, "rms_interval"))}
            </div>
            <div>
                <b>FFT: </b>
                {_get(settings, "fft_interval")} {Helpers.readingIntervalName(_get(settings, "fft_interval_type"), _get(settings, "fft_interval"))}
            </div>
            {!!impactVueEnabled && (
                <div>
                    <b>
                        <ImpactVueText type={"rms"} />:{" "}
                    </b>
                    {_get(settings, "impactvue_rms_interval")}{" "}
                    {Helpers.readingIntervalName(_get(settings, "impactvue_rms_interval_type"), _get(settings, "impactvue_rms_interval"))}
                </div>
            )}
            {!!impactVueEnabled && (
                <div>
                    <b>
                        <ImpactVueText type={"fft"} />:{" "}
                    </b>
                    {_get(settings, "impactvue_fft_interval")}{" "}
                    {Helpers.readingIntervalName(_get(settings, "impactvue_fft_interval_type"), _get(settings, "impactvue_fft_interval"))}
                </div>
            )}
        </div>
    );
};

ShortDescriptionIntervals.propTypes = {
    settings: PropTypes.object,
    isAdapter: PropTypes.bool,
    booleanSupport: PropTypes.bool,
    impactVueEnabled: PropTypes.bool,
};

const ShortFftSettings = ({settings, fftAxes, withHfdvue, booleanSupport, supportHfdvue, isAdapter}) => {
    const fftSettings = _get(settings, "fft_settings", []);
    if (booleanSupport || isAdapter) {
        return <div className="settings-preview-item fft-preview" />;
    }

    return (
        <div className="settings-preview-item fft-preview">
            {fftAxes ? (
                Object.keys(fftSettings).map((axis_id) => (
                    <div
                        key={`axis_name-${axis_id}`}
                        className={"row"}
                    >
                        <div className={"col-3"}>{`${fftSettings[axis_id].axis_name.toUpperCase()}`}-Axis</div>
                        <div className={"col-3"}>
                            {fftSettings[axis_id].fmax} {FREQUENCY_TYPES.HZ}
                        </div>
                        <div className={"col-3"}>{fftSettings[axis_id].lines_of_resolution}</div>
                        <div className={"col-3"}>{+axis_id === 1 ? _get(settings, "high_pass") + " " + FREQUENCY_TYPES.HZ : ""}</div>
                    </div>
                ))
            ) : (
                <div className={"row"}>
                    <div className={"col-3"}>All-Axes</div>
                    <div className={"col-3"}>
                        {_get(fftSettings, "0.fmax")} {FREQUENCY_TYPES.HZ}
                    </div>
                    <div className={"col-3"}>{_get(fftSettings, "0.lines_of_resolution")}</div>
                    <div className={"col-3"}>
                        {_get(settings, "high_pass")} {FREQUENCY_TYPES.HZ}
                    </div>
                </div>
            )}
            {!!withHfdvue && supportHfdvue && (
                <div className={"row"}>
                    <div className={"col-3"}>
                        <ImpactVueText type={"fft"} />
                    </div>
                    <div className={"col-3"}>
                        {_get(fftSettings, "1.hfdvue_fmax")} {FREQUENCY_TYPES.HZ}
                    </div>
                    <div className={"col-3"}>{_get(fftSettings, "1.hfdvue_lines_of_resolution")}</div>
                </div>
            )}
        </div>
    );
};

ShortFftSettings.propTypes = {
    settings: PropTypes.object,
    fftAxes: PropTypes.bool,
    withHfdvue: PropTypes.bool,
    supportHfdvue: PropTypes.bool,
    isAdapter: PropTypes.bool,
    booleanSupport: PropTypes.bool,
};

const NoSensorForFft = () => {
    return (
        <div className="settings-preview-item no-data-preview alert alert-secondary text-center ml-2 my-0 py-2 px-2 flex-grow-1">
            <div className="alert-text">Sensor not installed</div>
        </div>
    );
};

const NoSensorReported = () => {
    return (
        <div className="settings-preview-item no-data-preview alert alert-secondary text-center ml-2 my-0 py-2 px-2 flex-grow-1">
            <div className="alert-text">This sensor has not reported any data yet.</div>
        </div>
    );
};

const NoNodeOnSensor = () => {
    return (
        <div className="settings-preview-item no-data-preview alert alert-secondary text-center ml-2 my-0 py-2 px-2 flex-grow-1">
            <div className="alert-text">This sensor has not been associated with a node.</div>
        </div>
    );
};

export {ViewSettings};

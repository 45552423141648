import React, {Component} from "react";
import PropTypes from "prop-types";
import Loader from "../../shared/loader/loader";
import InfiniteScroll from "react-infinite-scroller";
import Highlighter from "react-highlight-words";
import {
    filter as _filter,
    get as _get,
    find as _find
} from "lodash";
import {ViewSettings} from "../shared/intervals/ViewSettings";
import {withLocationSelectStore} from "../../stores/LocationSelectStore";

class BatchIntervalsTable extends Component {

    render() {
        const {search, list, hasMore, onNextPage, loader, auth, setSettingIntervalModal, updateSettings, location, getLocationById, setHighPassModalData} = this.props;

        const locationName = (getLocationById(location) || {}).name;

        return (
            <table className="table table-bordered sticky-wrapper default-levels-table">
                {search.length === 0 &&
                    <thead className="sticky-table-header">
                        <tr>
                            <th className="bg-gray-block font-weight-bold">
                                <div className="settings-preview-list d-flex justify-content-between interval-content">
                                    <div className="settings-preview-item point-preview">
                                        <div className={"title"}>{location ? "Location - " + locationName : "ALL EQUIPMENT"}</div>
                                    </div>
                                    <div className="settings-preview-item intervals-preview">
                                        <div className={"title"}>Intervals</div>
                                    </div>
                                    <div className="settings-preview-item fft-preview">
                                        <div className={"row"}>
                                            <div className={"col-3"}>
                                                <div className={"title"}>FFT</div>
                                            </div>
                                            <div className={"col-3"}>
                                                <div className={"title"}>FMax</div>
                                            </div>
                                            <div className={"col-3"}>
                                                <div className={"title"}>LoR</div>
                                            </div>
                                            <div className={"col-3"}>
                                                <div className={"title text-nowrap"}>
                                                    RMS High-Pass
                                                    {(auth.userCan("editEquipment") && (auth.customer.system_version === "self-service" || auth.userCan("manageFullServiceEquipments"))) &&
                                                        <a
                                                            title={
                                                                location
                                                                    ?
                                                                    "Set High-Pass Filter for location " + locationName
                                                                    :
                                                                    "Set High-Pass Filter for all equipment"
                                                            }
                                                            className="pull-right intervals-settings"
                                                            onClick={() => setHighPassModalData(
                                                                location
                                                                    ?
                                                                    {
                                                                        show: true,
                                                                        all: false,
                                                                        locationId: location,
                                                                        locationName: locationName,
                                                                        equipmentId: null,
                                                                    }
                                                                    :
                                                                    {
                                                                        show: true,
                                                                        all: true,
                                                                        locationId: null,
                                                                        equipmentId: null,
                                                                    }
                                                            )}
                                                        >
                                                            <i className="fa fa-pen"/>
                                                        </a>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a className="pull-right intervals-settings" style={{width: "13px"}}></a>
                                </div>
                            </th>
                        </tr>
                    </thead>
                }
                <InfiniteScroll
                    loadMore={() => {
                        if (list.length > 0) {
                            onNextPage();
                        }
                    }}
                    hasMore={!!hasMore}
                    loader={list.length > 0 ? <tr key="loader"><td colSpan="5" className="text-center"><Loader/></td></tr> : ""}
                    element="tbody"
                    initialLoad={false}
                >
                    {list.length > 0
                        ?
                        <React.Fragment>
                            {list.map((equipment, index) => {
                                const showAll = equipment.showAll === false
                                    && equipment.installationPoints.length !== _filter(equipment.installationPoints, installationPoint => installationPoint.foundBySearch).length
                                    && search.trim().length > 1;

                                const showBatchEdit = !!_filter(equipment.installationPoints || [], installationPoint => installationPoint.hasSensor).length
                                && !_find(equipment.installationPoints, installationPoint => installationPoint?.settings?.minInterval !== 900);

                                return (
                                    <React.Fragment key={index}>
                                        <tr className="bg-gray-block">
                                            <td className="font-weight-bold">
                                                <div className="settings-preview-list d-flex justify-content-between interval-content">
                                                    <div className="settings-preview-item point-preview">
                                                        <Highlighter
                                                            searchWords={[search.trim()]}
                                                            className={"title"}
                                                            autoEscape={true}
                                                            textToHighlight={equipment.name}
                                                        />
                                                    </div>
                                                    {
                                                        search.length > 0
                                                            ?
                                                            <>
                                                                <div className="settings-preview-item intervals-preview">
                                                                    <div className={"title"}>Intervals</div>
                                                                </div>
                                                                <div className="settings-preview-item fft-preview">
                                                                    <div className={"row"}>
                                                                        <div className={"col-3"}>
                                                                            <div className={"title"}>FFT</div>
                                                                        </div>
                                                                        <div className={"col-3"}>
                                                                            <div className={"title"}>FMax</div>
                                                                        </div>
                                                                        <div className={"col-3"}>
                                                                            <div className={"title"}>LoR</div>
                                                                        </div>
                                                                        <div className={"col-3"}>
                                                                            <div className={"title text-nowrap"}>
                                                                                RMS High-Pass
                                                                                {(auth.userCan("editEquipment") && (auth.customer.system_version === "self-service" || auth.userCan("manageFullServiceEquipments"))) &&
                                                                                    <a
                                                                                        title={"Set High-Pass Filter for " + equipment.name}
                                                                                        className="pull-left intervals-settings"
                                                                                        onClick={() => setHighPassModalData(
                                                                                            {
                                                                                                show: true,
                                                                                                all: false,
                                                                                                locationId: null,
                                                                                                equipmentId: equipment.id,
                                                                                                equipmentName: equipment.name,
                                                                                            }
                                                                                        )}
                                                                                    >
                                                                                        <i className="fa fa-pen"/>
                                                                                    </a>
                                                                                }
                                                                                {showBatchEdit && (auth.userCan("editEquipment") && (auth.customer.system_version === "self-service" || auth.userCan("manageFullServiceEquipments"))) &&
                                                                                    <a
                                                                                        title="Set Intervals"
                                                                                        className="pull-right intervals-settings"
                                                                                        onClick={() => setSettingIntervalModal({
                                                                                            show: true,
                                                                                            equipment: equipment,
                                                                                            installationPoint: null
                                                                                        })}
                                                                                    >
                                                                                        <i className="fa fa-pen"/>
                                                                                    </a>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div className="settings-preview-item intervals-preview" />
                                                                <div className="settings-preview-item fft-preview">
                                                                    <div className={"row"}>
                                                                        <div className={"col-9"} />
                                                                        <div className={"col-3"}>
                                                                            {(auth.userCan("editEquipment") && (auth.customer.system_version === "self-service" || auth.userCan("manageFullServiceEquipments"))) &&
                                                                                <a
                                                                                    title={"Set High-Pass Filter for " + equipment.name}
                                                                                    className="pull-left intervals-settings"
                                                                                    onClick={() => setHighPassModalData(
                                                                                        {
                                                                                            show: true,
                                                                                            all: false,
                                                                                            locationId: null,
                                                                                            equipmentId: equipment.id,
                                                                                            equipmentName: equipment.name
                                                                                        }
                                                                                    )}
                                                                                >
                                                                                    <i className="fa fa-pen"/>
                                                                                </a>
                                                                            }
                                                                            {showBatchEdit && (auth.userCan("editEquipment") && (auth.customer.system_version === "self-service" || auth.userCan("manageFullServiceEquipments"))) &&
                                                                                <a
                                                                                    title="Set Intervals"
                                                                                    className="pull-right intervals-settings"
                                                                                    onClick={() => setSettingIntervalModal({
                                                                                        show: true,
                                                                                        equipment: equipment,
                                                                                        installationPoint: null
                                                                                    })}
                                                                                >
                                                                                    <i className="fa fa-pen"/>
                                                                                </a>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>

                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                        {equipment.installationPoints.length > 0
                                            ?
                                            <React.Fragment>
                                                {equipment.installationPoints.map((installationPoint) =>
                                                    <React.Fragment key={installationPoint.id}>
                                                        <tr>
                                                            <ViewSettings
                                                                auth={auth}
                                                                installationPoint={installationPoint}
                                                                withHfdvue={+_get(installationPoint, "sensor.is_hfdvue", 0)}
                                                                onEdit={() => setSettingIntervalModal({
                                                                    show: true,
                                                                    equipment: {},
                                                                    installationPoint: installationPoint
                                                                })}
                                                                readonly={auth.equipmentViewOnly(equipment)}
                                                                updateSettings={updateSettings}
                                                                hasWos={installationPoint.hasWos}
                                                            />
                                                        </tr>
                                                    </React.Fragment>
                                                )}
                                                {showAll === true &&
                                                    <tr>
                                                        <td colSpan={5} className="text-center">
                                                            <button className="link link-primary" >
                                                                <b>Show All</b>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                }
                                            </React.Fragment>
                                            :
                                            <tr>
                                                <td colSpan={5} className="text-center">
                                                    Installation points not set for this Equipment
                                                </td>
                                            </tr>
                                        }
                                    </React.Fragment>
                                );
                            })}
                        </React.Fragment>
                        :
                        <React.Fragment>
                            {loader === false &&
                                <tr>
                                    <td colSpan={ 5 } className="text-center">
                                        {search?.length > 0 ? "No items match your search." : "No sensor intervals exist."}
                                    </td>
                                </tr>
                            }
                        </React.Fragment>
                    }

                </InfiniteScroll>
            </table>
        );
    }
}

BatchIntervalsTable.propTypes = {
    list: PropTypes.array,
    search: PropTypes.string,
    loader: PropTypes.bool,
    showAllSearchResults: PropTypes.func,
    onNextPage: PropTypes.func,
    hasMore: PropTypes.bool,
    auth: PropTypes.object,
    setSettingIntervalModal: PropTypes.func,
    updateSettings: PropTypes.func,
    location: PropTypes.string,
    setHighPassModalData: PropTypes.func,

    // from location store
    getLocationById: PropTypes.func,
};

export default withLocationSelectStore(BatchIntervalsTable);
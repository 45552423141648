import React, {useEffect, useState} from "react";
import DatePickerInput from "../../../../../../shared/datePickerInput/datePickerInput";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import Select from "react-select";
import {DATEPICKER_TYPE} from "../../../../../../constants/constants";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {
    dateListByPointSelector,
    lastTenByAxis,
    timeListByPointDateSelector,
    useFFTTimestampsQuery,
} from "../../../../../../hooks/api/fftReadings/TimestampsQuery";
import {usePointData} from "../../../hooks/usePointsData";
import {useIsImpactVue} from "../../../hooks/useChartTypes";
import {useSelectedAxis} from "../../hooks/helpers/useSelectedAxis";
import useUserProfile, {profileUserSelector} from "../../../../../../hooks/api/Global/useUserProfile";
import Helper from "../../../../../../helpers/helper";

const MAX_READINGS_COUNT = 10;
const isDarkTheme = localStorage.getItem("lightMode");

const DatePicker3d = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isTimeDropdownOpen, updateIsTimeDropdownOpen] = useState(false);

    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const isImpactVue = useIsImpactVue(chartType);
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint);
    const currentWaterfallDates = useChartStateStoreContext((state) => state.settings.currentWaterfallDates);
    const pointData = usePointData(selectedPoint);
    const {setCurrentWaterfallDates, setLoader} = useChartStateStoreContext((state) => state.actions, shallow);

    const isLoading = useChartStateStoreContext((state) => state.isLoading);
    const selectedAxis = useSelectedAxis();
    const {data: availableDates} = useFFTTimestampsQuery(pointData.equipment_id, (data) =>
        dateListByPointSelector(data, isImpactVue, pointData.id, selectedAxis)
    );

    const [selectedDate, updateSelectedDate] = useState(availableDates.at(-1));

    const {data: availableTimes} = useFFTTimestampsQuery(pointData.equipment_id, (data) =>
        timeListByPointDateSelector(data, isImpactVue, pointData.id, selectedDate, selectedAxis)
    );

    const {data: lastReadingDates} = useFFTTimestampsQuery(pointData.equipment_id, (data) =>
        lastTenByAxis(data, isImpactVue, pointData.id, selectedAxis.at(0))
    );
    const {data: user} = useUserProfile(profileUserSelector);

    useEffect(() => {
        if (currentWaterfallDates?.length) {
            return;
        }
        const sorted = [...lastReadingDates].sort((a, b) => {
            const firstDate = new Date(`${a.date} ${a.time}`);
            const secondDate = new Date(`${b.date} ${b.time}`);
            return firstDate - secondDate;
        });
        setCurrentWaterfallDates(sorted);
        updateDateTimeList(sorted);
        if (sorted.length === 0) {
            setLoader(false);
        }
    }, [lastReadingDates]);

    useEffect(() => {
        updateSelectedTime(Object.keys(availableTimes).at(-1));
    }, selectedDate);

    const [selectedTime, updateSelectedTime] = useState(Object.keys(availableTimes).at(-1));

    const initDateTimeList = () => (currentWaterfallDates?.length ? currentWaterfallDates : lastReadingDates);
    const [dateTimeList, updateDateTimeList] = useState(initDateTimeList);

    const apply = () => {
        setIsOpen(false);
        setCurrentWaterfallDates(dateTimeList);
    };

    const select = () => {
        updateDateTimeList([...dateTimeList, {date: selectedDate, time: selectedTime}]);
    };

    const onChange = (values) => {
        updateDateTimeList(values.map((val) => val.value));
    };

    const isSelected = (date, time) => {
        return dateTimeList.some((dateTimeItem) => dateTimeItem.date === date && dateTimeItem.time === time);
    };

    const isLimitReached = dateTimeList.length >= MAX_READINGS_COUNT;

    return (
        <Dropdown
            size="sm"
            isOpen={isOpen}
            disabled={!!isLoading}
            toggle={() => setIsOpen(!isOpen)}
        >
            <DropdownToggle
                caret
                className="btn-default"
            >
                Select Dates
            </DropdownToggle>
            <DropdownMenu>
                <div
                    className="row"
                    style={{width: 390}}
                >
                    <div className={"col-md-7 text-center"}>
                        <DatePickerInput
                            value={selectedDate || availableDates.at(-1)}
                            inline={true}
                            onChangeHandler={updateSelectedDate}
                            valueFormat={"YYYY-MM-DD"}
                            availableDates={availableDates || []}
                            type={DATEPICKER_TYPE.FFT}
                            disabled={(availableDates || []).length === 0}
                        />
                        <div className={"row"}>
                            <Dropdown
                                className={"ml-3"}
                                isOpen={isTimeDropdownOpen}
                                toggle={() => updateIsTimeDropdownOpen(!isTimeDropdownOpen)}
                            >
                                <DropdownToggle
                                    caret
                                    className="btn-default"
                                    disabled={(Object.keys(availableTimes) || []).length < 2}
                                >
                                    {selectedTime || "-"}
                                </DropdownToggle>
                                <DropdownMenu className={(Object.keys(availableTimes) || []).length > 8 ? "custom-scroll" : ""}>
                                    {(Object.keys(availableTimes) || []).map((time) => (
                                        <DropdownItem
                                            key={time}
                                            onClick={() => updateSelectedTime(time)}
                                            disabled={selectedTime === time}
                                        >
                                            {time}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>

                            <button
                                className={"btn btn-sm btn-info ml-2"}
                                onClick={select}
                                disabled={!selectedDate || !selectedTime || isLimitReached || isSelected(selectedDate, selectedTime)}
                            >
                                <i className={"fa fa-arrow-right"} />
                                <span>Select</span>
                            </button>
                        </div>
                    </div>
                    <div className={"col-md-5"}>
                        <div className="d-flex flex-column justify-between">
                            <div className="wrapper-dates-list">
                                <Select
                                    value={dateTimeList.map((date) => ({
                                        value: {...date},
                                        label: Helper.dateToUserFormat(`${date.date} ${date.time}`, user),
                                    }))}
                                    styles={{
                                        control: (base) => ({
                                            ...base,
                                            height: (26 * dateTimeList.length || 26) + 4,
                                            border: "none",
                                            boxShadow: "none",
                                            ...(isDarkTheme === "true"
                                                ? {
                                                      background: "#383835",
                                                  }
                                                : {}),
                                        }),
                                        multiValueLabel: (base) => ({
                                            ...base,
                                            ...(isDarkTheme === "true"
                                                ? {
                                                      backgroundColor: "#555",
                                                      color: "#ccc",
                                                  }
                                                : {}),
                                        }),
                                        multiValueRemove: (base) => ({
                                            ...base,
                                            ...(isDarkTheme === "true"
                                                ? {
                                                      backgroundColor: "#555",
                                                      color: "#ccc",
                                                  }
                                                : {}),
                                        }),
                                        multiValue: (base) => ({
                                            ...base,
                                            ...(isDarkTheme === "true"
                                                ? {
                                                      backgroundColor: "#555",
                                                      color: "#ccc",
                                                  }
                                                : {}),
                                        }),
                                    }}
                                    isMulti
                                    isSearchable={false}
                                    menuIsOpen={false}
                                    className={"form-control-select"}
                                    options={[]}
                                    onChange={onChange}
                                    components={{
                                        DropdownIndicator: () => null,
                                        IndicatorSeparator: () => null,
                                        ClearIndicator: () => null,
                                    }}
                                    placeholder={""}
                                    id=""
                                />
                            </div>
                            <div className="text-right save-wrapper-picker">
                                <button
                                    className={"btn btn-sm btn-info ml-2"}
                                    onClick={apply}
                                    disabled={dateTimeList.length === 0}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        {isLimitReached && <div className={"invalid-feedback show pr-3"}>The maximum number of readings has been reached.</div>}
                        {dateTimeList.length === 0 && <div className={"invalid-feedback show pr-3"}>You must select at least one reading.</div>}
                    </div>
                </div>
            </DropdownMenu>
        </Dropdown>
    );
};

export default DatePicker3d;

import React, {Component} from "react";
import PropTypes from "prop-types";
import {
    get as _get,
    set as _set
} from "lodash";
import {IDLE_THRESHOLD_TYPES, SPEED_TYPE_LABELS, SPEED_TYPES} from "../../../constants/constants";
import Helper from "../../../helpers/helper";
import {ValidationError} from "../../../shared";
import EquipmentTypeSelector from "../../../shared/form/EquipmentTypeSelector";
import SelectWrapper from "../../../helpers/select-wrapper";

class GeneralStep extends Component
{
    onChangeSpeedType = (event) => {
        const {onChange} = this.props;
        let profile = {...this.props.profile};
        let general = _get(profile, "general");

        const value = _get(event, "target.value");

        _set(general, "equipmentSpeedTypeId", value);
        _set(general, "currentSpeed", "");
        _set(general, "speedMax", "");
        _set(general, "speedMin", "");

        onChange({
            target: {
                name: "general",
                value: general
            }
        });
    }

    render() {
        const {profile, chartTypes, onChange, formErrors, validateStep, inProgress} = this.props;

        return (
            <React.Fragment>
                <div className={"general-step row"}>
                    <div className={"col-md-4"}>
                        <div className={"column-title"}>Basic</div>
                        <EquipmentTypeField
                            profile={profile}
                            formErrors={formErrors}
                            onChange={onChange}
                            validateStep={validateStep}
                            inProgress={inProgress}
                        />
                        <NameField
                            profile={profile}
                            formErrors={formErrors}
                            onChange={onChange}
                            validateStep={validateStep}
                            inProgress={inProgress}
                        />
                        <DescriptionField
                            profile={profile}
                            formErrors={formErrors}
                            onChange={onChange}
                            validateStep={validateStep}
                            inProgress={inProgress}
                        />
                    </div>
                    <div className={"col-md-4"}>
                        <div className={"column-title"}>Idle Threshold</div>
                        <IdleThresholdTypeField
                            profile={profile}
                            formErrors={formErrors}
                            onChange={onChange}
                            validateStep={validateStep}
                            inProgress={inProgress}
                        />
                        <IdleThresholdField
                            profile={profile}
                            formErrors={formErrors}
                            onChange={onChange}
                            chartTypes={chartTypes}
                            validateStep={validateStep}
                            inProgress={inProgress}
                        />
                    </div>
                    <div className={"col-md-4 pl-4"}>
                        <div className={"column-title"}>Speed Settings</div>
                        <SpeedTypeField
                            profile={profile}
                            formErrors={formErrors}
                            onChange={this.onChangeSpeedType}
                            validateStep={validateStep}
                            inProgress={inProgress}
                        />
                        <SpeedValues
                            profile={profile}
                            formErrors={formErrors}
                            onChange={onChange}
                            validateStep={validateStep}
                            inProgress={inProgress}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const EquipmentTypeField = ({profile, formErrors, onChange, validateStep, inProgress}) => {
    const errorMsg = _get(formErrors, "equipmentTypeId");
    const value = _get(profile, "general.equipmentTypeId");

    let validationClass = "";
    if (errorMsg) {
        validationClass = " is-invalid";
    } else if (value && !inProgress) {
        validationClass = " valid";
    }

    return (
        <div className={"position-relative validation-control" + validationClass}>
            <EquipmentTypeSelector
                name={"general.equipmentTypeId"}
                value={value}
                onChange={(e) => {
                    onChange(e);
                    validateStep();
                }}
            />
            <ValidationError message={errorMsg}/>
        </div>
    );
};

EquipmentTypeField.propTypes = {
    profile: PropTypes.object,
    equipmentTypes: PropTypes.array,
    formErrors: PropTypes.object,
    onChange: PropTypes.func,
    validateStep: PropTypes.func,
    inProgress: PropTypes.bool,
    fetchEquipmentTypes: PropTypes.func
};

const NameField = ({profile, formErrors, onChange, validateStep, inProgress}) => {
    const errorMsg = _get(formErrors, "name");
    const value = _get(profile, "general.name");

    let validationClass = "";
    if (errorMsg) {
        validationClass = " is-invalid";
    } else if (value && !inProgress) {
        validationClass = " valid";
    }

    return (
        <div className={"form-group"}>
            <label className="form-label">Display Name <span className="color-danger">*</span></label>
            <input
                autoComplete={"off"}
                className={"form-control" + validationClass}
                name={"general.name"}
                value={value}
                onChange={onChange}
                onBlur={validateStep}
            />
            <ValidationError message={errorMsg}/>
        </div>
    );
};

NameField.propTypes = {
    profile: PropTypes.object,
    formErrors: PropTypes.object,
    onChange: PropTypes.func,
    validateStep: PropTypes.func,
    inProgress: PropTypes.bool
};

const DescriptionField = ({profile, formErrors, onChange, validateStep, inProgress}) => {
    const errorMsg = _get(formErrors, "description");
    const value = _get(profile, "general.description");

    let validationClass = "";
    if (errorMsg) {
        validationClass = " is-invalid";
    } else if (value && !inProgress) {
        validationClass = " valid";
    }

    return (
        <div className={"form-group"}>
            <label>Description</label>
            <input
                className={"form-control" + validationClass}
                name={"general.description"}
                value={value}
                onChange={onChange}
                onBlur={validateStep}
            />
            <ValidationError message={errorMsg}/>
        </div>
    );
};

DescriptionField.propTypes = {
    profile: PropTypes.object,
    formErrors: PropTypes.object,
    onChange: PropTypes.func,
    validateStep: PropTypes.func,
    inProgress: PropTypes.bool
};

const IdleThresholdTypeField = ({profile, formErrors, onChange, validateStep, inProgress}) => {
    const errorMsg = _get(formErrors, "idleThresholdType");
    const value = _get(profile, "general.idleThresholdType", "");

    let validationClass = "";
    if (errorMsg) {
        validationClass = " is-invalid";
    } else if (value && !inProgress) {
        validationClass = " valid";
    }

    return (
        <div className={"form-group"}>
            <label>Idle Threshold Type</label>
            <SelectWrapper
                className={validationClass}
                name={"general.idleThresholdType"}
                value={value}
                onChange={onChange}
                onBlur={validateStep}
            >
                <option value={""}>---</option>
                {Object.values(IDLE_THRESHOLD_TYPES).map(type =>
                    <option key={type} value={type}>{Helper.capitalize(type)}</option>
                )}
            </SelectWrapper>
            <ValidationError message={errorMsg}/>
        </div>
    );
};

IdleThresholdTypeField.propTypes = {
    profile: PropTypes.object,
    formErrors: PropTypes.object,
    onChange: PropTypes.func,
    validateStep: PropTypes.func,
    inProgress: PropTypes.bool
};

const IdleThresholdField = ({profile, formErrors, onChange, chartTypes, validateStep, inProgress}) => {
    const errorMsg = _get(formErrors, "idleThreshold");
    const value = _get(profile, "general.idleThreshold");

    let validationClass = "";
    if (errorMsg) {
        validationClass = " is-invalid";
    } else if (value && !inProgress) {
        validationClass = " valid";
    }

    return (
        <div className={"form-group"}>
            <label>Idle Threshold</label>
            <div className={"input-group"}>
                <input
                    className={"form-control" + validationClass}
                    type="number"
                    name={"general.idleThreshold"}
                    value={value}
                    onChange={onChange}
                    onBlur={validateStep}
                />
                <ValidationError message={errorMsg}/>
                <div className="input-group-append">
                    <span className="input-group-text">
                        {Helper.getIdleThresholdMeasure(
                            chartTypes,
                            _get(profile, "general.idleThresholdType")
                        )}
                    </span>
                </div>
            </div>
        </div>
    );
};

IdleThresholdField.propTypes = {
    profile: PropTypes.object,
    formErrors: PropTypes.object,
    onChange: PropTypes.func,
    chartTypes: PropTypes.any,
    validateStep: PropTypes.func,
    inProgress: PropTypes.bool
};

const SpeedTypeField = ({profile, formErrors, onChange, validateStep, inProgress}) => {
    const errorMsg = _get(formErrors, "equipmentSpeedTypeId");
    const value = _get(profile, "general.equipmentSpeedTypeId", "");

    let validationClass = "";
    if (errorMsg) {
        validationClass = " is-invalid";
    } else if (value && !inProgress) {
        validationClass = " valid";
    }

    return (
        <div className={"form-group"}>
            <label>Speed Type</label>
            <SelectWrapper
                className={validationClass}
                name="general.equipmentSpeedTypeId"
                value={value}
                onChange={onChange}
                onBlur={validateStep}
            >
                <option value="">---</option>
                {Object.values(SPEED_TYPES).map((speedType) => (
                    <option key={speedType} value={speedType}>{SPEED_TYPE_LABELS[speedType]}</option>
                ))}
            </SelectWrapper>
            <ValidationError message={errorMsg}/>
        </div>
    );
};

SpeedTypeField.propTypes = {
    profile: PropTypes.object,
    formErrors: PropTypes.object,
    onChange: PropTypes.func,
    validateStep: PropTypes.func,
    inProgress: PropTypes.bool
};

const SpeedValues = ({profile, formErrors, onChange, validateStep, inProgress}) => {
    const isFixed = +_get(profile, "general.equipmentSpeedTypeId") === SPEED_TYPES.FIXED;
    const isRatio = +_get(profile, "general.equipmentSpeedTypeId") === SPEED_TYPES.RATIO;
    const isVariable = +_get(profile, "general.equipmentSpeedTypeId") === SPEED_TYPES.VARIABLE;

    return (
        <React.Fragment>
            {(isFixed || isRatio)  &&
                <RunningSpeedField
                    profile={profile}
                    formErrors={formErrors}
                    onChange={onChange}
                    validateStep={validateStep}
                    inProgress={inProgress}
                />
            }
            {isVariable &&
                <React.Fragment>
                    <MinimumRpmField
                        profile={profile}
                        formErrors={formErrors}
                        onChange={onChange}
                        validateStep={validateStep}
                        inProgress={inProgress}
                    />
                    <MaximumRpmField
                        profile={profile}
                        formErrors={formErrors}
                        onChange={onChange}
                        validateStep={validateStep}
                        inProgress={inProgress}
                    />
                </React.Fragment>
            }
        </React.Fragment>
    );
};

SpeedValues.propTypes = {
    profile: PropTypes.object,
    formErrors: PropTypes.object,
    onChange: PropTypes.func,
    validateStep: PropTypes.func,
    inProgress: PropTypes.bool
};

const RunningSpeedField = ({profile, formErrors, onChange, validateStep, inProgress}) => {
    const label = +_get(profile, "general.equipmentSpeedTypeId") === SPEED_TYPES.FIXED ? "Default Running Speed" : "Base Running Speed";
    const errorMsg = _get(formErrors, "currentSpeed");
    const value = _get(profile, "general.currentSpeed");

    let validationClass = "";
    if (errorMsg) {
        validationClass = " is-invalid";
    } else if (value && !inProgress) {
        validationClass = " valid";
    }

    return (
        <div className={"form-group"}>
            <label>{label}</label>
            <input
                className={"form-control" + validationClass}
                name={"general.currentSpeed"}
                value={value}
                onChange={onChange}
                onBlur={validateStep}
            />
            <ValidationError message={errorMsg}/>
        </div>
    );
};

RunningSpeedField.propTypes = {
    profile: PropTypes.object,
    formErrors: PropTypes.object,
    onChange: PropTypes.func,
    validateStep: PropTypes.func,
    inProgress: PropTypes.bool
};

const MinimumRpmField = ({profile, formErrors, onChange, validateStep, inProgress}) => {
    const errorMsg = _get(formErrors, "speedMin");
    const value = _get(profile, "general.speedMin");

    let validationClass = "";
    if (errorMsg) {
        validationClass = " is-invalid";
    } else if (value && !inProgress) {
        validationClass = " valid";
    }

    return (
        <div className={"form-group"}>
            <label>Minimum RPM</label>
            <input
                className={"form-control" + validationClass}
                name={"general.speedMin"}
                value={value}
                onChange={onChange}
                onBlur={validateStep}
            />
            <ValidationError message={errorMsg}/>
        </div>
    );
};

MinimumRpmField.propTypes = {
    profile: PropTypes.object,
    formErrors: PropTypes.object,
    onChange: PropTypes.func,
    validateStep: PropTypes.func,
    inProgress: PropTypes.bool
};

const MaximumRpmField = ({profile, formErrors, onChange, validateStep, inProgress}) => {
    const errorMsg = _get(formErrors, "speedMax");
    const value = _get(profile, "general.speedMax");

    let validationClass = "";
    if (errorMsg) {
        validationClass = " is-invalid";
    } else if (value && !inProgress) {
        validationClass = " valid";
    }

    return (
        <div className={"form-group"}>
            <label>Maximum RPM</label>
            <input
                className={"form-control" + validationClass}
                name={"general.speedMax"}
                value={value}
                onChange={onChange}
                onBlur={validateStep}
            />
            <ValidationError message={errorMsg}/>
        </div>
    );
};

MaximumRpmField.propTypes = {
    profile: PropTypes.object,
    formErrors: PropTypes.object,
    onChange: PropTypes.func,
    validateStep: PropTypes.func,
    inProgress: PropTypes.bool
};

GeneralStep.propTypes = {
    profile: PropTypes.object,
    chartTypes: PropTypes.object,
    onChange: PropTypes.func,
    formErrors: PropTypes.object,
    validateStep: PropTypes.func,
    inProgress: PropTypes.bool,
    equipmentTypes: PropTypes.array,
    fetchEquipmentTypes: PropTypes.func
};

export default GeneralStep;
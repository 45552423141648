import React, {Component} from "react";
import FormValidator from "../../../helpers/form-validator";
import PropTypes from "prop-types";
import SelfBearingApi from "../../../api/selfBearing";
import {
    get as _get,
    set as _set
} from "lodash";
import {Loader} from "../../../shared";
// import {Button} from "reactstrap";
import ValidationError from "../../../layouts/ValidationError/ValidationError";
import {Modal} from "../../../shared";

const propTypes = {
    bearingId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.bool,
        PropTypes.string
    ]),
    title: PropTypes.string,
    bearing: PropTypes.object,
    match: PropTypes.object,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
};

const rules = [
    {
        field: "mfr",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required."
    },
    {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required."
    },
    {
        field: "balls_rollers",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required."
    },
    {
        field: "ftf",
        method: "isFloat",
        options: {"min": 0},
        validWhen: true,
        skipOnEmpty: false,
        message: "FTF Value should be a float value and not less than 0."
    },
    {
        field: "bsf",
        method: "isFloat",
        options: {"min": 0},
        validWhen: true,
        skipOnEmpty: false,
        message: "BSF Value should be a float value and not less than 0."
    },
    {
        field: "bpfo",
        method: "isFloat",
        options: {"min": 0},
        validWhen: true,
        skipOnEmpty: false,
        message: "BPFO Value should be a float value and not less than 0."
    },
    {
        field: "bpfi",
        method: "isFloat",
        options: {"min": 0},
        validWhen: true,
        skipOnEmpty: false,
        message: "BPFI Value should be a float value and not less than 0."
    }
];

class SelfBearingModal extends Component
{
    constructor(props) {
        super(props);
        this.validator = new FormValidator(rules);
        this.state = {
            title: this.props.bearingId ? "Edit Custom Bearing" : "Add Custom Bearing",
            data: {
                mfr: "",
                name: "",
                balls_rollers: "",
                ftf: "",
                bsf: "",
                bpfo: "",
                bpfi: ""
            },
            loader: true,
            inProgress: false,
            validation: this.validator.valid(),
            bearingId: this.props.bearingId ? this.props.bearingId : "",
        };

        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitAddBearing = this.handleSubmitAddBearing.bind(this);
        this.handleSubmitEditBearing = this.handleSubmitEditBearing.bind(this);
    }

    handleSubmitAddBearing(data) {
        return SelfBearingApi.add({data}).then(() => {
            this.props.onClose();
        });
    }

    handleSubmitEditBearing(data) {
        return SelfBearingApi.update(this.state.bearingId, {data}).then(() => {
            this.props.onSubmit();
            this.props.onClose();
        });
    }

    handleSubmit() {
        const validation = this.validator.validate(this.state.data);
        const {data} = Object.assign({}, this.state);

        this.setState(
            {inProgress: true},
            () => {
                this.setState({validation}, () => {
                    if (validation.isValid) {
                        if (this.state.bearingId) {
                            this.handleSubmitEditBearing(data).catch(err => {
                                if (err.errors) {
                                    const validation = this.state.validation;

                                    Object.keys(err.errors).map(key => {
                                        validation[key].isValid = false;
                                        validation[key].message = err.errors[key];
                                    });

                                    this.setState({
                                        validation,
                                        inProgress: false
                                    });
                                } else {
                                    this.setState({
                                        inProgress: false
                                    });
                                }
                            });
                        } else {
                            this.handleSubmitAddBearing(data).catch(err => {
                                if (err.errors) {
                                    const validation = this.state.validation;
                                    Object.keys(err.errors).map(key => {
                                        validation[key].isValid = false;
                                        validation[key].message = err.errors[key];
                                    });

                                    this.setState({
                                        validation,
                                        inProgress: false
                                    });
                                } else {
                                    this.setState({
                                        inProgress: false
                                    });
                                }
                            });
                        }
                    } else {
                        this.setState({inProgress: false});
                    }
                });
            }
        );
    }

    onChange(e) {
        const data = {...this.state.data};
        _set(data, _get(e, "target.name", ""), _get(e, "target.value", ""));
        const validation = this.validator.validate(data, _get(e, "target.name", ""));
        this.setState({data, validation});
    }

    componentDidMount() {

        if (this.state.bearingId) {
            SelfBearingApi.get(this.props.bearingId).then((response) => {
                if (response.status === "ok") {
                    const bearing = response.bearing;
                    this.setState({
                        loader: false,
                        data: {
                            mfr: bearing.mfr,
                            name: bearing.name,
                            balls_rollers: bearing.balls_rollers,
                            ftf: bearing.ftf,
                            bsf: bearing.bsf,
                            bpfo: bearing.bpfo,
                            bpfi: bearing.bpfi
                        },
                    });
                }
            });
        } else {
            this.setState({
                loader: false,
                data: {
                    ...this.state.data,
                }
            });
        }
    }

    render() {

        const {
            loader,
            title,
            inProgress,
            data,
            validation
        } = this.state;

        const {onClose} = this.props;

        return (
            <Modal
                showModal={true}
                title={title}
                size={"lg"}
                loader={loader}
                onClose={onClose}
                onSubmit={this.handleSubmit}
                disableSubmit={this.state.inProgress}
                submitTitle="Save"
            >
                <div className="" style={{ marginBottom: 24 }}>
                    {loader
                        ?
                        <div className="loader-fullscreen"><Loader/></div>
                        :
                        <div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="text-right form-label">Name:</label>
                                        <div className="">
                                            <input
                                                className={"form-control" + (validation.name.isValid || !validation.name.message ? "" : " is-invalid")}
                                                type="text"
                                                name="name"
                                                defaultValue={data.name}
                                                onChange={this.onChange}
                                                disabled={inProgress}
                                            />
                                            <ValidationError message={validation.name.message}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="text-right form-label">Manufacturer:</label>
                                        <div className="">
                                            <input
                                                className={"form-control" + (validation.mfr.isValid || !validation.mfr.message ? "" : " is-invalid")}
                                                type="text"
                                                name="mfr"
                                                defaultValue={data.mfr}
                                                onChange={this.onChange}
                                                disabled={inProgress}
                                            />
                                            <ValidationError message={validation.mfr.message}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="text-right form-label">Balls/Rollers:</label>
                                        <div className="">
                                            <input
                                                className={"form-control" + (validation.balls_rollers.isValid || !validation.balls_rollers.message ? "" : " is-invalid")}
                                                type="text"
                                                name="balls_rollers"
                                                defaultValue={data.balls_rollers}
                                                onChange={this.onChange}
                                                disabled={inProgress}
                                            />
                                            <ValidationError message={validation.balls_rollers.message}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="text-right form-label">FTF:</label>
                                        <div className="">
                                            <input
                                                className={"form-control" + (validation.ftf.isValid || !validation.ftf.message ? "" : " is-invalid")}
                                                type="text"
                                                name="ftf"
                                                defaultValue={data.ftf}
                                                onChange={this.onChange}
                                                disabled={inProgress}
                                            />
                                            <ValidationError message={validation.ftf.message}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="text-right form-label">BSF:</label>
                                        <div className="">
                                            <input
                                                className={"form-control" + (validation.bsf.isValid || !validation.bsf.message ? "" : " is-invalid")}
                                                type="text"
                                                name="bsf"
                                                defaultValue={data.bsf}
                                                onChange={this.onChange}
                                                disabled={inProgress}
                                            />
                                            <ValidationError message={validation.bsf.message}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="text-right form-label">BPFO:</label>
                                        <div className="">
                                            <input
                                                className={"form-control" + (validation.bpfo.isValid || !validation.bpfo.message ? "" : " is-invalid")}
                                                type="text"
                                                name="bpfo"
                                                defaultValue={data.bpfo}
                                                onChange={this.onChange}
                                                disabled={inProgress}
                                            />
                                            <ValidationError message={validation.bpfo.message}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="text-right form-label">BPFI:</label>
                                        <div className="">
                                            <input
                                                className={"form-control" + (validation.bpfi.isValid || !validation.bpfi.message ? "" : " is-invalid")}
                                                type="text"
                                                name="bpfi"
                                                defaultValue={data.bpfi}
                                                onChange={this.onChange}
                                                disabled={inProgress}
                                            />
                                            <ValidationError message={validation.bpfi.message}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Modal>
        );
    }
}


SelfBearingModal.propTypes = propTypes;

export default SelfBearingModal;
/* eslint-disable no-unused-vars */
import {create} from "zustand";
import {immer} from "zustand/middleware/immer";
import {shallow} from "zustand/shallow";
import {subscribeWithSelector} from "zustand/middleware";
import {getSyncSelector} from "./helpers/getSyncSelector";
import {useRef} from "react";

export const useTimestampsSyncStore = create(
    immer(
        subscribeWithSelector((set, get) => ({
            timestamps: {},
            actions: {
                setTimestampsForFft(installationPointId, {date = "last", time = "last"}, isImpactVue = false) {
                    const installPointSelector = getSyncSelector(installationPointId, isImpactVue);
                    set((state) => {
                        state.timestamps[installPointSelector] = {date, time};
                    });
                },
            },
        }))
    )
);

export const useFftSyncedTimestamp = (installationPointId, isImpactVue = false) => {
    const installPointSelector = getSyncSelector(installationPointId, isImpactVue);
    const defaultTimestamps = useRef({date: "last", time: "last"});

    return useTimestampsSyncStore((state) => state.timestamps[installPointSelector], shallow) || defaultTimestamps.current;
};

export const useTimestampsSyncStoreActions = () => useTimestampsSyncStore((state) => state.actions, shallow);

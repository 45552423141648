import React, {useEffect, useReducer, useRef} from "react";
import PropTypes from "prop-types";
import {withGlobalStore} from "../../stores/GlobalStore";
import {useHistory, withRouter} from "react-router";
import Helper from "../../helpers/helper";
import {hideMenu} from "react-contextmenu";
import "../../assets/scss/components/bearings/bearings.scss";
import ImpactViewNotification from "../../modals/ImpactViewNotification";
import {ACTION, reducer} from "./reducer/mainReducer";
import {HeaderEquipment} from "../../shared/header";
import Aside from "./aside/aside";
import BodyEquipment from "./components/bodyEquipment";
import ModalWrapper from "./components/modalWrapper";
import useChartMarksQuery from "../../hooks/api/chartMarksSimple/useChartMarksQuery";

import {useChartTypesStore} from "../../stores/zustand/ChartSelectedChartTypeStore";

import {useFFTTimestampsQuery} from "../../hooks/api/fftReadings/TimestampsQuery";
import {useEquipmentQuery} from "../../hooks/api/equipment/useEquipmentQuery";
import useEquipmentNotesQuery from "../../hooks/api/NotesCount/useEquipmentNotesQuery";
import {useEquipmentNotesInvalidate} from "../../hooks/api/NotesCount/useEquipmentNotesInvalidate";
import {useEquipmentBreadcrumbsStore} from "../../stores/zustand/EquipmentBreadcrumbsStore";
import {Loader} from "../../shared";
import useAlarmList from "../../hooks/api/Alarm/UseAlarmListQuery";
import {useAlarmListInvalidate} from "../../hooks/api/Alarm/UseAlarmListInvalidate";
import {useChartSelectedEquipmentStore, useChartSelectedEquipmentStoreActions} from "../../stores/zustand/ChartSelectedEquipmentStore";
import {useCurrentAlertActions} from "../../stores/CurrentAlertStore";
import {useUserViewChartType} from "../../stores/UserLocalSettingsStore";
import {useBeforeAlertScrollEffect} from "./hooks/useBeforeAlertScrollEffect";
import {useSyncSensorWithRoute} from "./hooks/useSyncSensorWithRoute";
import {useAdapterTypes} from "./hooks/useAdapterTypes";

const initialState = {
    globalSearchString: "",
    inProgress: false,
    showPopoverNotes: false,
    notes: [],
    selectedAlarms: [],
    selectedType: null,
    queryFilters: [],
    excluded: [],
    deleteEquipment: false,
};

const Charts = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const viewChartType = useUserViewChartType();
    const chartTypes = useChartTypesStore();
    const breadcrumbs = useEquipmentBreadcrumbsStore();
    const {clearCurrentAlert} = useCurrentAlertActions();

    const {checkSelectedInstallationPoint} = useChartSelectedEquipmentStoreActions();
    const selectedEquipment = useChartSelectedEquipmentStore();

    const {globalSearchString, fftFrequencyType, selectedAlarms, excluded, selectedType, deleteEquipment} = state;

    const {user, match} = props;
    let simpleChartElementHeight = 538 + 13;
    let fftChartElementHeight = 601 + 13;
    const prevProps = useRef(null);

    const history = useHistory();

    const {isSuccess: equipmentLoader, equipmentItem} = useEquipmentQuery(match.params.equipmentId);
    const {isSuccess: equipmentNotesLoader, notesCount} = useEquipmentNotesQuery(match.params.equipmentId);
    const invalidateEquipmentNotes = useEquipmentNotesInvalidate(match.params.equipmentId);
    const invalidateAlarmList = useAlarmListInvalidate(match.params.equipmentId);

    const {isSuccess: chartMarksLoader} = useChartMarksQuery(match.params.equipmentId);
    const {isSuccess: fftTimestampsLoader} = useFFTTimestampsQuery(match.params.equipmentId);

    const {customsChartTypesLoader} = useAdapterTypes();

    const {activeAlarms, countActiveEquipmentAlarms} = useAlarmList(match.params.equipmentId);

    const isSuccess = chartMarksLoader && fftTimestampsLoader && equipmentLoader && equipmentNotesLoader && customsChartTypesLoader;

    useEffect(() => {
        if (isSuccess === false) {
            prevProps.current = props;
            scrollToNearestFft();
        }

        if (equipmentLoader) {
            if (!selectedEquipment[equipmentItem.id]) {
                return;
            }

            checkSelectedInstallationPoint(equipmentItem);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (viewChartType === "blocks" && document.getElementById("chart-list")) {
            simpleChartElementHeight = 420;
            fftChartElementHeight = 420;
            document.getElementById("chart-list").dispatchEvent(new Event("scroll"));
        }

        return () => clearCurrentAlert();
    }, []);

    const scrollToNearestFft = (chartType, installationPointId) => {
        if (chartType && installationPointId) {
            const target = document.getElementById(`placeholder-${chartType}-${installationPointId}`);

            if (target !== null) {
                target.scrollIntoView();
            }
        }
    };

    const handleChangeGlobalSearch = (globalSearchString) => {
        dispatch({type: ACTION.SET_STATE, state: {globalSearchString: globalSearchString}});
    };

    const handleSetIdleThresholdModal = (installationPointId = null) => {
        history.push(
            Helper.setHashParams({
                modal: "idle-threshold",
                id: installationPointId,
            })
        );
    };

    const setSelectedAlarms = (ids = [], filters = [], selectedType, excluded = []) => {
        dispatch({
            type: ACTION.SET_STATE,
            state: {selectedAlarms: [...ids], queryFilters: filters, selectedType, excluded},
        });
    };

    const deleteChartMark = (chartMarkId) => {
        props.deleteChartMark(chartMarkId);
        invalidateEquipmentNotes();
        props.updateCountGlobalNotes();
    };

    useBeforeAlertScrollEffect();
    useSyncSensorWithRoute();

    return (
        <>
            {equipmentLoader ? (
                <>
                    <HeaderEquipment
                        equipment={equipmentItem}
                        breadcrumbs={breadcrumbs}
                        handleChangeGlobalSearch={handleChangeGlobalSearch}
                        globalSearchString={globalSearchString}
                        user={user}
                        countActiveEquipmentAlarms={countActiveEquipmentAlarms}
                        notesCount={notesCount}
                        selectedAlarms={selectedAlarms}
                        setSelectedAlarms={setSelectedAlarms}
                        excluded={excluded}
                        handleNotesCount={invalidateEquipmentNotes}
                    />
                    <div className="d-flex align-items-start">
                        <div
                            className="block-body charts-list custom-scroll position-relative custom-scroll-equipment"
                            id="chart-list"
                            onScroll={hideMenu}
                        >
                            <ModalWrapper
                                equipment={equipmentItem}
                                dispatch={dispatch}
                                state={state}
                                chartTypes={chartTypes}
                                deleteEquipment={deleteEquipment}
                                match={props.match}
                                addChartMark={props.addChartMark}
                                updateChartMark={props.updateChartMark}
                                updateAlertsList={invalidateAlarmList}
                                updateCountGlobalNotes={props.updateCountGlobalNotes}
                                updateCountActiveAlarms={props.updateCountActiveAlarms}
                                handleNotesCount={invalidateEquipmentNotes}
                                selectedType={selectedType}
                                selectedAlarms={selectedAlarms}
                            >
                                <BodyEquipment
                                    chartTypes={chartTypes}
                                    equipment={equipmentItem}
                                    simpleChartElementHeight={simpleChartElementHeight}
                                    user={user}
                                    handleSetIdleThresholdModal={handleSetIdleThresholdModal}
                                    deleteChartMark={deleteChartMark}
                                    fftFrequencyType={fftFrequencyType}
                                    fftChartElementHeight={fftChartElementHeight}
                                />
                            </ModalWrapper>
                        </div>
                        <Aside
                            equipment={equipmentItem}
                            activeAlarms={activeAlarms}
                        />
                    </div>
                    <ImpactViewNotification />
                </>
            ) : (
                <Loader />
            )}
        </>
    );
};

Charts.propTypes = {
    match: PropTypes.object,
    auth: PropTypes.object,
    user: PropTypes.object,

    addChartMark: PropTypes.func,
    updateChartMark: PropTypes.func,
    deleteChartMark: PropTypes.func,

    updateCountActiveAlarms: PropTypes.func,
    updateCountGlobalNotes: PropTypes.func,
};

export default withGlobalStore(withRouter(Charts));

import Api from "./api";

const Location = {
    get: (id) => Api.get("locations/" + id),
    getDeleteInfo: (id) => Api.get("locations/delete-info/" + id),
    list: ({onlyWithEquipment = false} = {}) => Api.get(`locations?onlyWithEquipment=${+onlyWithEquipment}`),
    equipmentsForLocation: (params) => Api.get("locations/get-equipments-list", {...params}),
    update: (body, id) => Api.post("locations/update/" + id, body),
    create: (body) => Api.post("locations/create", body),
    delete: (id) => Api.delete("locations/delete/" + id),
    updateTree: (body) => Api.post("locations/update-tree", body),
    updateSort: (body) => Api.post("locations/update-sort", body),
};

export default Location;

import React from "react";
import PropTypes from "prop-types";
import EmailTemplate from "./email-template";
import FrequencyConverter from "../../../helpers/frequency-converter";
import AlertActions from "./alert-actions";
import SelectWrapper from "../../../helpers/select-wrapper";
import {FREQUENCY_TYPES} from "../../../constants/constants";

const BandAlertTemplate = ({
    isCurrent,
    inProgress,
    isChanged,
    isAddBaned,
    alert,
    users,
    level,
    units,
    conditions,
    currentSpeed,
    currentFrequency,
    update,
    remove,
    reset,
    save,
    setCurrent,
    validErrorMsg,
}) => {
    if (alert.fftAlertType !== 1) return "";
    return (
        <div
            className={`form-group mb-3 alert-level-block-big alert-level-block${isCurrent ? " current" : ""}`}
            onClick={() => (isCurrent ? {} : setCurrent(alert))}
        >
            <div className="row flex-xl-nowrap justify-content-end">
                <div className={level.name === "Caution" ? "col col-12 col-xl-3 mb-3 mb-xl-0" : "col col-12 col-xl-auto flex-grow-1 mb-3 mb-xl-0"}>
                    <label className={level.name === "Caution" ? "level-label caution-level" : "level-label warning-level"}>
                        {isAddBaned ? "Add New " : ""}
                        {level.name || ""}
                    </label>
                </div>
                <div className="col-12 col-md-6 col-xl-3 mb-3 mb-md-0">
                    <SelectWrapper
                        style={{
                            width: "100%",
                            display: "block",
                        }}
                        value={alert.condition || ""}
                        onChange={(ev) => update({condition: ev.target.value})}
                        disabled={!isCurrent}
                        name={"level-rand"}
                    >
                        {Object.keys(conditions || {}).map((key) => (
                            <option
                                key={key}
                                value={key}
                            >
                                {key}
                            </option>
                        ))}
                    </SelectWrapper>
                </div>
                <div className="col-12 col-md-4 col-xl-6 mb-3 mb-md-0">
                    <div className="input-group input-group-sm">
                        <input
                            className={`form-control request-form-control${isCurrent ? " current-alert-value" : ""}`}
                            type="number"
                            value={alert.value.toString() || ""}
                            step={0.1}
                            min={0}
                            onChange={(ev) => update({value: ev.target.value})}
                            disabled={!isCurrent}
                        />
                        {!!units && (
                            <div className="input-group-append">
                                <span className="input-group-text request-form-control">{units}</span>
                            </div>
                        )}
                    </div>
                    {alert.value === "" && validErrorMsg && isCurrent && (
                        <span className="error-msg d-block color-danger mt-1 text-right">This field cannot be empty</span>
                    )}
                </div>
            </div>
            <div className="row justify-content-end mt-2">
                <div className="col-12 col-md-3 mb-1 mb-md-0">
                    <span className="trigger-type label-horizontal-input d-block mt-2">Trigger Type</span>
                </div>
                <div className="col-md-9">
                    <SelectWrapper
                        style={{
                            width: "100%",
                            display: "block",
                        }}
                        value={alert.notificationType || ""}
                        onChange={(ev) => update({notificationType: +ev.target.value})}
                        disabled={!isCurrent}
                        name={"trigger-type"}
                    >
                        <option value={1}>Alert only when reading exceeds the last active alert</option>
                        <option value={2}>Alert for every reading</option>
                    </SelectWrapper>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12 col-md-3 mb-1 mb-md-0">
                    <span>From / To</span>
                </div>
                <div className="col-md-3">
                    <input
                        className={`form-control request-form-control${isCurrent ? " current-alert-fftBandFrom" : ""}`}
                        type="number"
                        value={alert.fftBandFrom || ""}
                        step={1}
                        min={0}
                        onChange={(ev) => update({fftBandFrom: ev.target.value, fft_band_from: ev.target.value})}
                        disabled={!isCurrent}
                    />
                </div>
                <div className="col-md-3">
                    <input
                        className={`form-control request-form-control${isCurrent ? " current-alert-fftBandTo" : ""}`}
                        type="number"
                        value={alert.fftBandTo || ""}
                        step={1}
                        min={1}
                        onChange={(ev) => update({fftBandTo: ev.target.value, fft_band_to: ev.target.value})}
                        disabled={!isCurrent}
                    />
                </div>
                <div className="col-md-3">
                    <SelectWrapper
                        style={{
                            width: "100%",
                            display: "block",
                        }}
                        value={alert.fftBandFrequencyType || FREQUENCY_TYPES.HZ}
                        onChange={(ev) => {
                            let fftBandFrom = +FrequencyConverter.fromType(currentFrequency, alert.fftBandFrom, currentSpeed)
                                .toType(ev.target.value)
                                .numberFormat();

                            let fftBandTo = +FrequencyConverter.fromType(currentFrequency, alert.fftBandTo, currentSpeed)
                                .toType(ev.target.value)
                                .numberFormat();

                            update({
                                fftBandFrequencyType: ev.target.value,
                                fftBandFrom: fftBandFrom.toString(),
                                fftBandTo: fftBandTo.toString(),
                            });
                        }}
                        disabled={!isCurrent}
                        name={"band-type"}
                    >
                        <option value={FREQUENCY_TYPES.HZ}>{FREQUENCY_TYPES.HZ}</option>
                        <option value={FREQUENCY_TYPES.CPM}>{FREQUENCY_TYPES.CPM}</option>
                        <option
                            value={FREQUENCY_TYPES.ORDERS}
                            disabled={currentSpeed <= 0}
                        >
                            {FREQUENCY_TYPES.ORDERS}
                        </option>
                    </SelectWrapper>
                </div>
            </div>
            <hr />
            <EmailTemplate
                isCurrent={isCurrent}
                users={users}
                alert={alert}
                level={level}
                update={update}
            />
            <div>
                <AlertActions
                    inProgress={inProgress}
                    isCurrent={isCurrent}
                    isChanged={isChanged}
                    reset={reset}
                    save={save}
                    remove={remove}
                    alert={alert}
                />
            </div>
        </div>
    );
};

BandAlertTemplate.propTypes = {
    inProgress: PropTypes.bool,
    isCurrent: PropTypes.bool,
    isChanged: PropTypes.bool,
    isAddBaned: PropTypes.bool,
    alias: PropTypes.string,
    units: PropTypes.string,
    currentFrequency: PropTypes.string,
    users: PropTypes.array,
    alert: PropTypes.object,
    level: PropTypes.object,
    conditions: PropTypes.object,
    versionType: PropTypes.number,
    currentSpeed: PropTypes.number,
    cancelAddBand: PropTypes.func,
    setCurrent: PropTypes.func,
    update: PropTypes.func,
    remove: PropTypes.func,
    reset: PropTypes.func,
    save: PropTypes.func,
    specs: PropTypes.object,
    validErrorMsg: PropTypes.bool,
};

export default BandAlertTemplate;

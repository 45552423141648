import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {Modal} from "../../../../shared";
import {get as _get, pull as _pull, findLastKey as _findLastKey, forEach as _forEach} from "lodash";
import ApiReading from "../../../../api/reading";
import Toast from "../../../shared/toast";
import {useFFTTimestampsQuery} from "../../../../hooks/api/fftReadings/TimestampsQuery";
import {useEquipmentByParams} from "../../../../hooks/api/equipment/useEquipmentQuery";
import useAxisLabelQuery from "../../../../hooks/api/axisLabel/useAxisLabelQuery";
import {useChartTypesStore} from "../../../../stores/zustand/ChartSelectedChartTypeStore";
import {getSeriesList} from "../helpers/getSeriesList";
import {useTimestampRefetch} from "../../../../hooks/api/fftReadings/useTimestampRefetch";
import {useSyncedAxis} from "../store/sync/axisSyncStore";
import {useIsImpactVue} from "../hooks/useChartTypes";

export default function hasOutliers(WrappedComponent) {
    const propTypes = {
        chartType: PropTypes.string,
        selectedPoint: PropTypes.any,
    };

    const HasOutliers = (props) => {
        const {selectedPoint, chartType} = props;
        const {equipmentItem} = useEquipmentByParams();
        const chartTypes = useChartTypesStore();
        const {axisLabelsChart: axisLabels} = useAxisLabelQuery(equipmentItem.id);
        const [showModal, setShowModal] = useState(false);
        const [axes, setAxes] = useState([]);
        const [inProgress, setInProgress] = useState(false);
        const [currentDate, setCurrentDate] = useState(null);

        const seriesList = useMemo(() => getSeriesList(props.chartType, false, chartTypes), [props.chartType]);
        const allAxesSelected = useMemo(() => axes.length === Object.keys(seriesList.list).length, [axes, seriesList]);

        const {data: fftTimestamps} = useFFTTimestampsQuery(equipmentItem.id, (res) => _get(res.fftTimestamps, [selectedPoint], {}));
        const refetchTimestamps = useTimestampRefetch();
        const isImpactVue = useIsImpactVue(chartType);
        const selectedAxis = useSyncedAxis(selectedPoint, isImpactVue, false);

        useEffect(() => {
            if (!currentDate) {
                return;
            }

            const [date, time] = currentDate.split(" ");
            const outliersIds = [];

            _forEach(fftTimestamps, (val, key) => {
                const isOutlier = _get(val, [date, time, "is_outlier"], false);

                if (isOutlier) {
                    outliersIds.push(+key);
                }
            });
            setAxes(outliersIds);
        }, [fftTimestamps, currentDate]);
        const showOutlierDialog = (currentDate) => {
            let date = currentDate.date;
            let time = currentDate.time;

            if (date === "last") {
                date = _findLastKey(_get(fftTimestamps, [selectedAxis[0]]));
            }
            if (time === "last") {
                time = _findLastKey(_get(fftTimestamps, [selectedAxis[0], date]));
            }
            currentDate = `${date} ${time}`;

            setCurrentDate(currentDate);
            setShowModal(true);
        };

        const onSubmit = () => {
            setInProgress(true);
            let axesLocal = axes;

            ApiReading.markFftAsOutlier(props.selectedPoint, {
                axes: axesLocal,
                timestamp: currentDate,
            }).then((response) => {
                if (response) {
                    Toast.success("The outliers have been successfully updated.");
                    setShowModal(false);
                    refetchTimestamps(equipmentItem.id);
                }
                setInProgress(false);
            });
        };

        const handleChangeAxis = (axisId) => {
            let axesLocal = [...axes];

            if (axisId === "all") {
                if (allAxesSelected) {
                    axesLocal = [];
                } else {
                    axesLocal = Object.keys(seriesList.list).map((axisId) => +axisId);
                }
            } else {
                if (axesLocal.indexOf(axisId) >= 0) {
                    _pull(axesLocal, axisId);
                } else {
                    axesLocal.push(axisId);
                }
            }
            setAxes(axesLocal);
        };

        return (
            <>
                <WrappedComponent
                    showOutlierDialog={showOutlierDialog}
                    {...props}
                />
                {!!showModal && (
                    <Modal
                        {...props}
                        showModal={true}
                        size="md"
                        title={"Mark reading as outlier"}
                        className={"custom-modal overlay-modal"}
                        onSubmit={onSubmit}
                        onClose={() => setShowModal(false)}
                        inProgress={inProgress}
                    >
                        <div className="row form-group">
                            {"Are you sure you want to mark this reading as an outlier? If you do, it will not be included in the envelope calculation."}
                        </div>
                        <h5 className="chart-title mt-5 mb-4">Select Axes:</h5>
                        <div className={"row"}>
                            <div
                                className={"col-md-3"}
                                key={"all"}
                            >
                                <label className="form-checkbox modal-checkbox">
                                    <input
                                        checked={allAxesSelected}
                                        type="checkbox"
                                        onChange={() => handleChangeAxis("all")}
                                    />
                                    All
                                    <span />
                                </label>
                            </div>
                            {Object.keys(seriesList.list).map((axisId) => (
                                <div
                                    className={"col-md-3"}
                                    key={axisId}
                                >
                                    <label className="form-checkbox modal-checkbox">
                                        <input
                                            checked={axes.indexOf(+axisId) >= 0}
                                            type="checkbox"
                                            onChange={() => handleChangeAxis(+axisId)}
                                        />
                                        {_get(axisLabels, [axisId, selectedPoint]) || seriesList.list[axisId].axisName + " - Axis"}
                                        <span />
                                    </label>
                                </div>
                            ))}
                        </div>
                    </Modal>
                )}
            </>
        );
    };

    HasOutliers.propTypes = propTypes;

    return HasOutliers;
}

import {useMemo} from "react";

//TODO REWORK ON API SIDE TO DETERMINATE IMPACT VUE TYPE
export const IMPACT_VUE_CHART_TYPES = [
    15, //Acceleration ImpactVUE
    19, //FFT Acceleration ImpactVUE
    20, //FFT Velocity ImpactVUE
    21, //TWF Acceleration ImpactVUE
    22, //TWF Velocity ImpactVUE
];

const IMPACT_VUE_TO_REGULAR_CHART_MAPPER = {
    19: 8, //FFT Acceleration ImpactVUE
    20: 9, //FFT Velocity ImpactVUE
    21: 7, //TWF Acceleration ImpactVUE
    22: 11, //TWF Velocity ImpactVUE
};

const TWF_FFT_VOLOCITY_CAHRT_TYPES = [
    20, //FFT Velocity ImpactVUE
    9, //FFT Velocity
    22, //TWF Velocity ImpactVUE
    11, //TWF Velocity
];

export const useIsImpactVue = (chartType) => {
    return useMemo(() => IMPACT_VUE_CHART_TYPES.includes(+chartType), [chartType]);
};

export const useIsTachometer = (chartType) => {
    return useMemo(() => +chartType === 14, [chartType]);
};

export const useIsTemperature = (chartType) => {
    return useMemo(() => +chartType === 1, [chartType]);
};

export const useImpactVueChartTypeMapper = (chartType) => {
    return IMPACT_VUE_TO_REGULAR_CHART_MAPPER[chartType] || chartType;
};

export const useIsFFTVelocityChartType = (chartType) => {
    return useMemo(() => TWF_FFT_VOLOCITY_CAHRT_TYPES.includes(+chartType), [chartType]);
};

import React, {useState} from "react";
import DatePickerInput from "../../../../../../shared/datePickerInput/datePickerInput";
import {DATEPICKER_TYPE, ON_DEMAND_REASON} from "../../../../../../constants/constants";
import Helper from "../../../../../../helpers/helper";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {dateListByPointSelector, timeListByPointDateSelector, useFFTTimestampsQuery} from "../../../../../../hooks/api/fftReadings/TimestampsQuery";
import useUserProfile from "../../../../../../hooks/api/Global/useUserProfile";
import {usePointData} from "../../../hooks/usePointsData";
import {useIsImpactVue} from "../../../hooks/useChartTypes";
import {useCurrentDate} from "../../hooks/helpers/useCurrentDate";
import {useSelectedAxis} from "../../hooks/helpers/useSelectedAxis";
import {useUpdateCurrentDate} from "../../hooks/helpers/useUpdateCurrentDate";
import {shallow} from "zustand/shallow";
import PropTypes from "prop-types";

const DatepickerButton = ({fullScreenMode = false}) => {
    const [isOpen, setIsOpen] = useState(false);
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const isImpactVue = useIsImpactVue(chartType);
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint);
    const {setLoader} = useChartStateStoreContext((state) => state.actions, shallow);
    const selectedAxis = useSelectedAxis();
    const pointData = usePointData(selectedPoint);
    const currentDate = useCurrentDate();
    const updateCurrentDate = useUpdateCurrentDate();

    const isLoading = useChartStateStoreContext((state) => state.isLoading);
    const changeCurrentDate = (currentDate) => {
        updateCurrentDate(currentDate);
        setLoader(true);
    };

    const {data: user} = useUserProfile();

    const {isSuccess: datesLoaded, data: dates} = useFFTTimestampsQuery(pointData.equipment_id, (data) =>
        dateListByPointSelector(data, isImpactVue, pointData.id, selectedAxis)
    );

    const {isSuccess: timesLoaded, data: times} = useFFTTimestampsQuery(pointData.equipment_id, (data) =>
        timeListByPointDateSelector(data, isImpactVue, pointData.id, currentDate.date, selectedAxis)
    );

    const isDataLoaded = datesLoaded && timesLoaded;

    if (!isDataLoaded) {
        return <></>;
    }

    if (dates.length === 0) {
        setLoader(false);
        return <></>;
    }

    const index = dates.indexOf(currentDate.date === "last" ? dates.at(-1) : currentDate.date);
    const nextDate = index + 1 < dates.length ? {date: dates.at(index + 1), time: "last"} : false;
    const prevDate = index - 1 >= 0 ? {date: dates.at(index - 1), time: "last"} : false;

    return (
        <React.Fragment>
            <div className="btn-group-sm dropdown btn-default date-container">
                <button
                    type="button"
                    className="btn btn-default btn-fa-icon"
                    onClick={() => changeCurrentDate(prevDate)}
                    disabled={!prevDate || !!isLoading}
                >
                    <i className="fa fa-angle-left" />
                </button>
                <div className="d-inline-block position-relative">
                    <DatePickerInput
                        className="form-control"
                        value={currentDate.date === "last" ? dates.at(-1) : currentDate.date}
                        type={DATEPICKER_TYPE.FFT}
                        availableDates={dates}
                        onChangeHandler={(date) => changeCurrentDate({date: date, time: "last"})}
                        replaceWithButton={true}
                        displayFormat={Helper.getUserDateFormat(user, true).date}
                        valueFormat={"YYYY-MM-DD"}
                        disabled={!dates.length || !!isLoading}
                        portalPosition={fullScreenMode === true ? "date-picker-portal-modal" : null}
                    />
                </div>
                <button
                    type="button"
                    className="btn btn-default btn-fa-icon"
                    onClick={() => changeCurrentDate(nextDate)}
                    disabled={!nextDate || !!isLoading}
                >
                    <i className="fa fa-angle-right" />
                </button>
            </div>
            <Dropdown
                size="sm"
                isOpen={isOpen}
                disabled={!!isLoading}
                toggle={() => setIsOpen((prevDate) => !prevDate)}
            >
                <DropdownToggle
                    caret
                    className="btn-default"
                    disabled={Object.keys(times).length < 2}
                >
                    {currentDate.time === "last" ? Object.keys(times).at(-1) : currentDate.time}
                </DropdownToggle>
                <DropdownMenu className={Object.keys(times).length > 8 ? "custom-scroll" : ""}>
                    {Object.keys(times).map((time) => (
                        <DropdownItem
                            key={time}
                            onClick={() => changeCurrentDate({date: currentDate.date, time: time})}
                            disabled={time === (currentDate.time === "last" ? Object.keys(times).at(-1) : currentDate.time)}
                        >
                            {time}
                            {times[time].is_on_demand && times[time].on_demand_reason !== ON_DEMAND_REASON.IDLE_THRESHOLD && (
                                <>
                                    {times[time].on_demand_reason === ON_DEMAND_REASON.LOCAL_ALARM ? (
                                        <span className="badge badge-info">On Demand by Alert</span>
                                    ) : (
                                        <span className="badge badge-info">On Demand</span>
                                    )}
                                </>
                            )}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

const propTypes = {
    fullScreenMode: PropTypes.bool,
};
DatepickerButton.propTypes = propTypes;

export default DatepickerButton;

import React from "react";
import PropTypes from "prop-types";
import Helper from "../../../helpers/helper";

const aliasList = ["acc", "vel", "pkpk", "temp", "fftvel", "fftacc"];

const RmsOdTemplate = ({alert, alias, isCurrent, update}) => {
    if (aliasList.indexOf(alias) === -1) return "";

    return (
        <div className="row mt-2 align-items-baseline" style={{minHeight: "30px"}}>
            <div className={alert.alertLevel.name === "Caution" ? "col-md-4" : "col-md-4"}>
                <label className="form-checkbox form-checkbox--alerts">
                    <input
                        type="checkbox"
                        checked={alert.rmsOd === "1"}
                        onChange={ev => update({rmsOd: Helper.getInputValue(ev.target)})}
                        disabled={!isCurrent}
                    />Request FFT<span/>
                </label>
            </div>
        </div>
    );
};

RmsOdTemplate.propTypes = {
    alert: PropTypes.object,
    alias: PropTypes.string,
    versionType: PropTypes.number,
    isCurrent: PropTypes.bool,
    update: PropTypes.func,
    specs: PropTypes.object,
};

export default RmsOdTemplate;